module.exports = {
  entry: './index.js',

  // headers: {
  //     'X-Frame-Options': 'allow-from https://web.facebook.com'
  // },

  output: {
    filename: 'bundle.js',
    publicPath: 'public'
  },

  resolve: {
    extensions: [ '.js', '.jsx']
  },

  module: {
    rules: [
      {
        test: /\.js$/,
        exclude: /node_modules/,
        use: {
          loader: 'babel-loader?presets[]=es2015&presets[]=react'
        }
      },
      {
        test: /\.css$/,
        use: ["style-loader", "css-loader"]
      }
    ]
  }

}