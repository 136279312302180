import React from 'react';
import axios from 'axios';
// import './../Ant.css';
import {
    Link
  } from 'react-router-dom';
import { Card } from 'antd-mobile'
var moment = require('moment');

let checklogin = () => {
  if(localStorage.getItem(window.BPdata.time)) {
      if(localStorage.getItem(window.BPdata.time) !== "0" ) {
          return true;
      }
  }
  return false;
}

class ThreeGameHomePage extends React.Component {
    constructor(props) {
        super(props)
        window.BPdata.footerMenu = "home";
        this.state = { 
            step: "login", 
            message : "", 
            result_list : [ {lottery_number: '--'}],
            form : {username: '', phone: '',verify_code: '', password: ''} , 
            settrade: {
                "thaidate":"29-11-21 13:11",
                "set":"--",
                "value":"--",
                "created_at":"2021-11-29 12:41:10",
                "number":"--"
            },
            eventone  : [],
            eventtwo   : [],
            wallet : [
                {
                    wallet_id: 1,
                    credit : 0
                },
                {
                    wallet_id: 2,
                    credit : 0
                },
                {
                    wallet_id: 3,
                    credit : 0
                }
            ],
         };


    }

    currencyFormat(num) {
        return num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
       // return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }

    hisUrl(url) {
        if(localStorage.getItem("route")) {
          let menu = JSON.parse(localStorage.getItem("route"));
          //console.log(menu.length+"-------------")
          if(menu.length === "0") {
            let data = ["/"];
            localStorage.setItem("route", JSON.stringify(data) );
          } else {
            if(menu[menu.length - 1] !== url ){
              menu.push(url)
              localStorage.setItem("route", JSON.stringify(menu) );
            }
          }
        
          // localStorage.setItem("route", JSON.stringify(menu) );
          // console.log(localStorage.getItem('route'));
        }
       // console.log(localStorage.getItem('route'));
    }

    componentDidMount() {

        if(localStorage.getItem(window.BPdata.time)) {
            if(localStorage.getItem(window.BPdata.time) !== "0" ) {
              //localStorage.setItem(window.BPdata.time, true);
                
              } else {
                this.props.history.push("/more");
                }
          } else {
              this.props.history.push("/more");
          }

        if(localStorage.getItem('wallet')) {
        
          if(JSON.parse(localStorage.getItem("wallet")) != null) {
            let wallet = JSON.parse(localStorage.getItem("wallet"));
            this.setState({wallet: wallet})
          }
        } 

        this.fetchData(window.BPdata.apiUrlCMS+'/3d');
        // http://dep.thahtaylay.com/api/v1/cms/2d
    }

    fetchData(url) {
        
        // let dataurl = url;

        // window.BPdata.liveurl =  setTimeout(() => this.fetchData(url), 30000);

        // axios.get(dataurl, {
        //   method: "GET", // *GET, POST, PUT, DELETE, etc.
        //   mode: "no-cors", // no-cors, cors, *same-origin
        //   cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        //   //credentials: "same-origin", // include, *same-origin, omit
        //   crossDomain: true,
        //   headers: {
        //       "Content-Type": "application/json; charset=utf-8",
        //       "X-Trident-Token" : window.BPdata.token,
        //       "BP-Agent"         : window.BPdata.rand_compound,
        //         "BP-Time"         : localStorage.getItem(window.BPdata.time)
        //       // "Content-Type": "application/x-www-form-urlencoded",
        //   },
        //   redirect: "follow", // manual, *follow, error
        //   referrer: "no-referrer", // no-referrer, *client
        //   //body: JSON.stringify(data), // body data type must match "Content-Type" header
        // })
        // .then(resp => {
        //     // console.log(resp.data)

        //     // {"thaidate":"29-11-21 13:11",
        //     // "set":"1,600.24",
        //     // "value":"64,409.60M",
        //     // "created_at":"2021-11-29 12:41:10",
        //     // "number":"49"}
        //     this.setState({ 
        //         settrade : {
        //             thaidate: resp.data.data.thaidate,
        //             set: resp.data.data.set,
        //             value: resp.data.data.value,
        //             created_at: resp.data.data.created_at,
        //             number: resp.data.data.number
        //         },
        //         eventone  : resp.data.eventone,
        //         eventtwo   : resp.data.eventtwo,
                
        //     });
        //     //this.setState({ isLoading: false });

        // })
        // .catch(function(error) {
        //   //console.log(error.tostring());
        // });   

        let NUM_ROWS = 5;

        let dataurl = window.BPdata.apiUrl+'/service/bet-list?limit='+NUM_ROWS+'&game_type=2';
        // let data = this.state.data;
        axios({
            method: 'get',
            url: dataurl,
            // data: data,
            headers: {
              "Content-Type": "application/json; charset=utf-8",
              "X-Trident-Token"  : window.BPdata.token,
              "BP-Agent"         : window.BPdata.rand_compound,
              "BP-Time"          : localStorage.getItem(window.BPdata.time)
              // "Content-Type": "application/x-www-form-urlencoded",
          },
        })
        .then(resp => {
          // console.log(this.data.concat(resp.data));
          this.bet_list = resp.data.bet_list.data;
          this.result_list = resp.data.result_list.data;

            this.setState({
              result_list: this.result_list,
            });
            
        })
        .catch(function(error) {
          //console.log(error);
        });   
        
    }

    // componentWillUpdate() {
    //     localStorage.setItem('route', '/more/login');
    //     console.log(localStorage.getItem('route'))
    // }

    inputChangeHandler(e) {
        let form = {...this.state.form};
        form[e.target.name] = e.target.value;
        this.setState({
            form
        });
    }

    childui() {

        let ui = []

        this.state.result_list.forEach(function(each,key){
                    
            ui.push(
                    <div className="adm-card row mt-10 grid-90">
                            <div className="adm-card-body grid-100">
                                    <div className="grid-50 left">
                                         {moment(each.schedule_at, 'YYYY-MM-DD hh:mm:ss').format('DD-MM-YYYY')} &nbsp;|&nbsp; 
                                         {moment(each.schedule_at, 'YYYY-MM-DD hh:mm:ss').format('hh:mm A')}
                                    </div>
                                    <div className="grid-40 left color-blue text-right">
                                        { each.lottery_number }
                                    </div>
                                
                            </div>
                    </div>)

        })

        return ui

    }



    render() {
        return (
            <div className="page">
                <div class="contact-header row text-center bg-image color-black">
                    <br />
                    <div className="semi-page-content text-center">
                        <div className="main-content">
                        <div className="grid-100 mb-4 text-center">

                            {moment(this.state.result_list[0].schedule_at, 'YYYY-MM-DD hh:mm:ss').format('DD-MM-YYYY')}

                        </div>

                        <div className="grid-100 mb-4 text-center">
                                <br />
                                    <h2 className="blink_me1 current-number" >{ this.state.result_list[0].lottery_number }</h2>
                        </div>

                        <br />
                        <br />
                        <br />

                        {(checklogin()) ? ((this.state.wallet[0]) ?
                            <div className="row mb-4 text-center">
                        <div className="adm-card row mt-10 bg-dark-red color-white">
                                <div className="adm-card-body grid-100">
                                        <div className="grid-10 left text-center">
                                          </div>
                                          <div className="grid-60 left text-left pl-20">
                                            { window.lang.title.main_wallet }
                                        </div>
                                        <div className="grid-30 left ">
                                             { (this.state.wallet) ? this.currencyFormat(this.state.wallet[0].credit) : 0 } Ks
                                        </div>
                                        <br />
                                </div>
                                <hr style={{ "borderBottom": "1px solid #fff" }} />
                                <div className="adm-card-body grid-100">
                                        <div className="grid-10 left text-center">
                                        </div>
                                        <div className="grid-60 left text-left pl-20">
                                            { window.lang.title.commission_wallet }
                                        </div>
                                        <div className="grid-30 left ">
                                             { (this.state.wallet) ? this.currencyFormat(this.state.wallet[2].credit) : 0 } Ks
                                        </div>
                                    
                                </div>
                        </div></div> : "" ) : ""}
                        <br />
                        <br />
                        <br />
                        <div className="row mb-4 text-center">
                            <div className="grid-25 left">
                                <Link to="/more/bet-list-3d" className="">
                                <img src="/img/list/_bet_list.png" height="30px"/>
                                <br />
                                <span>{window.lang.title.bet_list}</span>
                                </Link>
                                
                            </div>

                            <div className="grid-25 left">
                                <Link to="/more/3d-winner-top-ten-list" className="">
                                <img src="/img/list/_winner.png" height="30px"/>
                                <br />
                                <span>{window.lang.title.winner}</span>
                                </Link>
                            </div>

                            <div className="grid-25 left">
                                <Link to="/more/thai-off-day" className="">
                                <img src="/img/list/_close_day.png" height="30px"/>
                                <br />
                                <span>{window.lang.title.close_day}</span>
                                </Link>
                            </div>

                            <div className="grid-25 left">
                                <Link to="/more/dream-photo" className="">
                                <img src="/img/list/_dream.png" height="30px"/>
                                <br />
                                <span>{window.lang.title.dream_photo}</span>
                                </Link>
                            </div>
                        </div>
                        <br />
                        </div>
                    </div>
                </div>
                <div className="page-content text-center">



                    <div className="main-content">
                    
                  {/*  <a href="" >Bet List</a> <a href="">Winner</a> 
                    <a href="/more/thai-off-day">Close Day</a> <a href="">Dream</a>*/}
                    <br />
                    <br />
                    <div className="row">
                        <div className="grid-100 text-center">
                            <b className="color-dark-red"> Recent Result</b>
                        </div>
                    </div>
                    <br />

                    { this.childui() }
                    
                    <br />
                    </div>
                    <div className="grid-100 text-center mt-10">
                       {(checklogin()) ?  <Link to="/three-game-set" className="btn btn-success grid-20">{ window.lang.betgroup.bet }</Link> : "" }
                    </div>
                    <br />
                </div>
            </div>
        );
    }
}

export default ThreeGameHomePage;