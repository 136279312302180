import React from "react";
import {
  Route,
  Switch,
  Redirect,
  Link
} from 'react-router-dom';
import SideMenu from "../components/sidemenu/SideMenu";
import "./ToolBar.css";

const toolbar = props => (
  <header className="App-header">
    <div className="App-header-inner">
      <div className="row text-center">
        <div className="align-center left grid-10 logo pl-5">
            <div className="toggle-btn">
              <SideMenu click={props.drawerToggleClickHandler} />
            </div>
        </div>
        <div className="align-center left grid-70 logo">
          <Link to="/" className="color-white">
            <b className="color-white">127-Payment Platform</b>
          </Link>
        </div>
        <div className="align-right right grid-10 pr-5">

          <Link to="/more/announcements">
            <img src="/img/_noti.png" style={{ height: "25pt", verticalAlign: "middle", paddingBottom:"4px"}} alt="Shwe Bet" />

          </Link>
          {/* <div className="download-apk-here">
              {
                ((navigator.userAgent.toLowerCase().indexOf("BPdata") > -1 ) ? "" : <a href="https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.BPdata.ss" >Download APK Here</a> )
              }
          </div> */}

        </div>
      </div>
    </div>
  </header>
);

export default toolbar;
