let rand_one = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 10);
let rand_two = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 10);
let salt = "r27bHi9jwClte3W8MypKXXqpMCvIRZErVOttKsz9SNf14xKwtK6J1rjWE9Zc";
// hlvixpdbur27bHi9jwClte3W8MypKXXqpMCvIRZErVOttKsz9SNf14xKwtK6J1rjWE9Zcdzivymys
let token = window.btoa(encodeURIComponent( rand_one + salt  + rand_two ));
let rand_compound = window.btoa(encodeURIComponent( rand_one + "!" + rand_two ));
//let token = encodeURIComponent(escape(window.atob( rand_one + salt  + rand_two )));
// atob(rand_one + salt  + rand_two);


window.BPdata =
    {
        time : "bp-pay-time",
        provider_id : 2,
        data :
            {
                path: '/',
                component: 'Home',
            },
        footerMenu : "Home",
        baseUrl : 'https://127mart.com/',
        // baseUrl : 'https://127mart.com/',
        //baseUrl : 'https:// dep.thahtaylay.com//',
        apiUrl  : 'https://127mart.com/api/v1',
        apiUrlCMS  : 'https://127mart.com/api/v1/cms',
        salt : salt,
        rand_one : rand_one,
        rand_two : rand_two,
        rand_compound : rand_compound,
        token : token,
        dashboardUrl : 'https://127mart.com',
        apiAssetUrl : 'https://127mart.com/uploads',
        popup   : true,
        route : "[\"/\"]",
        preurl: "/",
        liveurl: "",
        mainmenu :[
            { id: 1, name: 'English Premier League',  shortname: 'EPL', mmshortname: 'ပရီးမီးယားလိဂ်',  url : 'premier-league' },
            { id: 2, name: 'Spain Laliga Premier', shortname: 'LALIGA', mmshortname: 'လာလီဂါ', url : 'laliga' },
            { id: 3, name: 'Itlaian Serie A', shortname: 'SERIE A', mmshortname: 'စီးရီးအေ', url : 'serie-a' },
            { id: 4, name: 'German Bundesliga',shortname: 'BUNDESLIGA', mmshortname: 'ဘွန်ဒက်လီဂါ',  url : 'bundesliga' },
            { id: 5, name: 'France Ligue 1', shortname: 'LIGUE 1', mmshortname: 'လီဂူး၀မ်း',  url : 'ligue-1' },
            { id: 6, name: 'UEFA Champion League', shortname: 'CHAMPION LEAGUE', mmshortname: 'ချန်ပီယံလိဂ်', url : 'champion-league' },
            { id: 7, name: 'UEFA Europa League', shortname: 'EUROPA LEAGUE', mmshortname: 'ယူရိုပါလိဂ်', url : 'europa-league' },
            { id: 8, name: 'World Cup', shortname: 'WORLDCUP', mmshortname: 'ကမ္ဘာ့ဖလား', url : 'worldcup' },
            { id: 9, name: 'Euro', shortname: 'EURO', mmshortname: 'ယူရို', url : 'euro' },
            { id: 10, name: 'Others', shortname: 'OTHERS', mmshortname: 'အခြား', url : 'others' }
          ],
        lang : "mm"
    }
;


window.enlang =
{
    more : {
        my_account          : 'My Account',
        live_channel1       : 'Live Channel 1',
        live_channel2       : 'Live Channel 2',
        live_channel3       : 'Live Channel 3',
        channelsschedule    : 'Channels Schedule',
        gem_store           : 'Gem Store',
        term_and_conditions : 'Terms & Conditions',
        announcement        : 'Announcement',
        feedback            : 'Feedback',
        faq                 : 'FAQ',
        video               : 'Skill Videos',
        help_center         : 'Help Center',
        wallet_store        : 'Wallet',
        contact_us          : 'Contact Us',
        download_app        : 'Download App',
        share_app           : 'Share App',
    },
    title : {
        latest_euro_news      : 'LATEST EURO NEWS',
        latest_news           : 'LATEST NEWS',
        latest_highlights     : 'LATEST HIGHLIGHTS',
        league_contest  : 'League Contest',
        bill_contest    : 'Bill Contest',
        tipsters        : 'Tipsters',
        tip             : 'Tips',
        league_history  : 'League History',
        bill_history    : 'Bill History',
        transaction     : 'Transaction',
        contest         : 'Contest',
        leaderboard     : 'Leaderboard',
        history         : 'History',
        euro_news       : 'Euro News',
        euro_standing   : 'Euro Standing',
        euro_team       : 'Euro Team',
        payment         : 'Payment',
        order           : 'Order',
        forum           : 'Forum',
        inbox           : 'Inbox',
        withdrawal      : 'Withdrawal',
        deposit         : 'Deposit',
        top_up          : 'Top Up',
        transfer        : 'Transfer',
        bet_list        : 'Bet List',
        invoice_list    : 'Invoice List',
        result          : 'Result',
        winner          : 'Winner',
        close_day       : 'Close Day',
        dream_photo     : 'Dream Photo',
        main_wallet     : 'Main Wallet',
        game_wallet     : 'Game Wallet',
        commission_wallet     : 'Commission Wallet',
    },
    form : {
        user            : 'User',
        id              : 'ID',
        withdrawal      : 'Withdrawal',
        deposit         : 'Deposit',
        top_up          : 'Top Up',
        transfer        : 'Transfer',
        gems            : 'Gems',
        point           : 'Point',
        hightest_point  : 'Highest Point',
        rank            : 'Rank',
        join_date       : 'Join Date',
        language        : 'Language',
        login           : 'User Login',
        register        : 'User Register',
        password_reset  : 'Password Reset',
        verify_code     : 'Verify Code',
        logout          : 'Logout',
        title           : 'Title',
        description     : 'Description',
        name            : 'Name',
        phone           : 'Phone',
        password        : 'Password',
        forgot_password : 'Forgot password?',
        submit          : 'Submit',
        cancel          : 'Cancel',
        new_post        : 'New Post',
        comment         : 'Comment',
        que             : {
            confirm     : 'Confirm Box',
            delete      : 'Are you sure to delete?'
        }
    },
    packages : {
        oneday          : 'One Day',
        oneweek         : 'One Week',
        onemonth        : 'One Month',
        buy             : 'Buy',
    },

    footer : [
            {icon: '/img/footer/_inv_list.png',icon_off: '/img/footer/_inv_list.png',text: 'Invoice', check: 'wallet', url : "/more/invoice-list" },
            {icon: '/img/logo_small.png',icon_off: '/img/logo_small.png',text: 'Home', check: 'home', url : "/" },
            {icon: '/img/footer/_profile.png',icon_off: '/img/footer/_profile.png',text: 'My Profile', check: 'myaccount', url : "/more/myaccount" },
          ],

    homeiconlist : [
            {icon: '/img/homeicon/twod_card.png',icon_off: '/img/homeicon/twod_card.png',text: 'G Part', check: 'NEWS', url : "/contact-us" , linktype : "local"},
            {icon: '/img/homeicon/twod_card.png',icon_off: '/img/homeicon/twod_card.png',text: 'G Part', check: 'NEWS', url : "/contact-us" , linktype : "local"},
            {icon: '/img/homeicon/twod_card.png',icon_off: '/img/homeicon/twod_card.png',text: 'G Part', check: 'NEWS', url : "/contact-us" , linktype : "local"},
            // {icon: '/img/homeicon/threed_card.png',icon_off: '/img/homeicon/threed_card.png',text: '3D', check: 'NEWS', url : "/three-game" , linktype : "local"},
            // {icon: '/img/homeicon/home-block-live-sports.jpg',icon_off: '/img/homeicon/home-block-live-sports.jpg',text: 'Football', check: 'FOOTBALL', url : "/ws-football" , linktype : "local"},
            // {icon: '/img/homeicon/pc_live.png',icon_off: '/img/homeicon/pc_live.png',text: 'Live Casino', check: 'NEWS', url : "/bg-live-casino", linktype : "local"},
            // {icon: '/img/homeicon/pc_fish1.png',icon_off: '/img/homeicon/pc_fish1.png',text: 'Fishing Master', check: 'Profile', url : "/bg-fishing-master", linktype : "local"},
            // {icon: '/img/homeicon/pc_fish2.png',icon_off: '/img/homeicon/pc_fish2.png',text: 'Xiyou Fishing', check: 'ABOUT', url : "/bg-xiyoufishing", linktype : "local" },
            // {icon: '/img/homeicon/pc_fish3.png',icon_off: '/img/homeicon/pc_fish3.png',text: 'Daisen', check: 'ABOUT', url : "/bg-daisen", linktype : "local" },
    ],
    messages : {
        last_six_digit_transaction : "Please input last 6 digit of transaction ",
        choose_bank     : "Choose Bank",
        cash_amount     : "Cash Amount",
        que_submit      : "Are you sure want to submit?",
        invalid_phone   : "Invalid phone number",
        welcome_message : "Welcome to WebApp",
        sending         : "Sending",
        somethingwrong  : "Something was wrong",
        not_available_yet : "Not Available Yet",
    },
    errors : {
        contact_cs      : "Please contact our customer support!!!",
    },
    moneyamountlist :  [
            { text: '1,000', num: '1000'},
            { text: '2,000',  num: '2000'},
            { text: '3,000',  num: '3000'},
            { text: '5,000', num: '5000'},
            { text: '10,000',  num: '10000'},
            { text: '50,000',  num: '50000'},
            { text: '100,000',  num: '100000'},
            { text: '200,000',  num: '200000'},
            { text: '500,000',  num: '500000'},
        ],
    betgroup : {
        select_number_group : "Select Number Group",
        big     : "Big",
        small   : "Small",
        odd     : "Odd",
        even    : "Even",
        same    : "Same",
        round   : "Round",
        head    : "Head",
        tail    : "Tail",
        consetellation : "Consetellation",
        power   : "Power",
        select_number : "Select Number",
        fast    : "Fast",
        clear   : "Clear",
        bet     : "Bet",
        bet_amount : "Bet",
        commission : "Commission",
        action     : "Action",
        edit       : "Edit",
        delete     : "Delete",
        commission_total : "Commission Total",
        total            : "Total",
        submit           : "Submit",
        color_meaning    : "Color Meaning",
        close_at         : "*Close at ",
        select_time      : "Select the time",
        same_number      : "Same Number",
    }
};

window.mmlang =
{
    more : {
        my_account          : 'ကိုယ်ပိုင်အကောင့်',
        live_channel1       : 'တိုက်ရိုက်ချန်နယ် ၁',
        live_channel2       : 'တိုက်ရိုက်ချန်နယ် ၂',
        live_channel3       : 'တိုက်ရိုက်ချန်နယ် ၃',
        channelsschedule    : 'ချန်နယ်အချိန်ဇယား',
        gem_store           : 'Gem ဆိုင်',
        term_and_conditions : 'စည်းကမ်းချက်များ',
        announcement        : 'အသိပေးကြေညာချက်',
        feedback            : 'အကြံပေးခြင်း ',
        faq                 : 'FAQ',
        video               : 'ဗွီဒီယိုများ',
        help_center         : 'မေးလေ့မေးထရှိသောမေးခွန်းများ',
        wallet_store        : 'ပိုက်ဆံအိတ်',
        contact_us          : 'ဆက်သွယ်ရန်',
        download_app        : 'အက်ပ်ကို ဒေါင်းလုဒ်လုပ်ပါ။',
        share_app           : 'အက်ပ်ကို မျှဝေပါ။',
    },
    title : {
        latest_euro_news        : 'နောက်ဆုံးရ ယူရိုသတင်းများ',
        latest_news             : 'နောက်ဆုံးရသတင်းများ',
        latest_highlights       : 'နောက်ဆုံးရဟိုက်လိုက်များ',
        league_contest  : 'League ပြိုင်ပွဲ',
        bill_contest    : 'ဖုန်းဘေလ်ပြိုင်ပွဲ',
        transaction     : 'ငွေသွင်းငွေထုတ်',
        tipsters        : 'ခန့်မှန်းပေးသူများ',
        tip             : 'ခန့်မှန်းချက်',
        league_history  : 'လိဂ်ပြိုင်ပွဲသမိုင်း',
        bill_history    : 'ဖုန်းဘေလ်ပြိုင်ပွဲသမိုင်း',
        contest         : 'ပြိုင်ပွဲ',
        leaderboard     : 'ရပ်တည်မှုဇယား',
        history         : 'မှတ်တမ်း',
        euro_news       : 'ယူရို သတင်း',
        euro_standing   : 'ယူရို ရပ်တည်မှုဇယား',
        euro_team       : 'ယူရို အသင်းများ',
        payment         : 'ငွေပေးချေမှု',
        order           : 'မှာယူခြင်း',
        forum           : 'ဖိုရမ်',
        inbox           : 'စာတိုက်ပုံး',
        withdrawal      : 'ငွေထုတ်ရန်',
        deposit         : 'ငွေဖြည့်ရန်',
        top_up          : 'ငွေဖြည့်ရန်',
        transfer        : 'ငွေလွှဲပြောင်းရန်',
        bet_list        : 'ရိုက်ချက်များ',
        invoice_list    : 'ဘောင်ချာများ',
        result          : 'ပေါက်ကွက်များ',
        winner          : 'ထီပေါက်သူများ',
        close_day       : 'ထီပိတ်ရက်များ',
        dream_photo     : 'အိမ်မက်ဂဏန်း',
        main_wallet     : 'ပင်မ ပိုက်ဆံအိတ်',
        game_wallet     : 'ဂိမ်း ပိုက်ဆံအိတ်',
        commission_wallet     : 'ကော်မရှင် ပိုက်ဆံအိတ်',
    },
    form : {
        user            : 'အသုံးပြုသူ နာမည်',
        id              : 'မှတ်ပုံတင်အမှတ်',
        withdrawal      : 'ငွေထုတ်',
        deposit         : 'ငွေဖြည့်',
        top_up          : 'ငွေဖြည့်',
        transfer        : 'ငွေလွှဲပြောင်း',
        gems            : 'Gems',
        point           : 'ရမှတ်',
        hightest_point  : 'အမြင့်ဆုံးရမှတ်',
        rank            : 'အဆင့်',
        join_date       : 'အသင်းဝင်ရက်စွဲ',
        language        : 'ဘာသာစကား',
        login           : 'အကောင့်၀င်ရန်',
        register        : 'အကောင့်မှတ်ပုံတင်ရန်',
        logout          : 'ထွက်မည်',
        password_reset  : 'စကားဝှက်ကို ပြန်လည်သတ်မှတ်မည်',
        verify_code     : 'ကုဒ်အတည်ပြုပါ',
        title           : 'ခေါင်းစဉ်',
        description     : 'ဖော်ပြချက်',
        name            : 'နာမည်',
        phone           : 'ဖုန်းနံပါတ်',
        password        : 'စကားဝှက်',
        forgot_password : 'စကားဝှက် မေ့နေပါသလား။',
        submit          : 'လုပ်ဆောင်မည်',
        cancel          : 'မလုပ်ဆောင်ပါ',
        new_post        : 'အသစ်တင်မည်',
        comment         : 'မှတ်ချက်',
        que             : {
            confirm     : 'အတည်ပြုပါ',
            delete      : 'သင်ဖျက်ဖို့သေချာလား။'
        }
    },
    packages : {
        oneday          : 'တစ်ရက်စာ',
        oneweek         : 'တစ်ပတ်စာ',
        onemonth        : 'တစ်လစာ',
        buy             : '၀ယ်မယ်',
    },
    footer : [
            {icon: '/img/footer/_inv_list.png',icon_off: '/img/footer/_inv_list.png',text: 'ဝယ်စာရင်းများ', check: 'wallet', url : "/more/invoice-list" },
            {icon: '/img/logo_small.png',icon_off: '/img/logo_small.png',text: 'မူလနေရာ', check: 'home', url : "/" },
            {icon: '/img/footer/_profile.png',icon_off: '/img/footer/_profile.png',text: 'ကိုယ်ပိုင်အကောင့်', check: 'myaccount', url : "/more/myaccount" },
          ],

    homeiconlist : [
            {icon: '/img/homeicon/twod_card.png',icon_off: '/img/homeicon/twod_card.png',text: 'G Part', check: 'NEWS', url : "/contact-us" , linktype : "local"},
            {icon: '/img/homeicon/twod_card.png',icon_off: '/img/homeicon/twod_card.png',text: 'G Part', check: 'NEWS', url : "/contact-us" , linktype : "local"},
            {icon: '/img/homeicon/twod_card.png',icon_off: '/img/homeicon/twod_card.png',text: 'G Part', check: 'NEWS', url : "/contact-us" , linktype : "local"},
            // {icon: '/img/homeicon/threed_card.png',icon_off: '/img/homeicon/threed_card.png',text: '3D', check: 'NEWS', url : "/three-game" , linktype : "local"},
            // {icon: '/img/homeicon/home-block-live-sports.jpg',icon_off: '/img/homeicon/home-block-live-sports.jpg',text: 'Football', check: 'FOOTBALL', url : "/ws-football" , linktype : "local"},
            // {icon: '/img/homeicon/pc_live.png',icon_off: '/img/homeicon/pc_live.png',text: 'Live Casino', check: 'NEWS', url : "/bg-live-casino", linktype : "local"},
            // {icon: '/img/homeicon/pc_fish1.png',icon_off: '/img/homeicon/pc_fish1.png',text: 'Fishing Master', check: 'Profile', url : "/bg-fishing-master", linktype : "local"},
            // {icon: '/img/homeicon/pc_fish2.png',icon_off: '/img/homeicon/pc_fish2.png',text: 'Xiyou Fishing', check: 'ABOUT', url : "/bg-xiyoufishing", linktype : "local" },
            // {icon: '/img/homeicon/pc_fish3.png',icon_off: '/img/homeicon/pc_fish3.png',text: 'Daisen', check: 'ABOUT', url : "/bg-daisen", linktype : "local" },
    ],
    messages : {
        last_six_digit_transaction : "ကျေးဇူးပြု၍ ဘဏ်လုပ်ငန်းစဥ်နံပါတ်၏ နောက်ဆုံးဂဏန်း 6 လုံးကို ထည့်သွင်းပါ။",
        choose_bank     : "ဘဏ်ကိုရွေးချယ်ပါ။",
        cash_amount     : "ငွေသားပမာဏ",
        que_submit      : "ပေးပို့ချင်တာသေချာပါသလား",
        invalid_phone   : "ဖုန်းနံပါတ် မှားယွင်းနေပါသည်။",
        welcome_message : "WebApp မှကြိုဆိုပါသည်",
        sending         : "လုပ်ဆောင်နေပါသည်",
        somethingwrong  : "အချက်အလက်များ မှားယွင်းနေပါသည်။",
        your_comment    : 'သင့်ရဲ့မှတ်ချက်',
        not_available_yet : "လောလောဆယ် မရရှိနိုင်သေးပါ။",
    },
    errors : {
        contact_cs      : "Please contact our customer support!!!",
    },
    moneyamountlist :  [
            { text: '1,000', num: '1000'},
            { text: '2,000',  num: '2000'},
            { text: '3,000',  num: '3000'},
            { text: '5,000', num: '5000'},
            { text: '10,000',  num: '10000'},
            { text: '50,000',  num: '50000'},
            { text: '100,000',  num: '100000'},
            { text: '200,000',  num: '200000'},
            { text: '500,000',  num: '500000'},
    ],
    betgroup : {
        select_number_group : "နံပါတ်အဖွဲ့ကို ရွေးပါ။",
        big     : "ကြီး",
        small   : "သေး",
        odd     : "မ",
        even    : "စုံ",
        same    : "အပူး",
        round   : "ပတ်လည်",
        head    : "ထိပ်",
        tail    : "နောက်ပိတ်",
        consetellation : "နက္ခတ်",
        power   : "ပါဝါ",
        select_number : "နံပါတ်ကို ရွေးပါ။",
        fast    : "အမြန်ရွှေး",
        clear   : "ပယ်ဖျက်ရန်",
        bet     : "ထိုးရန်",
        bet_amount : "ထိုးငွေ",
        commission : "ကော်မရှင်",
        action     : "လုပ်ဆောင်ချက်",
        edit       : "ပြင်ဆင်ရန်",
        delete     : "ဖျက်ရန်",
        commission_total : "ကော်မရှင်စုစုပေါင်း",
        total            : "စုစုပေါင်း",
        submit           : "လုပ်ဆောင်မည်",
        color_meaning    : "အရောင်အဓိပ္ပါယ်",
        close_at         : "*ပိတ်ချိန် ",
        select_time      : "အချိန်ကိုရွေးချယ်ပါ။",
        same_number      : "နံပါတ်တူ",
    }
};


window.lang = window.mmlang;
