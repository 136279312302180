/* eslint no-dupe-keys: 0 */
import React from 'react';
import Swiper from 'swiper/dist/js/swiper.min.js';

import {
  Link
} from 'react-router-dom';
import axios from 'axios';
import Modal from 'react-responsive-modal';
import { ListView } from 'antd-mobile';
import NewsVideoSlider from './../NewsVideoSlider';
// import HomeListPage from './HomeListPage';
import HomeIconList from './HomeIconList';

import ScrollMenu from 'react-horizontal-scrolling-menu';
import ScrollText from 'react-scroll-text'
//import { FacebookProvider, CommentsCount } from 'react-facebook';
//import { askForPermissioToReceiveNotifications } from './../push-notification';
require('./../../../BPdata.js')

//var moment = require('moment');


let data = [
  {
    news_image: 'https://zos.alipayobjects.com/rmsportal/dKbkpPXKfvZzWCM.png',
    news_name: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Aut consectetur temporibus eligendi fuga, dolor expedita ',
    news_desc: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Aut consectetur temporibus eligendi fuga, dolor expedita ',
  }
];


let NUM_ROWS = 3;
//let MAX_LENGTH = 90;
// One item component
// selected prop will be passed
const MenuItem = ({ activeMenu, data, text, selected }) => {
  return (

      <div
      className="menu-item"
      >
        {data.id === 0 ? (
          <Link to={`${data.url}`} className={(activeMenu === text) ? ' cont-wrap-link' : 'cont-wrap-link' } >{(window.BPdata.lang === 'mm')? data.mmshortname : data.shortname }</Link>
        ) : (
          <Link to={`/news/league/${data.id}/${data.url}`} className={(activeMenu === text) ? ' cont-wrap-link' : 'cont-wrap-link' } >{(window.BPdata.lang === 'mm')? data.mmshortname : data.shortname }</Link>
        )}
        {/* <Link to="/about" className={(activeMenu === text) ? 'active' : '' }  > */}

          {/* <span>{text}</span> */}

        {/* </Link> */}
      </div>
  );
};


// All items component
// Important! add unique key
export const Menu = (list,selected,activeMenu) => list.map(el => {
  const { name,shortname } = el;
  // console.log(selected)
  return (
    <MenuItem
      activeMenu={activeMenu}
      text={shortname}
      key={name}
      data={el}
    />
  );
});


const Arrow = ({ text, className }) => {
  return (
    <div
      className={className}
    >{text}</div>
  );
};


const ArrowLeft = Arrow({ text: '<', className: 'arrow-prev' });
const ArrowRight = Arrow({ text: '>', className: 'arrow-next' });


const closeIcon = (
  <svg fill="currentColor" viewBox="0 0 20 20" width={28} height={28}>
    <path
      fillRule="evenodd"
      d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z"
      clipRule="evenodd"
    ></path>
  </svg>
);


let checklogin = () => {
      if(localStorage.getItem(window.BPdata.time)) {
          if(localStorage.getItem(window.BPdata.time) !== "0" ) {
              return true;
          }
      }
      return false;
  }

class HomePage extends React.Component {
  constructor(props) {
    super(props);
    window.BPdata.footerMenu = "home";
    const dataSource = new ListView.DataSource({
      rowHasChanged: (row1, row2) => row1 !== row2,
    });

    this.state = {
      login : 0,
      is_verified : 1,
      selected: 0,
      dataSource,
      isLoading: true,
      // virtual data
      videoSlides:['slide1.jpg','slide1.jpg','slide1.jpg', 'slide1.jpg'],

      virtualVideoData: {
        slides: ['slide1.jpg','slide1.jpg','slide1.jpg', 'slide1.jpg'],
      },
      leagueMenu: 'NEWS',
      open: false,
      advopen : false,
      menu : window.BPdata.mainmenu,
      wallet : []
    };



  }
  onSelect = key => {
    this.setState({ selected: key });
  }

  onOpenModal = () => {
    this.setState({ open: true,  advopen : true });
  };

  onCloseModal = () => {
    this.setState({ open: false , advopen : false });


    //localStorage.setItem('popup', false);
    // window.BPdata.popup = false
    // console.log(window.BPdata.popup)
  };

  currencyFormat(num) {
      return num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
     // return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }

  componentDidMount() {
    let menu = ["/"];

    localStorage.setItem("route", JSON.stringify(menu) );
    //console.log(localStorage.getItem('route'));
    //console.log(window.BPdata.apiUrl)
    // you can scroll to the specified position
    // setTimeout(() => this.lv.scrollTo(0, 120), 800);



    const self = this;


    new Swiper('.swiper-video-container', {
      // ...
      loop: true,
      slidesPerView: 1,
      slidesPerGroup: 1,
      virtual: {
        slides: self.state.videoSlides,
        renderExternal(videoData) {
          // assign virtual slides data
          self.setState({
            virtualVideoData: videoData,
          });
        }
      },
      pagination: {
        el: '.swiper-vieo-pagination',
      },
    });




    // this.setState({ open: true });

      var d = new Date();
      var month = d.getDay();

      if(localStorage.getItem('popup')) {
        if(localStorage.getItem('popup') != month) {
          localStorage.setItem('popup', month);
          this.setState({ open: true });
        }

      } else {
        localStorage.setItem('popup', -1);
        this.setState({ open: true });
      }
      // this.setState({ open: true });

      // if(localStorage.getItem('wallet')) {

      //   if(JSON.parse(localStorage.getItem("wallet")) != null) {
      //     let wallet = JSON.parse(localStorage.getItem("wallet"));
      //     this.setState({wallet: wallet})
      //   }
      // }

      //console.log(localStorage.getItem('popup'));
      //this.fetchData();
      clearTimeout(window.BPdata.liveurl);

      if(localStorage.getItem(window.BPdata.time)) {
          if(localStorage.getItem(window.BPdata.time) !== "0" ) {
              this.setState({login: 1})
          }
      } else {
        this.setState({login: 0})
      }


      this.fetchUserData();

      // if (navigator.userAgent.toLowerCase().indexOf("thahtaylay") > -1 ) {
      //   console.log("other")
      // } else {
      //     if(localStorage.getItem('androidpopup')) {
      //       if(localStorage.getItem('androidpopup') === 'reset') {
      //         localStorage.setItem('androidpopup', true);
      //         this.setState({ open: true });
      //       }

      //     } else {
      //       localStorage.setItem('androidpopup', true);
      //       this.setState({ open: true });
      //     }

      // }
  }

  walletBlock() {

    let childui = [];

      if(this.state.login == 1) {
          if(this.state.wallet[0]) {
            childui.push(<div className="row pb-10">
                <br />
                 <div className="adm-card row mt-10 bg-dark-red color-white">
                        <div className="adm-card-body grid-100">
                            <div className="row">
                                <div className="grid-10 left text-center">
                                </div>
                                <div className="grid-40 left text-left pl-20 ">
                                    { window.lang.title.main_wallet }
                                </div>
                                <div className="grid-40 left text-right">
                                    { (this.state.wallet) ? this.currencyFormat(this.state.wallet[0].credit) : 0 }  Ks

                                </div>
                                <div className="grid-10 left text-center">
                                  <Link to="/more/wallet-store">
                                    &nbsp; <img src="/img/plus.png" height="18px" />
                                  </Link>
                                </div>
                                <br />
                                <hr style={{ "borderBottom": "1px solid #fff" }} />
                            </div>
                            <div className="row">
                                <div className="grid-10 left text-center">
                                </div>
                                <div className="grid-40 left text-left pl-20">
                                    { window.lang.title.game_wallet }
                                </div>
                                <div className="grid-40 left text-right">
                                    { (this.state.wallet) ? this.currencyFormat(this.state.wallet[1].credit) : 0 }  Ks

                                </div>
                                <div className="grid-10 left text-center">
                                  <Link to="/more/wallet-store">
                                    &nbsp; <img src="/img/plus.png" height="18px" />
                                  </Link>
                                </div>
                                <br />
                                <hr style={{ "borderBottom": "1px solid #fff" }} />
                            </div>
                            <div className="row">
                                <div className="grid-10 left text-center">
                                </div>
                                <div className="grid-40 left text-left pl-20">
                                    { window.lang.title.commission_wallet }
                                </div>
                                <div className="grid-40 left text-right">
                                    { (this.state.wallet) ? this.currencyFormat(this.state.wallet[2].credit) : 0 }  Ks

                                </div>
                                <div className="grid-10 left text-center">
                                  <Link to="/more/wallet-store">
                                    &nbsp; <img src="/img/plus.png" height="18px" />
                                  </Link>
                                </div>

                            </div>
                        </div>
                </div>
            </div>)
          } else {
            if(this.state.is_verified == 0) {
              childui.push(<div className="row pb-10">
                <br />
                 <div className="adm-card row mt-10">
                        <div className="adm-card-body grid-100">
                            <div className="row">
                                <div className="grid-100 left text-center">
                                    You have been banned
                                </div>
                                <br />
                                <hr />
                            </div>
                        </div>
                </div>
            </div>)
            } else {
              //Please Login
              childui.push("")
            }

          }
      }
      return childui;
  }

  componentWillUnmount() {
    clearInterval(this.state)
  }

  UNSAFE_componentWillMount() {
    window.BPdata.lang = localStorage.getItem("lang");
  }

  fetchUserData() {
        //localStorage.getItem(window.BPdata.time)
        const url = window.BPdata.apiUrl+'/cms/player/show?transfer_type=0&provider_id='+window.BPdata.provider_id;
        //const url = window.BPdata.apiUrl+'/user/token/'+localStorage.getItem(window.BPdata.time);

        ///api/customer?transfer_type=2

        axios({
          method: 'post',
          url: url,
          data: { time : window.BPdata.rand_compound+"kiuwernxyzxfyfysdsdfdsfydf373sdfdsf" },
          headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "X-Trident-Token" : window.BPdata.token,
                "BP-Agent"         : window.BPdata.rand_compound,
                "BP-Time"         : localStorage.getItem(window.BPdata.time)
            },
        })
        .then(resp => {

            if(resp.data) {
                this.setState({
                  companyuserid : resp.data.data.companyuserid,
                  username      : resp.data.data.first_name,
                  wallet        : resp.data.customer_wallet,
                  is_verified   : resp.data.data.is_verified
                });

                localStorage.setItem("wallet", JSON.stringify(resp.data.customer_wallet) )
                this.setState({login: 1})

            } else {
                this.setState({login: 0})
            }


          })
          .catch(function(error) {
            // console.log(error);
          });
    }
  // If you use redux, the data maybe at props, you need use `componentWillReceiveProps`
  // componentWillReceiveProps(nextProps) {
  //   if (nextProps.dataSource !== this.props.dataSource) {
  //     this.setState({
  //       dataSource: this.state.dataSource.cloneWithRows(nextProps.dataSource),
  //     });
  //   }
  // }

  onEndReached = (event) => {
    // load new data
    // hasMore: from backend data, indicates whether it is the last page, here is false
    // if (this.state.isLoading && !this.state.hasMore) {
    //   return;
    // }
    // console.log('reach end', event);
    // this.setState({ isLoading: true });
    // setTimeout(() => {
    //   this.rData = { ...this.rData, ...genData(++pageIndex) };
    //   this.setState({
    //     dataSource: this.state.dataSource.cloneWithRows(this.rData),
    //     isLoading: false,
    //   });
    // }, 1000);
  }

  render() {
    const { selected } = this.state;
    let leagueMenu = this.state.leagueMenu;
    // Create menu from items
    const menu = Menu(this.state.menu, selected, leagueMenu);

    return (
      <div className="page">
        <div className="page-content">
            <ScrollText>
                မင်္ဂလာပါရှင့်  127 Gateway မှကြိုဆိုပါတယ်ရှင့် အခက်အခဲတစ်စုံတစ်ရာ ပေါ်ပေါက်ခဲ့ပါက Viber 09785041924 ကို ဆက်သွယ်မေးမြန်းနိုင်ပါသည်။

            </ScrollText>

            {/* <ScrollMenu
              data={menu}
              arrowLeft={ArrowLeft}
              arrowRight={ArrowRight}
              selected={selected}
              onSelect={this.onSelect}
              alignCenter={false}
              wheel={true}
              inertiaScrolling={true}
              // scrollToSelected={true}
            />

            {/* <button onClick={askForPermissioToReceiveNotifications} >
                  Click here to receive notifications
            </button> */}


              <div className="main-content">





                {/* <ListView
                  ref={el => this.lv = el}
                  dataSource={this.state.dataSource}
                  renderHeader={() => <div className="list-header"><Link to="/feature"><img src="/img/trending.jpg" className="list-img-circle" alt="Trending" /><span>TRENDING NEWS</span></Link></div>}
                  // renderFooter={() => (<div style={{ padding: 30, textAlign: 'center' }}>
                  //   {this.state.isLoading ? 'Loading...' : 'Loaded'}
                  // </div>)}
                  renderRow={row}
                  renderSeparator={separator}
                  className="am-list"
                  useBodyScroll
                  // onScroll={() => { console.log('scroll'); }}
                  scrollRenderAheadDistance={500}
                /> */}

                {/*<HomeListPage pageIndexTotal="20" perPage="100" isLoading="false" />*/}

                <div className="row">
                  <div className="grid-100">
                    <NewsVideoSlider />
                    <br />
                   {/* <div className="grid-100 text-center">
                      Download Tha Htay Lay Mobile App &nbsp;&nbsp;<a className="btn btn-success" href="">Download</a>
                    </div>*/}
                  </div>
                </div>



                  <br />

                <HomeIconList></HomeIconList>

              </div>

              {/* <HighlightsVideoSlider></HighlightsVideoSlider> */}


        </div>
      </div>
    );
  }
}

export default HomePage;
