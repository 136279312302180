import React from 'react';
import axios from 'axios';
import { Modal }  from 'antd-mobile';
import {
    Link
  } from 'react-router-dom';


const alert = Modal.alert;
var moment = require('moment');

class MyEditAccPage extends React.Component {

    constructor(props) {
        super(props)
        window.BPdata.footerMenu = "myaccount";
        this.state = { step: "profile", message : "", error: [],
            form : { salt : localStorage.getItem(window.BPdata.time), first_name: '', phone: '',email: '', division: 0 },
            id:"",date_of_birth:"",username:"",phone:"",created_at:"",gem:"",league_points:"0",max_league_points:"0",rank:"", editform : false
        };
        this.userLogout         = this.userLogout.bind(this);
        this.langSwitch         = this.langSwitch.bind(this);
        this.versionSwitch      = this.versionSwitch.bind(this);

        this.sendPlayerEditApi  = this.sendPlayerEditApi.bind(this);
    }

    lpad(value, padding) {
        var zeroes = new Array(padding+1).join("0");
        return (zeroes + value).slice(-padding);
    }


    
    userNameEdit() {
        this.setState({editform : true});
    }

    hisUrl(url) {
        if(localStorage.getItem("route")) {
          let menu = JSON.parse(localStorage.getItem("route"));
          //console.log(menu.length+"-------------")
          if(menu.length === "0") {
            let data = ["/"];
            localStorage.setItem("route", JSON.stringify(data) );
          } 
           if(menu.length > 0) {
              //console.log(menu[menu.length - 1] + "-" + url )
                if(menu[menu.length - 2] === url ){
                    //console.log("first");
                    menu.pop();
                    //window.BPdata.route = JSON.stringify(menu);
                    localStorage.setItem("route", JSON.stringify(menu) );
                    //localStorage.setItem("route", JSON.stringify(menu) );
                }  
                else if(menu[menu.length-1] !== url ){
                    menu.push(url)
                    //window.BPdata.route = JSON.stringify(menu);
                    localStorage.setItem("route", JSON.stringify(menu) );
                }
                window.BPdata.preurl =  menu[menu.length-2];
            }
        }
      }

    fetchUserData() {
        //localStorage.getItem(window.BPdata.time)
        const url = window.BPdata.apiUrl+'/cms/player/show?transfer_type=0&provider_id='+window.BPdata.provider_id;
        //const url = window.BPdata.apiUrl+'/user/token/'+localStorage.getItem(window.BPdata.time);
    
        axios({
          method: 'post',
          url: url,
          data: { time : window.BPdata.rand_compound+"kiuwernxyzxfyfysdsdfdsfydf373sdfdsf" },
          headers: { 
                'Content-Type': 'application/json;charset=UTF-8',
                "X-Trident-Token" : window.BPdata.token,
                "BP-Agent"         : window.BPdata.rand_compound,
                "BP-Time"         : localStorage.getItem(window.BPdata.time) 
            },
        })
        .then(resp => {
          // console.log(this.data.concat(resp.data));
          //let latest_data = resp.data;
          //  console.log('-----------------------')
            //console.log(latest_data)
            //console.log(resp.data);
            // this.setState({
            //   id : resp.data.data.id,
            //   username : resp.data.data.first_name,
            //   date_of_birth : resp.data.data.date_of_birth,
            //   phone : resp.data.data.phone,
            //   created_at : resp.data.data.created_at,
            //   // gem       : resp.data.gem,
            //   // league_points : resp.data.league_points,
            //   // max_league_points : resp.data.max_league_points,
            //   // rank : resp.data.rank
            // });

            this.setState(prevState => ({
                form : {
                    ...prevState.form,
                    division: resp.data.data.division,
                    first_name : resp.data.data.first_name,
                    phone : resp.data.data.phone,
                    email : resp.data.data.email,
                },
            }))
            
          })
          .catch(function(error) {
            // console.log(error);
          });   
    }

    componentDidMount() {

        if(localStorage.getItem(window.BPdata.time)) {
            if(localStorage.getItem(window.BPdata.time) !== "0" ) {
              //localStorage.setItem(window.BPdata.time, true);
                    this.fetchUserData();
              } else {
                this.props.history.push("/more");
                }
          } else {
              this.props.history.push("/more");
          }

        // this.fetchUserData();

       
        this.hisUrl('/more/myaccount');
        //localStorage.setItem('route', '/more/login');
        //console.log(localStorage.getItem('route'))
    }

    // componentWillUpdate() {
    //     localStorage.setItem('route', '/more/login');
    //     console.log(localStorage.getItem('route'))
    // }

    inputChangeHandler(e) {
        let form = {...this.state.form};
        form[e.target.name] = e.target.value;
        this.setState({
            form
        });
    }

    // inputChangeHandler(e) {
    //     // this.state.username
    //     // let form = {...this.state.form};
    //     // form[e.target.name] = e.target.value;
    //     this.setState({
    //         username : e.target.value
    //     });
    // }

    versionSwitch() {
        if(localStorage.getItem('appview') === '0') {
            localStorage.setItem('appview', '1');
        } else {
            localStorage.setItem('appview', '0');
        }
        
        this.props.history.push("/more/myaccount");
    }

    langSwitch() {

        if(localStorage.getItem('lang') === 'en') {
            localStorage.setItem('lang', 'mm');
            window.lang = window.mmlang;
        } else {
            localStorage.setItem('lang', 'en');
            window.lang = window.enlang;
        }
        
        // console.log(window.lang)
        this.props.history.push("/more/myaccount");
    }

    userLogout() {

        alert('Message', 'အကောင့်မှ ထွက်ရန် သေချာပါသလား။', [
            { text: 'မထွက်ပါ', onPress: () => console.log('cancel') },
            {
            text: 'ထွက်မည်',
            onPress: () => {
                    localStorage.setItem(window.BPdata.time, 0);
                    localStorage.setItem('username', "");
                    this.setState({ step: "login" , message : "Successfully Log Out" }); 
                    this.props.history.push("/");
                }
                
            },
        ])
    }



    sendPlayerEditApi() {

        let formdata = this.state.form;
        var self = this;
        let dataurl = window.BPdata.apiUrlCMS+'/player/edit';
        // let dataurl = window.BPdata.apiUrlCMS+"/player/login";
        axios({
            method: 'post',
            url: dataurl,
            data: formdata,
            headers: { 
                'content-type': 'application/json;charset=UTF-8',
                "X-Trident-Token" : window.BPdata.token,
                "BP-Agent"         : window.BPdata.rand_compound,
                "BP-Time"         : localStorage.getItem(window.BPdata.time) 
            },
        })
        .then(function (response) {

            if(response.data.status == 403) {
                self.setState({  message : response.data.message, error : response.data.error, status : response.data.status  });
            } else if(response.data.status == 200) {
                self.setState({  message : "Successfully Submited",  status : response.data.status  });
            }  else {
                self.setState({  message : "တစ်ခုခုတော့မှားနေပြီ" , status : response.data.data.status  });
            }
            //console.log(response.data.success);
        })
        .catch(function (error) {
            // console.log(error);
        });

    }

   

    viewHandler() {
        if(this.state.step){
            switch(this.state.step) {
                case "home":    return this.homeBlock();
                case "profile": return this.profileBlock();
                default:        return "";
              }
        }
       
    }

    profileBlock() {
      return <div>
          <div className="row">
              <h2>Hi {((this.state.form.username)? this.state.form.username : "Customer")}</h2>
              <p>Welcome to Web App</p>
              <input type="submit" className="btn btn-blue grid-50" value="Log Out" onClick={this.userLogout} />
              {/* <a className="btn btn-blue grid-50" onClick={this.userLogout}>Logout</a> */}
          </div>
      </div>
   }

    homeBlock() {
        return <div>
            <div className="row">
                <a href="/more/login" className="btn btn-success grid-50">Log in</a><br /><br />
                <a href="/more/sign-up" className="btn btn-blue grid-50">Sign up</a>
            </div>
        </div>
    }

    render() {
        return (
            <div className="page">

                <div className="contact-header row text-center">
                        
                            <img src="/img/footer/_profile.png" height="106pt"/>
                            <br /><br />
                        <span> 
                           {/* {this.state.username}  (<img src="/img/_edit.png" height="16pt"/>)*/}
                        </span>
                </div>


                <div className="page-content text-left mt-10" style={{  paddingTop: "30px"}}>
                        {(this.state.message) ? this.state.message : ""}
                        <div className=" page-contest text-left">
                            <div className="grid-90 grid-s-90 left">
                                <span>Name </span>
                                <br />
                                <input id="first_name" type="text" className="form-control" name="first_name" onChange={(e) => this.inputChangeHandler.call(this, e)} value={this.state.form.first_name} required autoFocus />
                            </div>
                        </div>
                        <div className=" page-contest text-left">
                            <div className="grid-90 grid-s-90 left">
                                <span>Phone </span>
                                <br />
                                <input id="first_name" type="text" className="form-control" name="phone" onChange={(e) => this.inputChangeHandler.call(this, e)} value={this.state.form.phone} required autoFocus />
                            </div>
                        </div>
                        <div className=" page-contest text-left">
                            <div className="grid-90 grid-s-90 left">
                                <span>Email </span>
                                <br />
                                <input id="email" type="email" className="form-control" name="email" onChange={(e) => this.inputChangeHandler.call(this, e)} value={this.state.form.email} required autoFocus />
                            </div>
                        </div>

                        <div className=" page-contest text-left">
                            <div className="grid-90 grid-s-90 left">
                                <span>Choose State </span>
                                <select className="form-control" required="required" name="division" onChange={(e) => this.inputChangeHandler.call(this, e)} value={this.state.form.division} 
                                ><option value="0">ပြည်နယ် ရွေးချယ်ရန်</option><option value="1">ရန်ကုန်</option><option value="2">မန္တလေး</option><option value="3">ဧရာဝတီ</option><option value="4">ပဲခူး</option>
                                <option value="5">ချင်း</option><option value="6">ကချင်</option><option value="7">ကယား</option>
                                <option value="8">ကရင်</option><option value="9">မကွေး</option><option value="10">မွန်</option>
                                <option value="11">ရခိုင်</option><option value="12">စစ်ကိုင်း</option>
                                <option value="13">ရှမ်း</option><option value="14">တနသာင်္ရီ</option>
                                </select>
                            </div>
                        </div>

                        <div className=" page-contest text-left">
                            <div className="grid-90 grid-s-90 left">
                                <br />
                                <a class="btn btn-success grid-90 pointer"  onClick={this.sendPlayerEditApi}>{window.lang.form.submit}</a>
                            </div>
                        </div>
                   
                </div>
               
            </div>
        );
    }
}

export default MyEditAccPage;