import React,{Component} from 'react';
// import {Helmet} from "react-helmet";
import {
    Link
  } from 'react-router-dom';
//import { FacebookProvider, Comments } from 'react-facebook';
import axios from 'axios';
import renderHTML from 'react-render-html';

class TermAndConditionPage extends Component {
    constructor(props) {
      super(props);
  
      window.BPdata.footerMenu = "home";
      this.state = {
        top : {
            description : ''
        },
        bottom : {
          description : ''
        },
        
        isLoading: true,
        loading: true,
        liked : 0,
      };


        // if(props.match.params.id) {
        //     this.state.tipster_id = props.match.params.id
        //     this.state.tipsterdata_id = props.match.params.id2
        // }
    }

 

    componentDidMount() {
        this.fetchData();
        
        this.setState({ loading: true });

        this.hisUrl('/more/term-condition')
    }

    hisUrl(url) {
      if(localStorage.getItem("route")) {
        let menu = JSON.parse(localStorage.getItem("route"));
        //console.log(menu.length+"-------------")
        if(menu.length === "0") {
          let data = ["/"];
          localStorage.setItem("route", JSON.stringify(data) );
        } 
         if(menu.length > 0) {
            //console.log(menu[menu.length - 1] + "-" + url )
              if(menu[menu.length - 2] === url ){
                  //console.log("first");
                  menu.pop();
                  //window.BPdata.route = JSON.stringify(menu);
                  localStorage.setItem("route", JSON.stringify(menu) );
                  //localStorage.setItem("route", JSON.stringify(menu) );
              }  
              else if(menu[menu.length-1] !== url ){
                  menu.push(url)
                  //window.BPdata.route = JSON.stringify(menu);
                  localStorage.setItem("route", JSON.stringify(menu) );
              }
              window.BPdata.preurl =  menu[menu.length-2];
          }
      }
    }

    componentDidUpdate() {
      
    }

    fetchData() {
        let dataurl = window.BPdata.apiUrl+'/user/league-contests/lrule';

        axios.get(dataurl, {
          method: "GET", // *GET, POST, PUT, DELETE, etc.
          mode: "no-cors", // no-cors, cors, *same-origin
          cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
          //credentials: "same-origin", // include, *same-origin, omit
          crossDomain: true,
          headers: {
              "Content-Type": "application/json; charset=utf-8",
              // "Content-Type": "application/x-www-form-urlencoded",
          },
          redirect: "follow", // manual, *follow, error
          referrer: "no-referrer", // no-referrer, *client
          //body: JSON.stringify(data), // body data type must match "Content-Type" header
        })
        .then(resp => {
            this.setState({
              top: resp.data.top,
              bottom: resp.data.bottom
            });
            this.setState({ isLoading: false });
            // console.log(this.state.data);
        })
        .catch(function(error) {
        //   console.log(error.tostring());
        });   
 
    }

    

    displayBlock() {
        let ui = []
        // console.log("liked--------"+this.state.liked)
        if(this.state.top  != null){
            ui.push(
                <div className="main-content" key="1">
                    <div className="row content-box">
                        <br />
                        <p>
                            { renderHTML(this.state.top.description) }
                        </p>
                        <p className="league-price-table">
                            { renderHTML(this.state.bottom.description) }
                        </p>
                    </div> 
                    
                   
                </div>
            )
        }

        if(ui.length === 0) {
            ui.push(<div className="main-content"><div className="text-center" key="0"><h2>Something was found.</h2></div></div>)
       }
        
        return ui
    }


    
    render() {
   

        return (
            <div className="page">
                <div className="page-content">
                    <div className="main-content">
                        <br/>
                        <div className="list-header">
                            <img src="/img/badge.jpg" className="list-img-circle" alt="GEM Store" /><span>စည်းမျဉ်းစည်းကမ်းများ</span>
                        </div>
                        { (this.state.isLoading) ?  (<div className="row text-center"><img src="/img/loading.gif" alt="loading"  /> </div> ) : this.displayBlock() }
                           
                        {/* <FacebookProvider appId="1750258531872776">
                            <Comments href="http://localhost" />
                            <CommentsCount href="http://localhost" /> Comments
                        </FacebookProvider> */}
                        <div className="ant-back-footer">
                            <div className="ant-back-top">
                                <div className="ant-back-top-content">
                                    <Link to={window.BPdata.preurl} className="color-green" >
                                { (JSON.parse(localStorage.getItem("route")) !== null) 
                                ? ((this.state.url !== "/" || JSON.parse(localStorage.getItem("route"))[JSON.parse(localStorage.getItem("route")).length-1] !== "/") ?  <img src="/img/back-arrow.png" alt="back" />: ""  ) : "" }
                                </Link> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            );
        }
      }                                                                                         
      
export default TermAndConditionPage;
 