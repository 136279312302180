import React from 'react';
import axios from 'axios';
import {
    Link
  } from 'react-router-dom';
import ImageUploading from "react-images-uploading";
import ReactMde from "react-mde";
import * as Showdown from "showdown";

import "react-mde/lib/styles/css/react-mde-all.css";

// const converter = new Showdown.Converter({
//     tables: true,
//     simplifiedAutoLink: true,
//     strikethrough: true,
//     tasklists: true
//   });

//const [selectedTab, setSelectedTab] = React.useState<"write" | "preview">("write");
const maxNumber = 10;
const maxMbFileSize = 8;

class ForumCreatePage extends React.Component {
    
    
    constructor(props) {
        super(props)
        this.state = { step: "feedback", form : {time: '',topic: '', message: '' } ,selectedTab:"write" , mdeState : "", pictures: [] };
        //this.state = { step: "register", message : "", form : {username: '', phone: '',verify_code: '', password: '' }};
        
        // this.converter = new Showdown.Converter({tables: true, simplifiedAutoLink: true,strikethrough: true, tasklists: true});
       // this.converter = new Showdown.Converter({tables: true, simplifiedAutoLink: true,strikethrough: true, tasklists: true});
        this.converter = new Showdown.Converter({
            tables: true,
            simplifiedAutoLink: true,
            strikethrough: true,
            tasklists: true,
          });

        this.formSubmit      = this.formSubmit.bind(this);
        // this.onDrop             = this.onDrop.bind(this);

    }

    handleValueChange = (mdeState) => {
        this.setState({mdeState : mdeState});
    };

    handleTabChange = (tab) => {
        console.log(tab+"----------")
        this.setState({ selectedTab : tab });
    };

    //React.useState<"write" | "preview">("write");

    formSubmit() {
        let data
        //console.log(this.state.form.phone.length)

        if( this.state.form.topic !== "" &&  this.state.mdeState !== "") {
            data = {
                topic : this.state.form.topic,
                message : this.state.mdeState,
                time : this.state.form.time
            }
            this.setState({  message : "Sending Data" });
            this.sendFeedbackApi(data);
            
        } else {
            //console.log(this.state.message)
            this.setState({  message : "Please fill up required field." });
        }
        
    }

    onChange = (imageList) => {
        // data for submit
        this.fileUpload(imageList);
    };
    
    fileUpload = (imageList) => {
        let fd = imageList;
        axios.post(window.BPdata.apiUrl+'/user/image/upload', fd).then(res=>
            {
                if(res.data.length>0) {
                    for (let index = 0; index < res.data.length; index++) {
                        let row = res.data[index];
                        let img = '!['+row.name+']('+row.url+' "'+row.name+'")';
                        let data = this.state.mdeState +' '+ img;
                        this.setState({mdeState: data });
                    }
                }
            }
        );         
    }  

    inputChangeHandler(e) {
        let form = {...this.state.form};
        form[e.target.name] = e.target.value;
        this.setState({
            form
        });
    }

    sendFeedbackApi(formdata) {
        var self = this;
        let dataurl = window.BPdata.apiUrl+'/user/forum/create';
        axios({
            method: 'post',
            url: dataurl,
            data: formdata,
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
        })
        .then(function (response) {
            if(response.data.success) {
                self.setState({  form : {topic: '', message: '' } , message : "အကြံပြုချက် အတွက်ကျေးဇူးတင်ပါသည်။", mdeState : null});
                self.props.history.push("/forum");
            } else {
                self.setState({  message : "တစ်ခုခု မှားယွင်းနေပါသည်။" });
            }
            //console.log(response.data.success);
        })
        .catch(function (error) {
            console.log(error);
        });
    }


    feedbackBlock() {
        return <div className="row">
                    <div className="row">
                        <div className="form-group text-left">
                            <label>{window.lang.form.title}</label>
                        </div>
                        <div className="form-group text-left">
                            <input type="text" className="form-control" name="topic" onChange={(e) => this.inputChangeHandler.call(this, e)} value={this.state.form.topic} /><br />
                        </div>
                        
                    </div>
                    <div className="row">
                        <div className="form-group text-left">
                            <label>{window.lang.form.description}</label>
                        </div>
                        <div className="form-group text-left">
                        
                            <ReactMde
                                toolbarCommands={[["code", "bold"], ["italic"]]}
                                name="message"
                                value={this.state.mdeState}
                                onChange={this.handleValueChange}
                                selectedTab={this.state.selectedTab}
                                onTabChange={this.handleTabChange}
                                generateMarkdownPreview={(markdown) => Promise.resolve(this.converter.makeHtml(markdown))}
                            />

                        {/* <textarea className="form-control text-left"  name="description" onChange={(e) => this.inputChangeHandler.call(this, e)}  rows="3">
                            {this.state.form.description} 
                        </textarea> */}
                            {/* <ImageUploader
                                withIcon={true}
                                buttonText='Choose images'
                                onChange={this.onDrop}
                                imgExtension={['.jpg', '.gif', '.png', '.gif']}
                                maxFileSize={5242880}
                            /> */}
                         

                        <br/>
                        </div>
                        <input type="submit" className="btn btn-success grid-40" value={window.lang.form.submit} onClick={this.formSubmit} /><br />
                    </div>
                       
        </div>
    }


    

    componentDidMount() {

        if(localStorage.getItem(window.BPdata.time)) {
            if(localStorage.getItem(window.BPdata.time) !== "0" ) {
                //localStorage.setItem(window.BPdata.time, true);
                console.log("ok");
            } else {
                this.props.history.push("/more/sign-up");
                }
        } else {
            this.props.history.push("/more/sign-up");
        }

        // if(localStorage.getItem(window.BPdata.time)) {
        //     if(localStorage.getItem(window.BPdata.time) !== "0" ) {
        //       this.setState({ step: "feedback" }); 
        //     }
            
        //   } 

        if(localStorage.getItem('username')) {
                this.setState({ 
                    form: {
                      time : localStorage.getItem(window.BPdata.time)
                    } 
                }); 
        } 

        this.hisUrl('/forum/create');
    }


    

    hisUrl(url) {
        if(localStorage.getItem("route")) {
          let menu = JSON.parse(localStorage.getItem("route"));
          //console.log(menu.length+"-------------")
          if(menu.length === "0") {
            let data = ["/"];
            localStorage.setItem("route", JSON.stringify(data) );
          } 
           if(menu.length > 0) {
              //console.log(menu[menu.length - 1] + "-" + url )
              if(menu[menu.length - 2] === url ){
                    menu.pop();
                    //window.BPdata.route = JSON.stringify(menu);
                    localStorage.setItem("route", JSON.stringify(menu) );
                    //localStorage.setItem("route", JSON.stringify(menu) );
                }  
                else if(menu[menu.length-1] !== url ){
                    menu.push(url)
                    //window.BPdata.route = JSON.stringify(menu);
                    localStorage.setItem("route", JSON.stringify(menu) );
                    
                }
                //window.BPdata.preurl =  menu[menu.length-1];
                window.BPdata.preurl =  menu[menu.length-2];
            }
        }
      }
    

    render() {
        const { value } = this.state
        return (
            <div className="page">
                <div className="page-content text-left">

                    <div className="main-content" key="0">
                    <br />
                   
                    <div className="list-header">
                        <img src="/img/badge.png" class="list-img-circle" alt={window.lang.title.forum} /><span>{window.lang.title.forum}</span>
                    </div>
                    <br/>
                    <span>{this.state.message}</span>
                    
                    { this.feedbackBlock() }
                   
                    <div className="ant-back-footer">
                            <div class="ant-back-top">
                                <div class="ant-back-top-content">
                                    <Link to={window.BPdata.preurl} className="color-green" >
                                { (JSON.parse(localStorage.getItem("route")) !== null) 
                                ? ((this.state.url !== "/" || JSON.parse(localStorage.getItem("route"))[JSON.parse(localStorage.getItem("route")).length-1] !== "/") ?  <img src="/img/back-arrow.png" alt="back" />: ""  ) : "" }
                                </Link> 
                                </div>
                            </div>
                        </div> 
                    </div>
                </div>
            </div>
        );
    }
}

export default ForumCreatePage;