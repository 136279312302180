import React from 'react';
import axios from 'axios';
import {
    Link
  } from 'react-router-dom';
var moment = require('moment');
//var moment = require('moment');

class WinnerTopTentwoDListPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = { 
            game_type : 1,
            step: "login", 
            message : "", 
            data : [
                {
                    order : {
                        customer : {
                            phone : ""
                        }
                    }
                }
            ],
            setting:[{"option_id":8,"option_name":"minimum_amount","option_value":"10","autoload":"yes"},{"option_id":9,"option_name":"twod_multiple","option_value":"70","autoload":"yes"},{"option_id":10,"option_name":"threed_multiple","option_value":"300","autoload":"yes"},{"option_id":11,"option_name":"twod_commission","option_value":"5","autoload":"yes"},{"option_id":12,"option_name":"twod_maximum_amount","option_value":"5000","autoload":"yes"},{"option_id":13,"option_name":"threed_maximum_amount","option_value":"5000","autoload":"yes"}]
        };
        
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    hisUrl(url) {
        if(localStorage.getItem("route")) {
          let menu = JSON.parse(localStorage.getItem("route"));
          //console.log(menu.length+"-------------")
          if(menu.length === "0") {
            let data = ["/"];
            localStorage.setItem("route", JSON.stringify(data) );
          } 
           if(menu.length > 0) {
              //console.log(menu[menu.length - 1] + "-" + url )
                if(menu[menu.length - 2] === url ){
                    //console.log("first");
                    menu.pop();
                    //window.BPdata.route = JSON.stringify(menu);
                    localStorage.setItem("route", JSON.stringify(menu) );
                    //localStorage.setItem("route", JSON.stringify(menu) );
                }  
                else if(menu[menu.length-1] !== url ){
                    menu.push(url)
                    //window.BPdata.route = JSON.stringify(menu);
                    localStorage.setItem("route", JSON.stringify(menu) );
                }
                window.BPdata.preurl =  menu[menu.length-2];
            }
        }
      }

    currencyFormat(num) {
        return num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
       // return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }

    getAnnouncementsApi() {
        var self = this;
        let dataurl = window.BPdata.apiUrlCMS+'/winner-top-ten-list?type='+this.state.game_type;
        let data = this.state.data;
        axios({
            method: 'get',
            url: dataurl,
            data: data,
            headers: {
              "Content-Type": "application/json; charset=utf-8",
                "X-Trident-Token" : window.BPdata.token,
                "BP-Agent"        : window.BPdata.rand_compound,
                "BP-Time"         : localStorage.getItem(window.BPdata.time)
              // "Content-Type": "application/x-www-form-urlencoded",
          },
        })
        .then(function (response) {
            if(response.data.data) {
                self.setState({  
                    data : response.data.data.data, 
                    setting : response.data.setting
                });

                // localStorage.setItem("noti", JSON.stringify(response.data.data) )
                //self.setState({  message : "Successfully updated" });
                //console.log(response.data.data)
            } else {
                self.setState({  message : "Something was wrong" });
            }
            //console.log(response.data.success);
        })
        .catch(function (error) {
            //console.log(error);
        });
    }

    
    
    handleInputChange(event) {
        const target = event.target;
        let value = target.type === 'radio' ? target.checked : target.value;
        let rev_value;
        if(value) {
            value     = 1;
            rev_value = 0;
        } else {
            value     = 0;
            rev_value = 1;
        }
        const name = target.name;
        let str = this.state.contests;
        // console.log(name)
        // console.log(name.split("-").length)

        if(name.split("-").length>0) {
            let word  = name.split("-");
            let first = word[0];
            let id    = word[1];
            console.log(first)
            if(first === "per_result1") {
                str[id].per_result1 = value;
                str[id].per_result2 = rev_value;
            }

            if(first === "per_result2") {
                str[id].per_result2 = value;
                str[id].per_result1 = rev_value;
            }

            if(first === "over") {
                str[id].over = value;
                str[id].under = rev_value;
            }

            if(first === "under") {
                str[id].under = value;
                str[id].over = rev_value;
            }
            
        }
        
        

        this.setState({contests:str});
        //console.log(name)
        // this.setState({
        //   [name]: value
        // });
        console.log(this.state.contests)
    }

    displayBlock( ) {
        let data = this.state.data
        //console.log(data.length)
        if(data.length  > 0){
            let ui = []
            // data.forEach(function(each){
            for (let i = 0; i < data.length; i++) { 
                let each = data[i]

                ui.push(<div style={{ display: '-webkit-box', display: 'flex' }} className="box-paragraph">
           
                <div className="row p-4" style={{ lineHeight: 1 }}>
                            <div className=" grid-100">
                                    <div className="grid-20 left">
                                        <img src="/img/myaccounticon.png" style={{ height : '50px' }}/>
                                    </div>
                                    <div className="grid-40 left">
                                        {each.order.customer.first_name }
                                        <br /><br />
                                       
                                        {each.order.customer.phone.substring(0, each.order.customer.phone.length - 4)+"****"}
                                    </div>
                                    <div className="grid-20 left">
                                        {each.total } Ks
                                    </div>
                                    <div className="grid-20 left color-blue text-center">
                                        { (each.product_type == 1) ? this.currencyFormat(each.total*this.state.setting[1].option_value)  : this.currencyFormat(each.total*this.state.setting[2].option_value) } Ks
                                    </div>
                                
                            </div>
                    </div>
                  </div>);

              
                
                
           }
           if(ui.length === 0) {
                ui.push(<div className="text-center" key="0"><h2>အချက်အလက်များရှာမတွေ့ပါ။</h2></div>)
           }
            return ui
        }
    }

    componentDidMount() {
        // console.log(mmph.normalizeInput('+၉၅၉၇၈၄၁၂၃၄၅၆')); // returns 09784123456
        // console.log(mmph.normalizeInput('+959784123456')); // returns 09784123456
        // console.log(mmph.getTelecomName('09978412345')); // returns Ooredoo
        // console.log(mmph.getPhoneNetworkType('09978412345')); // returns GSM
        // console.log(mmph.isValidMMPhoneNumber('09978412345')); // returns true if it meets Myanmar Phone
        if(localStorage.getItem(window.BPdata.time)) {
            if(localStorage.getItem(window.BPdata.time) !== "0" ) {
              this.setState({ step: "profile" }); 
            }
            
          } 

        if(localStorage.getItem('noti')) {
            if(JSON.parse(localStorage.getItem("noti")) != null) {
              let noti = JSON.parse(localStorage.getItem("noti"));
              this.setState({data: noti})
              
            }
        } 
        // if(localStorage.getItem('username')) {
        //         this.setState({ 
        //             form: {
        //                 username : localStorage.getItem('username')
        //             } 
        //         }); 
        // } 

        // localStorage.setItem(window.BPdata.time, 0);
        // localStorage.setItem('username', "");
        // else {
        //   localStorage.setItem(window.BPdata.time, 'profile');
        //   this.setState({ step: "home" }); 
        // }
        this.hisUrl('/more/announcements');
        //localStorage.setItem('route', '/more/login');
        //console.log(localStorage.getItem('route'))
        this.getAnnouncementsApi();
    }

    // componentWillUpdate() {
    //     localStorage.setItem('route', '/more/login');
    //     console.log(localStorage.getItem('route'))
    // }

    render() {
        return (
            <div className="page">
                <div className="page-content">
                    <div className="main-content text-center">
                        
                        <div className="row text-left">
                            <br />
                            <br/>
                            <div className="row p-4" style={{ lineHeight: 1, display: 'flex' }}>
                                <div className="grid-100 mb-10">
                                        <div className="grid-20 left">
                                            &nbsp;
                                        </div>
                                        <div className="grid-40 left">
                                            &nbsp;
                                        </div>
                                        <div className="grid-20 left">
                                            Amount
                                        </div>
                                        <div className="grid-20 left text-center">
                                            Win
                                        </div>
                                    
                                </div>
                            </div>
                            {this.displayBlock()}
                        </div>
                      {/*  <div className="ant-back-footer">
                            <div class="ant-back-top">
                                <div class="ant-back-top-content">
                                    <Link to={window.BPdata.preurl} className="color-green" >
                                { (JSON.parse(localStorage.getItem("route")) !== null) 
                                ? ((this.state.url !== "/" || JSON.parse(localStorage.getItem("route"))[JSON.parse(localStorage.getItem("route")).length-1] !== "/") ?  <img src="/img/back-arrow.png" alt="back" />: ""  ) : "" }
                                </Link> 
                                </div>
                            </div>
                        </div>*/}
                    </div>
                </div>
            </div>
        );
    }
}

export default WinnerTopTentwoDListPage;