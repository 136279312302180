import React from 'react';
import axios from 'axios';
import {
    Link
  } from 'react-router-dom';
var moment = require('moment');
//var moment = require('moment');

class ParticipantPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = { 
            selectValue: "",
            step: "login", 
            message : "", 
            data : [
                {id:	1, title : "Shwe Sports", type	: 0, description : "Shwe Sports version 1.1.2 is available now", link:"https://shwe-sports.com",
                    created_at : "2019-05-21 08:14:30" },
            ],
            standingstep: {
                0 : [{ name : 'England', mmname : 'asdd', id: 1,  option : "" }, { name : 'Germeny', mmname : 'asdd', id: 2,  option : "" }, { name : 'Purtuge', mmname : 'asdd', id: 3,  option : "" }],
                1 : [{ name : 'England', mmname : 'asdd', id: 1,  option : "" }, { name : 'Germeny', mmname : 'asdd', id: 2,  option : "" }, { name : 'Purtuge', mmname : 'asdd', id: 3,  option : "" }],
            }
        };
        
        // this.handleDropdownChange = this.handleDropdownChange.bind(this,id);
    }

    handleDropdownChange(name,id,e) {
        //console.log(id)
        //this.setState({ standingstep})
        let subid = parseInt(e.target.selectedIndex)-1;

        if(subid > -1) {
            // console.log(e.target);
            // console.log("ssss  "+(parseInt(e.target.selectedIndex)-1))

            let str = this.state.standingstep;
            // let temp;
            
            //individual group clean up
            let cleandata = str[id].map((data) => {
                if(data.option === name) {
                    data.option = "";
                }
                return data;

             });

            str[id] = cleandata;
            str[id][subid].option = name;

            this.setState({standingstep:str})
        }
        
        //this.setState({ selectValue: e.target.value });
        //console.log("selected value" + e.target.value)
    }

    hisUrl(url) {
        if(localStorage.getItem("route")) {
          let menu = JSON.parse(localStorage.getItem("route"));
          //console.log(menu.length+"-------------")
          if(menu.length === "0") {
            let data = ["/"];
            localStorage.setItem("route", JSON.stringify(data) );
          } 
           if(menu.length > 0) {
              //console.log(menu[menu.length - 1] + "-" + url )
                if(menu[menu.length - 2] === url ){
                    //console.log("first");
                    menu.pop();
                    //window.BPdata.route = JSON.stringify(menu);
                    localStorage.setItem("route", JSON.stringify(menu) );
                    //localStorage.setItem("route", JSON.stringify(menu) );
                }  
                else if(menu[menu.length-1] !== url ){
                    menu.push(url)
                    //window.BPdata.route = JSON.stringify(menu);
                    localStorage.setItem("route", JSON.stringify(menu) );
                }
                window.BPdata.preurl =  menu[menu.length-2];
            }
        }
      }

    getAnnouncementsApi() {
        var self = this;
        let dataurl = window.BPdata.apiUrl+'/user/announcements';
        let data = this.state.data;
        axios({
            method: 'get',
            url: dataurl,
            data: data,
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
        })
        .then(function (response) {
            if(response.data.data) {
                self.setState({  
                    data : response.data.data, 
                });
                //self.setState({  message : "Successfully updated" });
                //console.log(response.data.data)
            } else {
                self.setState({  message : "Something was wrong" });
            }
            //console.log(response.data.success);
        })
        .catch(function (error) {
            // console.log(error);
        });
    }

    
    
    displayBlock( ) {
        let data = this.state.data
        //console.log(data.length)
        if(data.length  > 0){
            let ui = []
            // data.forEach(function(each){
            for (let i = 0; i < data.length; i++) { 
                let each = data[i]
                ui.push(<div className="league-box "><div className="league-list-header p-4"><span>{each.title}</span></div><div className="league-box-paragraph"><div className="row">
                {each.description}<br/><sub><i>{moment(each.created_at, 'YYYY-MM-DD hh:mm:ss').format('DD MMMM YYYY, hh:mm A')}</i></sub></div></div></div>)
                
                
           }
           if(ui.length === 0) {
                ui.push(<div className="text-center" key="0"><h2>အချက်အလက်များရှာမတွေ့ပါ။</h2></div>)
           }
            return ui
        }
    }

    componentDidMount() {
        if(localStorage.getItem(window.BPdata.time)) {
            if(localStorage.getItem(window.BPdata.time) !== "0" ) {
              this.setState({ step: "profile" }); 
            }
            
          } 

        //let standinglen = this.state.standingstep.length;

        // console.log(this.state.standingstep)
        // for(let i=0; i < this.state.standingstep.length; i++) {
        //     console.log(this.state.standingstep[i])
        // }

        // let letters = .map(function(item){
        //     return item
        //     // if(item !== "b") {
        //     //     return 
        //     // }
        // })

        //console.log(letters)
        // if(localStorage.getItem('username')) {
        //         this.setState({ 
        //             form: {
        //                 username : localStorage.getItem('username')
        //             } 
        //         }); 
        // } 

        // localStorage.setItem(window.BPdata.time, 0);
        // localStorage.setItem('username', "");
        // else {
        //   localStorage.setItem(window.BPdata.time, 'profile');
        //   this.setState({ step: "home" }); 
        // }
        //this.hisUrl('/more/announcements');
        //localStorage.setItem('route', '/more/login');
        //console.log(localStorage.getItem('route'))

        //this.getAnnouncementsApi();
    }

    // componentWillUpdate() {
    //     localStorage.setItem('route', '/more/login');
    //     console.log(localStorage.getItem('route'))
    // }

    render() {
        return (
            <div className="page">
                <div className="page-content text-center">
                    
                    <div className="row text-left">
                        <br />
                        <div className="list-header">
                            <img src="/img/badge.jpg" className="list-img-circle" alt={window.lang.more.announcement} /><span>{window.lang.more.announcement}</span>
                        </div>
                        <br/>
                        <div>

                            
                        <table className="table text-center tree-table">
                            <tbody>
                                <tr>
                                    <td className="text-left tb-first bg-second  color-first align-middle td1">A1</td>
                                    <td style={{borderRight: "1px solid #dee2e6"}} className="td2">
                                    <div className="input-group">
                                        <select className="form-control" name="A1" id="A1"  onChange={this.handleDropdownChange.bind(this,"A1",0)} defaultValue="0">
                                            <option value="0">Select</option>
                                            {this.state.standingstep[0].map((item) => {
                                                return <option  value={item.id} key={item.id}>{item.name}</option>
                                            })}
                                        </select>
                                    </div>
                                    </td>
                                    <td rowSpan="3" className="no-bd align-middle no-padding td3">
                                    <hr />
                                    </td>
                                    <td className="no-bd td4">
                                    </td>
                                    <td className="no-bd td5"> 
                                    </td>
                                </tr> 
                                <tr>
                                    <td colSpan="2" style={{borderRight: "1px solid #dee2e6",borderLeft: "0px !important" }}>
                                    </td>
                                    <td className="text-left tb-first bg-second  color-first align-middle">
                                    Q1
                                    </td>
                                    <td className="rg-bd">
                                    <div className="input-group">
                                    <select className="form-control" name="Q1" id="Q1"  onChange={this.handleDropdownChange.bind(this,'Q1',0)} defaultValue="0">
                                            <option value="0">Select</option>
                                            {this.state.standingstep[0].map((item) => {
                                                return <option  value={item.id} key={item.id}>{item.name}</option>
                                            })}
                                    </select>
                                    </div>
                                </td>
                                </tr>          
                                <tr>
                                    <td className="text-left tb-first bg-second  color-first align-middle">B2</td>
                                    <td className="bt-bd tdr">
                                    <div className="input-group">
                                        <select className="form-control" name="B2" id="B2" onChange={this.handleDropdownChange.bind(this,'B2',0)} defaultValue="0">
                                            <option value="0">Select</option>
                                            {this.state.standingstep[0].map((item) => {
                                                return <option  value={item.id} key={item.id}>{item.name}</option>
                                            })}     
                                        </select>
                                    </div>

                                    </td>
                                    <td>
                                    </td>
                                    <td> 
                                    </td>
                                </tr>             
                            </tbody>
                        </table>
                            {/* <div>
                                <select id="dropdown" onChange={this.handleDropdownChange('AA')}>
                                    <option value="N/A">N/A</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                </select>
                            </div> */}

                            {/* <div>Selected value is : {this.state.selectValue}</div> */}
                        </div>
                        {/* {this.displayBlock()} */}
                    </div>
                    <div className="ant-back-footer">
                        <div className="ant-back-top">
                            <div className="ant-back-top-content">
                                <Link to={window.BPdata.preurl} className="color-green" >
                            { (JSON.parse(localStorage.getItem("route")) !== null) 
                            ? ((this.state.url !== "/" || JSON.parse(localStorage.getItem("route"))[JSON.parse(localStorage.getItem("route")).length-1] !== "/") ?  <img src="/img/back-arrow.png" alt="back" />: ""  ) : "" }
                            </Link> 
                            </div>
                        </div>
                    </div>
                </div>
               
            </div>
        );
    }
}

export default ParticipantPage;