import React,{Component} from 'react';
// import {Helmet} from "react-helmet";
//import { FacebookProvider, Comments } from 'react-facebook';
import axios from 'axios';
import swal from 'sweetalert';
var moment = require('moment');


let NUM_ROWS = 20;




class InvoiceListDetailPage extends Component {
    constructor(props) {
      super(props);

      window.BPdata.footerMenu = "home";
      this.state = {
        data : {
            title : '',
            body : '',
            featured_img: '',
            shop : {
              name : ''
            }
        },
        form : { 
            cusbank_bank_acc_name: '', 
            cusbank_bank_num: '',
            bank_transaction_id: '', 
            division: 0 ,
            "dagonbank": {
                "id":3,
                "holdername":"",
                "bank_type":2,
                "cardnum":"",
                "shortcode": ""
            },
        },
        is_form : true,
        latest_data : [],
        isLoading: true,
        post_id : 1,
        leagueMenu: 'NEWS',
        menu : window.BPdata.mainmenu,
        comments: [],
        comment : null,
        advertise: {},
        loading: true,
        liked : 0,
        fetch : 0,
        payment_request_id : null,
        order_items:[{
            "id":1,
            "contest_id":0,
            "orders_id":1,
            "product_id":"1",
            "sku":"00","product_type":"1","provider_type":"1","name":"00","coupon_code":null,"weight":"0","qty_ordered":"1","discount_percent":null,"price":"300.00","discount_amount":null,"total":"300.00","item_data":null,"message":"","payment_transaction_id":null,"package_id":null,"provider_transaction_id":null,"tracking_id":null,"tracking_status":null,"informed":0,"commission":0,"created_at":"2021-12-14T08:12:59.000000Z","updated_at":"2021-12-14T08:12:59.000000Z"
          }],
        contest : {
            schedule_at : "2022-03-23 16:30:00"
        },
        permit : {
            bank_types_s : [
                { id : 1, icon: '/img/bank/kpay_logo.png',icon_off: '/img/bank/kpay_logo.png',shortcode: 'K Pay', check: 'Tennis', url : "/more" },
                { id : 2, icon: '/img/bank/cb_logo.png',icon_off: '/img/bank/cb_logo.png',shortcode: 'CB', check: 'Ball', url : "/more/wallet-store" },
                { id : 3,icon: '/img/bank/wave_logo.png',icon_off: '/img/bank/wave_logo.png',shortcode: 'Wave Money', check: 'Tennis', url : "/more" },
                { id : 4, icon: '/img/bank/ logo_kbz.png',icon_off: '/img/bank/logo_kbz.png',shortcode: 'KBZ', check: 'Basket Ball', url : "/contact-us" },
                { id : 5, icon: '/img/bank/‌aya_logo.png',icon_off: '/img/bank/‌aya_logo.png',shortcode: 'AYA', check: 'Football', url : "/more/announcements"},
            ],
            bank_types : [
                { id : 1, shortcode : "AYA" , image : "/img/banks/active/aya.png", non_image : "/img/banks/in-active/aya.png" },
                { id : 2, shortcode : "CB" , image : "/img/banks/active/cb.png", non_image : "/img/banks/in-active/cb.png"  },
                { id : 3, shortcode : "KBZ" , image : "/img/banks/active/kbz.png", non_image : "/img/banks/in-active/kbz.png"  },
                { id : 4, shortcode : "K Pay" , image : "/img/banks/active/kpay.png", non_image : "/img/banks/in-active/kpay.png" },
                { id : 5, shortcode : "Wave Moneny" , image : "/img/banks/active/wavepay.png", non_image : "/img/banks/in-active/wavepay.png" },
            ],
            bank_accounts : [
                { id : 1, bank_type: 1, shortcode : "AYA", holdername : "", cardnum : "" }
            ],
            specfic_bank_accounts : [
                { id : 1, bank_type: 1, shortcode : "AYA", holdername : "", cardnum : "" }
            ],
            customer_banks : [
                 { id : 1, bank_type: 1,bank_acc_name : "", bank_num : "" }
            ],
            specfic_cus_bank_accounts : [
                  { id : 1, bank_type: 1,bank_acc_name : "", bank_num : "" }
            ],
            from_customer : {
                id : 0,
                first_name : "",
                date_of_birth : ""
            },
            to_customer : {
                id : 0,
                first_name : "",
                date_of_birth : ""
            },
        }
      };

      if(props.match.params.id) {
        this.state.post_id = props.match.params.id
      }

      this.sendPayStatusEditApi  = this.sendPayStatusEditApi.bind(this);
      this.onBankCopyChange = this.onBankCopyChange.bind(this);
      // this.addComment = this.addComment.bind(this);

    }

    // addComment(comment) {

    //   //this.props.history.push("/forum/15");
    //   this.setState({
    //     loading: false,
    //     // comments: [ comment, ...this.state.comments],
    //     fetch : 1
    //     //comments: [...this.state.comments,comment],
    //     //comments: [this.state.comments],
    //     // comment : comment
    //   });
    //   this.fetchCommentData();
    // }

    UNSAFE_componentWillMount() {
      window.BPdata.lang = localStorage.getItem("lang");
    }

    onBankCopyChange() {

        //alert("ok");

        window.navigator.clipboard.writeText(this.state.form.dagonbank.cardnum )
        swal(this.state.form.dagonbank.cardnum + " Copied")

    }

    componentDidMount() {

        if(localStorage.getItem(window.BPdata.time)) {
          if(localStorage.getItem(window.BPdata.time) !== "0" ) {
            //localStorage.setItem(window.BPdata.time, true);
            // this.setState({ step: "form" });

          } else {
             this.props.history.push("/more/login");
            }
        } else {
            this.props.history.push("/more/login");
        }

        this.fetchData();
        // this.fetchLatestData();

        let self = this;

        //console.log(localStorage.getItem('route'))
        // loading
        this.setState({ loading: true });

        this.hisUrl('/more/bet-list/'+this.state.post_id)
        clearTimeout(window.BPdata.liveurl);
    }

    inputChangeHandler(e) {
        let form = {...this.state.form};
        form[e.target.name] = e.target.value;
        this.setState({
            form
        });
    }

    hisUrl(url) {
      if(localStorage.getItem("route")) {
        let menu = JSON.parse(localStorage.getItem("route"));
        //console.log(menu.length+"-------------")
        if(menu.length === "0") {
          let data = ["/"];
          localStorage.setItem("route", JSON.stringify(data) );
        }
         if(menu.length > 0) {
            //console.log(menu[menu.length - 1] + "-" + url )
              if(menu[menu.length - 2] === url ){
                  //console.log("first");
                  menu.pop();
                  //window.BPdata.route = JSON.stringify(menu);
                  localStorage.setItem("route", JSON.stringify(menu) );
                  //localStorage.setItem("route", JSON.stringify(menu) );
              }
              else if(menu[menu.length-1] !== url ){
                  menu.push(url)
                  //window.BPdata.route = JSON.stringify(menu);
                  localStorage.setItem("route", JSON.stringify(menu) );
              }
              window.BPdata.preurl =  menu[menu.length-2];
          }
      }
    }

    componentDidUpdate() {

    }

    orderStatus(id) {
        if(id == 1) {
          return "Awaiting payment";
        } else if (id == 2) {
          return "Processing";
        } else if (id == 3) {
          return "Cancel";
        } else if (id == 4) {
          return "Completed";
        } else {
          return "Pending";
        }
    }

    fetchData() {

          let dataurl = window.BPdata.apiUrl+'/service/invoice-list/detail/'+this.state.post_id;
          // let dataurl = window.BPdata.apiUrl+'/news/detail/'+this.state.post_id;

          axios.get(dataurl, {
            method: "GET", // *GET, POST, PUT, DELETE, etc.
            mode: "no-cors", // no-cors, cors, *same-origin
            cache: "force-cache", // *default, no-cache, reload, force-cache, only-if-cached
            //credentials: "same-origin", // include, *same-origin, omit
            crossDomain: true,
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                "X-Trident-Token" : window.BPdata.token,
                "BP-Agent"         : window.BPdata.rand_compound,
                "BP-Time"         : localStorage.getItem(window.BPdata.time) 
                // "Content-Type": "application/x-www-form-urlencoded",
            },
            redirect: "follow", // manual, *follow, error
            referrer: "no-referrer", // no-referrer, *client
            //body: JSON.stringify(data), // body data type must match "Content-Type" header
          })
          .then(resp => {
                let is_form = false;

                if(resp.data.data.cusbank_bank_acc_name == null ) {
                    is_form = true;
                }

                // this.setState({
                //     data: resp.data.data,
                //     isLoading: false,
                //     is_form : is_form
                // });

                // console.log(resp.data.data.first_name)

                this.setState(prevState => ({
                    data: resp.data.data,
                    isLoading: false,
                    is_form : is_form,
                    form : {
                        ...prevState.form,
                        cusbank_bank_acc_name : resp.data.data.first_name
                    },
                    permit : {
                      ...prevState.permit,
                      specfic_bank_accounts : resp.data.bank_accounts,
                      bank_accounts : resp.data.bank_accounts,
                      bank_types : resp.data.bank_types,
                    },
                    dagonbank : {
                        ...prevState.dagonbank,
                        id : resp.data.data.dagonbank_id,
                        bank_type : resp.data.data.cusbank_bank_type,
                      }
                  }))
                
                    // alert(resp.data.data.cusbank_bank_type);
                  //this.onCompanyBankTypeChange();
                  this.onListBankTypeChange(1,resp.data.data.cusbank_bank_type);

              // console.log(resp.data.payment_request_id)
          })
          .catch(function(error) {
            //console.log(error);
            //console.log(error.tostring());
          });

    }

      sendPayStatusEditApi() {

        let formdata = this.state.form;

        // console.log(formdata);
        
        let self = this;
        let dataurl = window.BPdata.apiUrl+'/service/invoice-list/detail/'+this.state.post_id;
        // let dataurl = window.BPdata.apiUrlCMS+"/player/login";
        axios({
            method: 'post',
            url: dataurl,
            data: formdata,
            headers: { 
                'content-type': 'application/json;charset=UTF-8',
                "X-Trident-Token" : window.BPdata.token,
                "BP-Agent"         : window.BPdata.rand_compound,
                "BP-Time"         : localStorage.getItem(window.BPdata.time) 
            },
        })
        .then(function (response) {

            if(response.data.status_code == 403) {
                self.setState({  message : response.data.message, error : response.data.error, status : response.data.status_code  });
            } else if(response.data.status_code == 200) {
                self.setState({  message : "Successfully Submited",  status : response.data.status_code , is_form : false  });
                self.fetchData();
            }  else {
                self.setState({  message : "တစ်ခုခုတော့မှားနေပြီ" , status : response.data.status_code, is_form : true  });
            }
            //console.log(response.data.success);
        })
        .catch(function (error) {
            // console.log(error);
        });

    }

    // fetchLatestData() {

    //   if(JSON.parse(localStorage.getItem("videoSlides")) != null) {
    //     let video_slides = JSON.parse(localStorage.getItem("videoSlides"));
    //     this.setState({
    //       latest_data: video_slides
    //     });
    //   }
    // }

    onListBankTypeChange(e, id) {

        // alert(e)

        let bank_type;


        if(e === undefined){
            bank_type = 1;
        }

        if(id === undefined){

            bank_type = e.target.value;
            // return
        } else {
            bank_type = id;
        }

        // alert(bank_type)
        // alert(id)

        // console.log(e)

        let find = 0;
        let dagonbank = this.state.form.dagonbank;
        let bank_accounts = [];
        let specfic_cus_bank_accounts = [];
        let specfic_bank_accounts = [];

        let selectcustomerbank = {};

        // let bank_type = this.form.customerbank.bank_type;

        // console.log(this.permit.customer_banks.length + "-----")


        {/*console.log(this.state.permit.customer_banks)*/}

        for(let j = 0; j<this.state.permit.bank_accounts.length; j++){

            if(bank_type == this.state.permit.bank_accounts[j].bank_type) {
                bank_accounts.push({ id : this.state.permit.bank_accounts[j].id, bank_type: this.state.permit.bank_accounts[j].bank_type, shortcode : this.state.permit.bank_accounts[j].shortcode, holdername :  this.state.permit.bank_accounts[j].holdername, cardnum :  this.state.permit.bank_accounts[j].cardnum })
            }

            // console.log(this.permit.bank_accounts[i].shortcode+"--------")
        }



       {/* if(specfic_cus_bank_accounts.length > 0) {
            selectcustomerbank.id = specfic_cus_bank_accounts[0].id;
            selectcustomerbank.bank_acc_name  = specfic_cus_bank_accounts[0].bank_acc_name;
            selectcustomerbank.bank_type  = specfic_cus_bank_accounts[0].bank_type;
            selectcustomerbank.bank_num  = specfic_cus_bank_accounts[0].bank_num;
        }*/}

        if(bank_accounts.length > 0){

                let rannum = Math.floor((Math.random() * bank_accounts.length));

                dagonbank.id = bank_accounts[rannum].id;
                dagonbank.holdername = bank_accounts[rannum].holdername;
                dagonbank.bank_type = bank_accounts[rannum].bank_type;
                dagonbank.cardnum = bank_accounts[rannum].cardnum;
                dagonbank.shortcode = bank_accounts[rannum].shortcode;
        }

        // console.log(bank_accounts)

        // this.setState({ permit : { bank_accounts : [] }});
        // this.setState({ permit : { bank_accounts : bank_accounts }});
        specfic_bank_accounts = [];
        specfic_bank_accounts = bank_accounts;

        {/*specfic_cus_bank_accounts = [];
        specfic_cus_bank_accounts = specfic_cus_bank_accounts; */}

        if(find == 0) {
            selectcustomerbank.id = 1;
            selectcustomerbank.bank_acc_name = "Unknown";
            selectcustomerbank.bank_type  = bank_type;
            selectcustomerbank.bank_num = "";
        }

       {/* console.log(selectcustomerbank)*/}

        this.setState(prevState => ({
            ...prevState,
            form: {
                ...prevState.form,           // copy all other key-value pairs of food object
                customerbank: selectcustomerbank,
                dagonbank : dagonbank
            },
            permit : {
                ...prevState.permit,
                specfic_cus_bank_accounts : specfic_cus_bank_accounts,
                specfic_bank_accounts : specfic_bank_accounts
            }
        }))

        if(e === undefined){
            return ;
        } else {
            e.preventDefault();
        }



        {/*this.setState({ step: "list" }); */}
        {/*
        console.log(this.state.permit.specfic_cus_bank_accounts)*/}
      {/*  console.log(this.state.form.customerbank)

        return */}
    }

    onCompanyBankTypeChange(e) {
        // console.log("ok")
        let bank_accounts = [];

        // let dagonbank_id = this.form.dagonbank.id;
        let dagonbank_id = e.target.value;

        // console.log(dagonbank_id)
        // console.log(this.state.permit.specfic_bank_accounts)
        let selectcompanybank = {};

        for(let i = 0; i< this.state.permit.specfic_bank_accounts.length; i++){

            // console.log(dagonbank_id + this.state.permit.specfic_bank_accounts[i].id)
            if(dagonbank_id == this.state.permit.specfic_bank_accounts[i].id) {

                selectcompanybank.id = this.state.permit.specfic_bank_accounts[i].id;
                selectcompanybank.holdername = this.state.permit.specfic_bank_accounts[i].holdername;
                selectcompanybank.bank_type = this.state.permit.specfic_bank_accounts[i].bank_type;
                selectcompanybank.cardnum = this.state.permit.specfic_bank_accounts[i].cardnum;

                // console.log(this.form.dagonbank.id + "------")

            }

        }

        this.setState(prevState => ({
            form: {
                ...prevState.form,
                dagonbank: selectcompanybank,
            }
        }))


        e.preventDefault();

    }

    displayBlock() {
        let ui = []
        // console.log("liked--------"+this.state.liked)
        if(this.state.data  != null){
            ui.push(
                <div className="main-content" key="1">
                    <div className="row content-box">
                      <div class="grid-100 pl-20">
                          <br />
                          <h2 className="color-red">Invoice No : {this.state.data.invoice_no}</h2><br />
                          <small>Invoice Date : {moment.utc(this.state.data.created_at).local().format('DD MMM YYYY, hh:mm A') } </small><br/>
                          <b>127 Mart</b>
                          <br />
                          <br />
                          
                          <b class="color-blue">{(this.state.data.payment_status) ? this.orderStatus(this.state.data.payment_status) : "Pending"}</b>
                          <br /><br />
                          

                          <table className="table  table-border1 league-table" >
                              <thead>
                                  <tr>
                                      <th className="text-left">Item</th>
                                      <th className="text-right">Price</th>
                                      <th className="text-right">Qty</th>
                                      <th className="text-right">Amount</th>
                                  </tr>
                              </thead>
                              <tbody>

                                  {this.childui(this.state.data.order_items)}


                                  <tr>
                                      <td colSpan="3" className="text-right">Total</td>
                                      <td className="text-right">{this.state.data.grand_total}</td>
                                      <td></td>
                                  </tr>

                              </tbody>
                          </table>
                          <br />
                          <br />
                          { (this.state.data.cusbank_bank_type == "1") ? 
                          <a href={"http://127mart.com/payment/kpay/"+this.state.data.payment_request_id} target="_blank" className="btn btn-success">
                            Pay with KBZpay
                          </a> : <p></p> }

                            
                          <div className="grid-90 grid-s-90 left">
                                <b>ငွေပေးချေရန် အချက်အလက်များ</b><br />
                                <select class="form-control"  value={this.state.form.dagonbank.bank_type}  onChange={(e) => this.onListBankTypeChange.call(this, e)}>
                                            {this.state.permit.bank_types.map(({ id, shortcode }, index) => <option value={id} >{shortcode}</option>)}
                                </select>
                                <br />
                                <span className="color-black"> <b >Merchant Name :</b> <span >{this.state.form.dagonbank.holdername} </span>  </span>
                                <br />
                                <span className="color-black"> <b >Merchant ID :</b><span > {this.state.form.dagonbank.cardnum} 
                                <br />
                                {this.state.form.dagonbank.shortcode} 
                                <span id="copy" onClick={this.onBankCopyChange} ></span>

                                


                                    <span className="text-right color-blue pointer" onClick={this.onBankCopyChange}>&nbsp;&nbsp;ကူးယူမည်</span>
                                    {/*<img src="/img/copy.png" height="20px" />*/}
                                    </span> </span>
                                    <br />

                                <br />
                                                
                            {/* <label class="control-label">Bank Account</label>
                            <select class="form-control" value={this.state.form.dagonbank.id} onChange={(e) => this.onCompanyBankTypeChange.call(this, e)}>
                                        {this.state.permit.specfic_bank_accounts.map(({ id, shortcode }, index) => <option value={id} >{shortcode}</option>)}
                            </select> */}
                        </div>

                        
                          { (this.state.data.cusbank_bank_type != "1") ? 
                          <span >
                          {(this.state.message) ? this.state.message : ""}

                          { (this.state.form.dagonbank.bank_type == "2") ?  <img src="/img/kpay-merchant.jpg" /> : "" }
                           
                            { (this.state.is_form == true ) ?
                            <span>
                                
                              <div className="grid-90 grid-s-90 left">
                                    <br />
                                  <span>ငွေလွဲသူ နာမည်   </span>
                                  <br />
                                  <input id="first_name" type="text" className="form-control" name="cusbank_bank_acc_name" onChange={(e) => this.inputChangeHandler.call(this, e)} value={this.state.form.cusbank_bank_acc_name} required autoFocus />
                              </div>
                          
                          
                              <div className="grid-90 grid-s-90 left">
                                  <span>ငွေလွဲသူ ဖုန်းနံပါတ် / ဘဏ်အကောင့်နံပါတ်  </span>
                                  <br />
                                  <input id="first_name" type="text" className="form-control" name="cusbank_bank_num" onChange={(e) => this.inputChangeHandler.call(this, e)} value={this.state.form.cusbank_bank_num} required autoFocus />
                              </div>
                          
                          
                              <div className="grid-90 grid-s-90 left">
                                  <span>ငွေလွှဲခဲ့သည့် အမှတ်စဉ်</span>
                                  <br />
                                  <input id="email" type="email" className="form-control" name="bank_transaction_id" onChange={(e) => this.inputChangeHandler.call(this, e)} value={this.state.form.bank_transaction_id} required autoFocus />
                                  <br /><br />
                              </div>
                                
                                  <a class="btn btn-success grid-90 pointer"  onClick={this.sendPayStatusEditApi}>{window.lang.form.submit}</a>
                            </span> : <span>
                            <div className="grid-90 grid-s-90 left">
                             <b>ငွေလွဲသူ ဘဏ်အကောင့်</b><br />
                              
                                  {this.state.data.cusbank_bank_acc_name}
                                  <br />
                                  {this.state.data.cusbank_bank_num} 
                                  <br />
                                  {this.state.data.bank_transaction_id}
                                  <br /><br />
                            </div>
                             </span>}
                              
                          
                     
                  </span> : ""
            }
                        </div>
                    </div>
                </div>
            )
        }

        if(ui.length === 0) {
            ui.push(<div className="main-content"><div className="text-center" key="0"><h2>Something was found.</h2></div></div>)
       }

        return ui
    }

    childui(item) {

        let self = this;
        let ui = []
            //console.log("-------------"+data)

            if(Array.isArray(item) === false) {
                return ui;
            } else {

                let arlength = item.length;

                if(arlength  > 0){

                    this.state.data.order_items.forEach(function(each,key){


                        ui.push(<tr key={"ro-"+each.id}>
                            <td  className={"text-left pointer "} >
                                { each.name }
                            </td>
                            <td colSpan="1" className="text-right">

                                { each.price }

                            </td>
                            <td colSpan="1" className="text-right">

                                { each.qty_ordered }

                            </td>

                            <td colSpan="1" className="text-right">

                                { each.total }

                            </td>

                        </tr>)

                    })

                    return ui
                }
        }

        return ui;
    }



    render() {
        const { selected } = this.state;
        let leagueMenu = this.state.leagueMenu;
        // Create menu from items
        // const menu = Menu(this.state.menu, selected, leagueMenu);
        // const loadingSpin = this.state.loading ? "App-logo Spin" : "App-logo";

        return (
            <div className="page">
                <div className="page-content">


                        { (this.state.isLoading) ?  (<div className="row text-center"><br /><img src="/img/loading.gif" alt="loading" height="50px"  /> </div> ) : this.displayBlock() }


                   {/*     <div className="ant-back-footer">
                            <div className="ant-back-top">
                                <div className="ant-back-top-content">
                                    <Link to={window.BPdata.preurl} className="color-dark-red" >
                                { (JSON.parse(localStorage.getItem("route")) !== null)
                                ? ((this.state.url !== "/" || JSON.parse(localStorage.getItem("route"))[JSON.parse(localStorage.getItem("route")).length-1] !== "/") ?  <img src="/img/back-arrow.png" alt="back" />: ""  ) : "" }
                                </Link>
                                </div>
                            </div>
                        </div>*/}

                </div>
            </div>
            );
        }
      }

export default InvoiceListDetailPage;
