/* eslint no-dupe-keys: 0 */
import React,{Component} from 'react';
import { ListView } from 'antd-mobile';
import {
  Link
} from 'react-router-dom';


import ScrollMenu from 'react-horizontal-scrolling-menu';
//import { FacebookProvider, CommentsCount } from 'react-facebook';


import axios from 'axios';
var moment = require('moment');

let NUM_ROWS = 20;
let LIMIT_ROWS = 20;
let TOTAL_INDEX = 0;
let MAX_LENGTH = 90;

let lang = localStorage.getItem('lang');

function genData(pIndex = 0) {
  const dataBlob = {};
  for (let i = 0; i < NUM_ROWS; i++) {
    const ii = (pIndex * NUM_ROWS) + i;
    dataBlob[`${ii}`] = `row - ${ii}`;
  }
  return dataBlob;
}

// const list = [
//   { id: 0, name: 'NEWS', shortname: 'NEWS', url : 'NEWS' },
//   { id: 0, name: 'HIGHLIGHTS',  shortname: 'HIGHLIGHTS',  url : 'HIGHLIGHTS' },
//   { id: 1, name: 'English Premier League',  shortname: 'EPL',  url : 'epl' },
//   { id: 2, name: 'Spain Laliga Premier', shortname: 'LALIGA', url : 'laliga' },
//   { id: 3, name: 'Itlaian Serie A', shortname: 'SERIE A', url : 'serie-a' },
//   { id: 4, name: 'German Bundesliga',shortname: 'BUNDESLIGA',  url : 'bundesliga' },
//   { id: 5, name: 'France Ligue 1',shortname: 'LIGUE 1',  url : 'ligue-one' },
//   { id: 6, name: 'UEFA Champion League', shortname: 'CHAMPION LEAGUE', url : 'champion' },
//   { id: 7, name: 'UEFA Europa League', shortname: 'EUROPA LEAGUE', url : 'europa' },
//   { id: 8, name: 'World Cup', shortname: 'WORLDCUP', url : 'worldcup' },
//   { id: 9, name: 'Euro', shortname: 'EURO', url : 'euro' },
//   { id: 10, name: 'Others', shortname: 'OTHERS', url : 'others' }
// ];


// One item component
// selected prop will be passed
const MenuItem = ({ activeMenu, data, text, selected }) => {
  return (
    
      <div
      className="menu-item"
      >
        {data.id === 0 ? (
          <Link to={`/${data.url}`} className={(activeMenu === data.url) ? ' cont-wrap-link' : 'cont-wrap-link' } >{(window.BPdata.lang === 'mm')? data.mmshortname : data.shortname }</Link>
        ) : (
          <Link to={`/news/league/${data.id}/${data.url}`} className={(activeMenu === data.url) ? ' cont-wrap-link' : 'cont-wrap-link' } >{(window.BPdata.lang === 'mm')? data.mmshortname : data.shortname }</Link>
        )}
        {/* <Link to="/about" className={(activeMenu === text) ? 'active' : '' }  > */}
         
          {/* <span>{text}</span> */}

        {/* </Link> */}
      </div>
  );
};


// All items component
// Important! add unique key
export const Menu = (list,selected,activeMenu) => list.map(el => {
  const { shortname, url } = el;
  // console.log(selected)
  return (
    <MenuItem
      activeMenu={activeMenu}
      text={shortname}
      key={url}
      data={el}
    />
  );
});


const Arrow = ({ text, className }) => {
  return (
    <div
      className={className}
    >{text}</div>
  );
};


const ArrowLeft = Arrow({ text: '<', className: 'arrow-prev' });
const ArrowRight = Arrow({ text: '>', className: 'arrow-next' });

class LeaguePage extends Component {
  constructor(props) {
    super(props);

    var dataSource = new ListView.DataSource({
      rowHasChanged: (r1, r2) => r1 !== r2
    });

    this.data = [];
    this.state = {
      selected : 'NEWS',
      dataSource: dataSource.cloneWithRows(this.data),
      isLoading: true,
      league_id: 0,
      leagueMenu: 'NEWS',
      advertise: [],
      menu : window.BPdata.mainmenu,
      assets : { id : '0', name: 'Web App', mmshortname: 'Web App', icon : 'badge.png'}
    };

    this.pageIndex = 1;
    TOTAL_INDEX = 0;
    NUM_ROWS = 20;
    if(this.props.perPage) {
      NUM_ROWS = this.props.perPage;
      TOTAL_INDEX = this.props.pageIndexTotal;

      this.state.isLoading = true;
      
    }

    if(props.match.params.id) {
      this.state.league_id = props.match.params.id
    }

    if(props.match.params.name) {
      this.state.selected = props.match.params.name
      this.state.leagueMenu = props.match.params.name
    }
  }

  onSelect = key => {
    NUM_ROWS = 20;
    this.setState({ leagueMenu: key });
    this.setState({ selected: key });
    let temp_data = 1;
    this.state.menu.forEach(function(row) {
      //console.log(row.url)
      if(key === row.url) {
        temp_data = row.id
      }
    })

    this.state.league_id = temp_data
    // this.setState({
    //     league_id: temp_data
    //   });

    // console.log(this.state.league_id)
    // console.log(temp_data)
    // console.log(key)
    //console.log(this.state.leagueMenu)
    this.fetchData()
    
  }

  UNSAFE_componentWillMount() {
    window.BPdata.lang = localStorage.getItem("lang");
  }
  
  componentDidMount() {
    //TOTAL_INDEX = 20;
    // you can scroll to the specified position
    setTimeout(() => this.lv.scrollTo(0, 120), 800);

    // simulate initial Ajax
    setTimeout(() => {
      this.fetchData();
    }, 600);

    this.hisUrl('/news/league/'+this.state.league_id +'/'+this.state.leagueMenu)
    
  }

  hisUrl(url) {
    if(localStorage.getItem("route")) {
      let menu = JSON.parse(localStorage.getItem("route"));
      //console.log(menu.length+"-------------")
      if(menu.length === "0") {
        let data = ["/"];
        localStorage.setItem("route", JSON.stringify(data) );
      } 
       if(menu.length > 0) {
          //console.log(menu[menu.length - 1] + "-" + url )
            if(menu[menu.length - 2] === url ){
                //console.log("first");
                menu.pop();
                //window.BPdata.route = JSON.stringify(menu);
                localStorage.setItem("route", JSON.stringify(menu) );
                //localStorage.setItem("route", JSON.stringify(menu) );
            }  
            else if(menu[menu.length-1] !== url ){
                menu.push(url)
                //window.BPdata.route = JSON.stringify(menu);
                localStorage.setItem("route", JSON.stringify(menu) );
            }
            window.BPdata.preurl =  menu[menu.length-2];
        }
    }
  }

  componentDidUpdate() {
    this.hisUrl('/news/league/'+this.state.league_id +'/'+this.state.leagueMenu)
  }

  fetchData() {
        
        let url = window.BPdata.apiUrl+'/news/league/'+this.state.league_id+'?limit='+NUM_ROWS+'&start=10';
       // const url = window.BPdata.apiUrl+'/news?limit='+NUM_ROWS+'&start=10';

        axios.get(url, {
          method: "GET", // *GET, POST, PUT, DELETE, etc.
          mode: "no-cors", // no-cors, cors, *same-origin
          cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
          credentials: "same-origin", // include, *same-origin, omit
          headers: {
              "Content-Type": "application/json; charset=utf-8",
              // "Content-Type": "application/x-www-form-urlencoded",
          },
          redirect: "follow", // manual, *follow, error
          referrer: "no-referrer", // no-referrer, *client
          //body: JSON.stringify(data), // body data type must match "Content-Type" header
        })
        .then(resp => {
          // console.log(this.data.concat(resp.data));
          this.data = resp.data.news;
            
            // this.setState({
            //   dataSource: this.state.dataSource.cloneWithRows(this.data.reverse()),
            //   isLoading: false,
            //   advertise: resp.data.advertise,
            //   menu : resp.data.menu,
            //   assets  : resp.data.assets
            // });
            this.setState({
              dataSource: this.state.dataSource.cloneWithRows(this.data.reverse()),
              isLoading: false,
              advertise: resp.data.advertise,
              assets  : resp.data.assets
            });

            // this.data = { ...this.data, ...genData(++this.pageIndex) };
            // console.log(this.data)
            NUM_ROWS = Number(NUM_ROWS) + Number(LIMIT_ROWS);
            //console.log(this.pageIndex)
            lang = localStorage.getItem('lang');
            // console.log("-------------------"+lang+"---------------------")
            
        })
        .catch(function(error) {
          //console.log(error.tostring());
        });   
  }

  // If you use redux, the data maybe at props, you need use `componentWillReceiveProps`
  // componentWillReceiveProps(nextProps) {
  //   if (nextProps.dataSource !== this.props.dataSource) {
  //     this.setState({
  //       dataSource: this.state.dataSource.cloneWithRows(nextProps.dataSource),
  //     });
  //   }
  // }

  onEndReached = (event) => {
    // load new data
    // hasMore: from backend data, indicates whether it is the last page, here is false
    if (this.state.isLoading && !this.state.hasMore) {
      
      return;
    }
    //console.log('reach end', event);
    //this.setState({ isLoading: true });
    if(TOTAL_INDEX !== 0) { 
      if(this.pageIndex+1 >= TOTAL_INDEX) {
        this.setState({
          isLoading: false,
        });
      } else {
        this.setState({ isLoading: true });
      }
    } else {
      this.setState({ isLoading: false });
    }

    setTimeout(() => {
      // console.log(this.pageIndex)
      if(TOTAL_INDEX !== 0) {
  
        if(this.pageIndex+1 >= TOTAL_INDEX) {
          return ;
        }

        //this.data = { ...this.data, ...genData(++this.pageIndex) };
        
        this.fetchData();
        // this.setState({
        //   // dataSource: this.state.dataSource.cloneWithRows(this.rData),
        //   isLoading: false,
        // });
      } else {
        this.data = { ...this.data, ...genData(++this.pageIndex) };
        this.fetchData();
        // this.setState({
        //   dataSource: this.state.dataSource.cloneWithRows(this.rData),
        //   isLoading: false,
        // });
      }
      
    }, 1000);
  }

  render() {
    const { selected } = this.state;
    let leagueMenu = this.state.leagueMenu;
    // Create menu from items
    const menu = Menu(this.state.menu, selected, leagueMenu);

    const separator = (sectionID, rowID) => (
      <div
        key={`${sectionID}-${rowID}`}
        style={{
          backgroundColor: '#F5F5F9',
          height: 8,
          borderTop: '1px solid #ECECED',
          borderBottom: '1px solid #ECECED',
        }}
      />
    );
    let index = this.data.length - 1;
    const row = (rowData, sectionID, rowID) => {
      if (index < 0) {
        index = this.data.length - 1;
      }
      const obj = this.data[index--];
      return (
        <Link to={"/news/"+obj.id_news}>
        <div key={rowID} style={{ padding: '0 5px' }}>
          <div className="list-item  list-box-shadow">
              <div className="grids text-left" >
                <img src={window.BPdata.apiAssetUrl+"/images/thumbnail_"+obj.news_image} alt=""  className="list-featured-img"  alt={obj.news_title}/>
                <br/><i style={{fontSize: "13px" }}>{moment(obj.created_at, 'YYYY-MM-DD hh:mm:ss').format('DD MMM YYYY')}</i>
              </div>
              <div className="grid-80">
                <div className="row" style={{ lineHeight: 1 }}>
                    <div className="list-featured-p">
                      <b className="color-green">{obj.news_name}</b><br/>
                      <span>{`${obj.news_desc.replace(/(<([^>]+)>)/ig,"").substring(0, MAX_LENGTH)}...`}</span>
                    </div>
                    <div className="row list-footer">
                      <div className="text-left grid-90 left">
                        {/* {moment(obj.created_at, 'YYYY-MM-DD hh:mm:ss').format('DD MMMM YYYY')} */}
                      </div>
                      <div className="text-right grid-10 right">
                        
                        {/* <FacebookProvider appId="1967980890125208">
                            <i>
                              <CommentsCount href={window.BPdata.baseUrl+"/news/"+obj.id_news} />
                              <img src="/img/comment.jpg" alt="comment" /> 
                            </i> 
                        </FacebookProvider> */}
                      </div>
                      
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </Link>
      );
    };
    return (
      <div className="page">
        <div className="page-content">
        
          <div className="main-content">
              <ScrollMenu
                  data={menu}
                  arrowLeft={ArrowLeft}
                  arrowRight={ArrowRight}
                  selected={selected}
                  onSelect={this.onSelect}
                  alignCenter={false}
                  wheel={true}
                  inertiaScrolling={true}
                />
                 <ListView
                        ref={el => this.lv = el}
                        dataSource={this.state.dataSource}
                        renderHeader={() => <div className="list-header bg-white"><Link to={`/news/league/${this.state.league_id}/${this.state.leagueMenu}`} ><img src={`/img/league/${this.state.assets.icon}`} className="list-icon-circle" alt={this.state.leagueMenu} /><span>{(window.BPdata.lang === 'mm')? this.state.assets.mmshortname : this.state.assets.name } </span></Link></div>}
                        renderFooter={() => (<div style={{ padding: this.state.isLoading ? 30 : 0 , textAlign: 'center', display: this.state.isLoading ? 'block' : 'none' }}>
                          {this.state.isLoading ? <img src="/img/loading.gif" alt="loading"  /> : ''}
                        </div>)}
                        renderRow={row}
                        // renderSeparator={separator}
                        className="am-list"
                        pageSize={4}
                        useBodyScroll
                      // onScroll={() => { console.log('scroll'); }}
                        scrollRenderAheadDistance={500}
                        onEndReached={this.onEndReached}
                        onEndReachedThreshold={10}
                      />
              <div className="ant-back-footer">
                  <div className="ant-back-top">
                      <div className="ant-back-top-content">
                          <Link to={window.BPdata.preurl} className="color-green" >
                      { (JSON.parse(localStorage.getItem("route")) !== null) 
                      ? ((this.state.url !== "/" || JSON.parse(localStorage.getItem("route"))[JSON.parse(localStorage.getItem("route")).length-1] !== "/") ?  <img src="/img/back-arrow.png" alt="back" />: ""  ) : "" }
                      </Link> 
                      </div>
                  </div>
              </div>
            </div>
        </div>
      </div>
    );
  }
}

export default LeaguePage;

