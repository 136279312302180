import React from 'react';

import './Message.css';





const Message = ({ parentCallback, sendBackData, message, name }) => {

  // alert(name)
  let isSentByCurrentUser = false;

  const trimmedName = name.trim().toLowerCase();

  if(name === trimmedName) {
    isSentByCurrentUser = true;
  }

  sendBackData = (msg) => {
    parentCallback(msg);
  }
  
  // const sendMessage = (event) => {
  //   event.preventDefault();
  //   let message = "hello";
  //   if(message) {
  //     socket.emit('sendMessage', message, () => setMessage(''));
  //   }
  // }
  

  return (
    isSentByCurrentUser
      ? (
        <div className="messageContainer justifyEnd">
          <p className="sentText pr-10">{trimmedName}</p>
          <div className="messageBox backgroundBlue">
            <p className="messageText colorWhite">{JSON.parse(message).msg}</p>
            {/* <b>{action}</b> */}
          </div>
        </div>
        )
        : (
          <div className="messageContainer justifyEnd">
            <p className="sentText pr-10">{trimmedName}</p>
            <div className="messageBox backgroundBlue">
              <p className="messageText colorWhite">{JSON.parse(message).msg}</p>
              {/* <b>{action}</b> */}
            </div>
          </div>
          )
  );
}

export default Message;