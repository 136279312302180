import React from 'react';
import axios from 'axios';
//import { Modal }  from 'antd-mobile';
import {
    Link
  } from 'react-router-dom';

import StatusAlert, { StatusAlertService } from 'react-status-alert'
import 'react-status-alert/dist/status-alert.css'
//   const alert = Modal.alert;
// var moment = require('moment');

class TransactionStatus extends React.Component {
    constructor(props) {
        super(props)
        window.BPdata.footerMenu = "wallet";
        this.state = { 
                        isLoading       : true,
                        wallet : [
                            {
                                wallet_id: 1,
                                credit : 0
                            },
                            {
                                wallet_id: 2,
                                credit : 0
                            }
                        ],
                        message: "", 
                        data : {}, 
                        transfer_type : 1,
                        transfer_type2 : 2,
                        deposit : [{
                            id  : 44,
                            player_id :  802,
                            to_player_id  :  802,
                            from_total : 0,
                            percentage : 0,
                            bonus_type : 0,
                            amount : 330,
                            transfer_type : 1,   
                            description : "",
                            staff_id   : 6,
                            cusbank_bank_acc_name :  "sdfd",
                            cusbank_bank_num  :  "32232323",
                            cusbank_bank_type  : 1,
                            dagonbank_id  :  1,
                            fromgame_id : "",
                            togame_id   : "",
                            transaction_date  : "2021-09-08 10:21:00",
                            approve_status : 0,
                            provider_id : 3,
                            bank_transaction_id : "2333232"
                        } ],
                        withdraw : [{
                            id  : 44,
                            player_id :  802,
                            to_player_id  :  802,
                            from_total : 0,
                            percentage : 0,
                            bonus_type : 0,
                            amount : 330,
                            transfer_type : 1,   
                            description : "",
                            staff_id   : 6,
                            cusbank_bank_acc_name :  "sdfd",
                            cusbank_bank_num  :  "32232323",
                            cusbank_bank_type  : 1,
                            dagonbank_id  :  1,
                            fromgame_id : "",
                            togame_id   : "",
                            transaction_date  : "2021-09-08 10:21:00",
                            approve_status : 0,
                            provider_id : 3,
                            bank_transaction_id : "2333232"
                        } ],
                    step: "deposit",  
                    advertise : {advertise_image:""}, 
                    check: 0,
                    alertId: '', 
                };
        //this.validatePhone      = this.validatePhone.bind(this);
        this.changeSubMenu      = this.changeSubMenu.bind(this);

        this.showSuccessAlert = this.showSuccessAlert.bind(this);
        this.removeAlert = this.removeAlert.bind(this);
    }


    showSuccessAlert(message) {
        const alertId = StatusAlertService.showSuccess(message);
        this.setState({ alertId });
    }
    
    removeAlert() {
        StatusAlertService.removeAlert(this.state.alertId);
    }
    

    fetchData() {

        // let phone = localStorage.getItem(window.BPdata.time)  

        var self = this;
        const url = window.BPdata.apiUrl+'/cms/player/show?transfer_type=0&provider_id='+window.BPdata.provider_id;
        let data = this.state.data;
        
        
        axios({
            method: 'post',
            url: url,
            data: { token : localStorage.getItem(window.BPdata.time) },
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                "X-Trident-Token" : window.BPdata.token,
                "BP-Agent"         : window.BPdata.rand_compound,
                "BP-Time"         : localStorage.getItem(window.BPdata.time)
                // "Content-Type": "application/x-www-form-urlencoded",
          },
        })
        .then(resp => {

            // this.last10trans = response.data.last10trans;

            // console.log(resp.data.last10trans)

            self.setState({
                transaction         : resp.data.last10trans,
                deposit           : resp.data.last10trans,
                withdraw          : resp.data.last10trans,
                isLoading         : false,
                wallet : resp.data.customer_wallet,
            });

            localStorage.setItem("wallet", JSON.stringify(resp.data.customer_wallet) )
            
        })
        .catch(function(error) {
        //   console.log(error.tostring());
        });   

        // console.log("-----------------")
        // console.log(this.state.withdraw)
        //console.log(this.state.contests);
 
    }


    changeSubMenu(step) {

        this.setState({ step : step});
        // if(step === 'league') {
        //     if(this.state.league) {
        //         this.setState({ league : false});
        //     } else {
        //         this.setState({ league : true});
        //     }
        // }
        
    }

    hisUrl(url) {
        if(localStorage.getItem("route")) {
          let menu = JSON.parse(localStorage.getItem("route"));
          //console.log(menu.length+"-------------")
          if(menu.length === "0") {
            let data = ["/"];
            localStorage.setItem("route", JSON.stringify(data) );
          } 
          if(menu.length > 0) {
            //console.log(menu[menu.length - 1] + "-" + url )
              if(menu[menu.length - 2] === url ){
                  menu.pop();
                  //window.BPdata.route = JSON.stringify(menu);
                  localStorage.setItem("route", JSON.stringify(menu) );
                  //localStorage.setItem("route", JSON.stringify(menu) );
              }  
              else if(menu[menu.length-1] !== url ){
                  menu.push(url)
                  //window.BPdata.route = JSON.stringify(menu);
                  localStorage.setItem("route", JSON.stringify(menu) );
                  
              }
              //window.BPdata.preurl =  menu[menu.length-1];
              window.BPdata.preurl =  menu[menu.length-2];
              //console.log(window.BPdata.preurl)
          }
        }
      }


    componentDidMount() {

        if(localStorage.getItem(window.BPdata.time)) {
          if(localStorage.getItem(window.BPdata.time) !== "0" ) {
            //localStorage.setItem(window.BPdata.time, true);
            this.setState({ step: "deposit" }); 
          } else {
             this.props.history.push("/more/sign-up");
            }
        } else {
            this.props.history.push("/more/sign-up");
        }

        if(localStorage.getItem('contest_advertise')) {
        
            if(JSON.parse(localStorage.getItem("contest_advertise")) != null) {
              let contest_advertise = JSON.parse(localStorage.getItem("contest_advertise"));
              this.setState({advertise: contest_advertise})
            }
        } 

        this.hisUrl('/contest');

        this.fetchData();
        // this.apifetchurl();
        
        // this.fetchRamAdsData();
        clearTimeout(window.BPdata.liveurl);
        
    }

    UNSAFE_componentWillUpdate() {
        //this.hisUrl('/more');
    }


    viewHandler() {

        // console.log(this.state.step);
        
        switch(this.state.step) {
            // case "withdraw": return this.displayBlock1();
            case "deposit": return this.displayBlock2();
            default:      return "";
          }
    }

   // displayBlock1( ) {
   //      let data = this.state.withdraw
   //      //console.log(data.length)
   //      if(data.length > 0){
   //          let ui = []

   //              ui.push(<div className="league-box" key="0">
   //                      <div className="league-list-header">
   //                              <span>Withdrawal List</span>
   //                      </div>
   //                      <div className="row">
   //                          <table className="table  table-border league-table" >
   //                              <tbody>
   //                                  {this.childui(0,data)}
   //                                  </tbody>
   //                          </table>
   //                      </div>
   //              </div>)
   //          return ui
   //      }
   //  }

    displayBlock2( ) {
        let data = this.state.deposit
        //console.log(data.length)
        if(data.length  > 0){
            let ui = []

                ui.push(<div className="league-box" key="0">
                        <div className="row">
                            <table className="table  table-striped  league-table" >
                                <thead>
                                    <tr>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.childui(0,data)}
                                    </tbody>
                            </table>
                        </div>
                </div>)
            return ui
        }
    }

    childui(id,data) {
        let ui = []
            //console.log("-------------"+data)
        
            if(Array.isArray(data) === false) {
                // console.log(data)
                // console.log(this.state.contests)
            } else {

                let arlength = data.length;
                
                if(arlength  > 0){
                    let ui = []
                    // data.forEach(function(each){
                    for (let i = 0; i < arlength; i++) { 
                        let each = data[i]
                        
                        ui.push(<tr key={id+""+i}>
                                <td> {(each.approve_status > 1) ? ((each.transfer_type == 1) ? <img src="/img/_ttopup.png" height="24px" /> : <img src="/img/_twithdraw.png" height="24px" />) : <img src="/img/_tprogress.png" height="24px" /> } </td>
                                <td colSpan="3" className="text-left pointer"> 
                                    {each.transaction_date}<br />
                                    {(each.transfer_type == 1) ? window.lang.form.top_up : ( (each.transfer_type == 2) ?  window.lang.form.withdrawal  : window.lang.form.transfer  ) }
                                </td>

                                <td></td>
                                <td colSpan="2" className="text-center">
                                    {(each.transfer_type == 1) ? "+" : "-"}{ each.amount } MMK

                                </td>
                                
                            </tr>) 
                        
                }

                if(ui.length === 0) {
                        ui.push(<div className="text-center" key="0"><h2>There is no event today</h2></div>)
                }
                    return ui
                }
        }
        
        return ui;
    }


    render() {
        return (
            
            <div className="page">
                <div className="page-content text-center">
                    <div className="page-contest text-center">
                        {(this.state.message !== "") ? <div className="grid"><StatusAlert/></div>: ""}
                        
                        <div className="row text-left">
                   
                        <div className="grid-100 text-center">
                            <br />
                            Amount 
                            <br />
                            { this.state.wallet[0].credit } Ks
                            <br />
                            
                            <hr />
                            <br />
                        </div>

                        <div className="grid-100 text-center">
                            <Link to="/more/wallet-store/top-up">
                                <div className="grid-45   package-block" >
                                    <img src="/img/_topup.png" alt="Phone bill" style={{height:"40px",marginBottom:"5px"}} /><br/>
                                    <span className="color-text">{window.lang.title.top_up}</span>
                                </div>
                            </Link>
                            
                            <Link to="/more/wallet-store/withdrawal">
                                <div className="grid-45   package-block">
                                    <img src="/img/_withdraw.png" alt={window.lang.title.forum} style={{height:"40px",marginBottom:"5px"}} /><br/>
                                    <span className="color-text">{window.lang.title.withdrawal}</span>
                                </div>
                            </Link>
                            
                            
                        </div>

                            <div className="grid-100 text-center">
                            <br />
                            { (this.state.isLoading) ?  (<div className="row text-center"><img src="/img/loading.gif" alt="loading"  /> </div> ) : this.viewHandler() }
                            <br />

                                
                            </div>
                            

                        </div>

                        
                    </div>
                
                    <br />
                   
                {/*    <div className="ant-back-footer">
                        <div className="ant-back-top">
                            <div className="ant-back-top-content">
                                <Link to={window.BPdata.preurl} className="color-green" >
                            { (JSON.parse(localStorage.getItem("route")) !== null) 
                            ? ((this.state.url !== "/" || JSON.parse(localStorage.getItem("route"))[JSON.parse(localStorage.getItem("route")).length-1] !== "/") ?  <img src="/img/back-arrow.png" alt="back" />: ""  ) : "" }
                            </Link> 
                            </div>
                        </div>
                    </div>*/}
                </div>
            </div>
        );
    }
}

export default TransactionStatus;