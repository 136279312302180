import React from 'react';
import axios from 'axios';
// import MetaTags from 'react-meta-tags';
// import { Modal }  from 'antd-mobile';
import {
    Link
  } from 'react-router-dom';
// import renderHTML from 'react-render-html';
//import MarkdownRenderer from 'react-markdown-renderer';


//import {emojify} from 'react-emojione';
// var md = require('markdown-it')().use(require('markdown-it-emoji'));
// var twemoji = require('twemoji');
// let md = new MarkdownIt();
// var moment = require('moment');
// import  { Redirect } from 'react-router-dom'
// import FacebookProvider, { CommentsCount,Comments,Like } from 'react-facebook';
require('./../../BPdata.js')
 
class ImagePreviewPage extends React.Component {
    constructor(props) {
      super(props);
      window.BPdata.footerMenu = "OTHER";

      this.state = {
          data : {
            id                    : '',
            featured_image        : '',
            type_id               : '',
          },
          isLoading: true,
          loading: true,
          post_id : 1
      };
      if(props.match.params.id) {
        this.state.post_id = props.match.params.id
      }

    }

   

    hisUrl(url) {
      if(localStorage.getItem("route")) {
        let menu = JSON.parse(localStorage.getItem("route"));
        //console.log(menu.length+"-------------")
        if(menu.length === "0") {
          let data = ["/"];
          localStorage.setItem("route", JSON.stringify(data) );
        } 
         if(menu.length > 0) {
            //console.log(menu[menu.length - 1] + "-" + url )
              if(menu[menu.length - 2] === url ){
                  //console.log("first");
                  menu.pop();
                  //window.BPdata.route = JSON.stringify(menu);
                  localStorage.setItem("route", JSON.stringify(menu) );
                  //localStorage.setItem("route", JSON.stringify(menu) );
              }  
              else if(menu[menu.length-1] !== url ){
                  menu.push(url)
                  //window.BPdata.route = JSON.stringify(menu);
                  localStorage.setItem("route", JSON.stringify(menu) );
              }
              window.BPdata.preurl =  menu[menu.length-2];
          }
      }
    }

    componentDidMount() {
        this.fetchData();
        this.hisUrl('/image/'+ this.state.post_id );
    }

    UNSAFE_componentWillUpdate() {
      
      //localStorage.setItem('route', '/highlight/'+ this.state.post_id +'/'+ this.state.data.highlights_name);
      //console.log(localStorage.getItem('route'))
    }

    fetchData() {
        let time = localStorage.getItem(window.BPdata.time);
        let dataurl = window.BPdata.apiUrl+'/user/media/'+this.state.post_id+"?time="+time;

        axios.get(dataurl, {
          method: "GET", // *GET, POST, PUT, DELETE, etc.
          mode: "no-cors", // no-cors, cors, *same-origin
          cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
          //credentials: "same-origin", // include, *same-origin, omit
          crossDomain: true,
          headers: {
              "Content-Type": "application/json; charset=utf-8",
              // "Content-Type": "application/x-www-form-urlencoded",
          },
          redirect: "follow", // manual, *follow, error
          referrer: "no-referrer", // no-referrer, *client
          //body: JSON.stringify(data), // body data type must match "Content-Type" header
        })
        .then(resp => {
             this.setState({
                data: resp.data.data
            });

            
        })
        .catch(function(error) {
          // console.log(error.tostring());
        });   
        
    }

    displayBlock() {
        let ui = []
        //let data
        if(this.state.data  != null){

            ui.push(
                <div className="main-content" key="0">
                       
                        
                        <div className="row content-box">
                            <br />
                            <img src={window.BPdata.apiAssetUrl+"/images/user/"+this.state.data.featured_image} width="100%" alt={this.state.data.featured_image}/> 
                        </div>
                        
                 </div>
            )
        }

        if(ui.length === 0) {
            ui.push(<div className="main-content"><div className="text-center" key="0"><h2>Something was found.</h2></div></div>)
       }
        
        return ui
    }

  
    render() {
        
        return (
            <div className="page">
                <div className="page-content">
                    {this.displayBlock()}

                    <div className="ant-back-footer">
                        <div className="ant-back-top">
                            <div className="ant-back-top-content">
                                <Link to={window.BPdata.preurl} className="color-green" >
                            { (JSON.parse(localStorage.getItem("route")) !== null) 
                            ? ((this.state.url !== "/" || JSON.parse(localStorage.getItem("route"))[JSON.parse(localStorage.getItem("route")).length-1] !== "/") ?  <img src="/img/back-arrow.png" alt="back" />: ""  ) : "" }
                            </Link> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            );
        }
      }
      
export default ImagePreviewPage;
 