
const initState = {
  BPdata :
      {
        footerMenu : "News",
        baseUrl : 'https://shwe-sports.com/',
        apiUrl  : 'https://dashboard.shwe-sports.com/api/ver1',
        dashboardUrl : 'https://dashboard.shwe-sports.com',
        apiAssetUrl : 'https://shwe-sports.com/BPdata/assets',
        popup   : true,
        leagues : [{"id":"2882","country":"Asia","name":"World Cup - Qualification - Third Stage"},{"id":"3031","country":"England","name":"England: Fa Cup"},{"id":"3037","country":"England","name":"England: Premier League"},{"id":"3038","country":"England","name":"England: Championship"},{"id":"3368","country":"World","name":"World: Friendly International"},{"id":"3232","country":"Spain","name":"Spain: Laliga"},{"id":"3230","country":"Spain","name":"Spain: Copa Del Rey"},{"id":"2840","country":"Europe","name":"Europe: Europa League - Play Offs"},{"id":"2838","country":"Europe","name":"Europe: Champions League - Play Offs"},{"id":"3364","country":"Europe","name":"Europe: Euro - Qualification"},{"id":"3054","country":"France","name":"France: Ligue 1"},{"id":"3050","country":"France","name":"France: Ligue 1 - Relegation"},{"id":"3102","country":"Italy","name":"Itlaian Serie A"},{"id":"3062","country":"German","name":"German Bundesliga"},{"id":"2889","country":"Europe","name":"World: World Cup"},{"id":"3631","country":"World","name":"World: World Cup U20"},{"id":"3729","country":"World","name":"World: World Cup Women"},{"id":"2872","country":"southamerica","name":"South America: Copa Am\u00e9rica"}]
      },
  
}



const rootReducer = (state = initState, action) => {
  return state;
}

export default rootReducer