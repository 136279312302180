import React from 'react';
import axios from 'axios';
//import { Modal }  from 'antd-mobile';
import {
    Link
  } from 'react-router-dom';

import StatusAlert, { StatusAlertService } from 'react-status-alert'
import 'react-status-alert/dist/status-alert.css'
//   const alert = Modal.alert;
// var moment = require('moment');

class WalletStore extends React.Component {
    constructor(props) {
        super(props)
        window.BPdata.footerMenu = "wallet";
        this.state = { message: "", 
                        wallet : [
                            {
                                wallet_id: 1,
                                credit : 0
                            },
                            {
                                wallet_id: 2,
                                credit : 0
                            },
                            {
                                wallet_id: 3,
                                credit : 0
                            }
                        ],
                        transaction : [{
                            id  : 44,
                            player_id :  802,
                            to_player_id  :  802,
                            from_total : 0,
                            percentage : 0,
                            bonus_type : 0,
                            amount : 330,
                            transfer_type : 1,   
                            description : "",
                            staff_id   : 6,
                            cusbank_bank_acc_name :  "sdfd",
                            cusbank_bank_num  :  "32232323",
                            cusbank_bank_type  : 1,
                            dagonbank_id  :  1,
                            fromgame_id : "",
                            togame_id   : "",
                            transaction_date  : "2021-09-08 10:21:00",
                            approve_status : 0,
                            provider_id : 3,
                            bank_transaction_id : "2333232"
                        } ],
                        withdraw : [{
                            id  : 44,
                            player_id :  802,
                            to_player_id  :  802,
                            from_total : 0,
                            percentage : 0,
                            bonus_type : 0,
                            amount : 330,
                            transfer_type : 1,   
                            description : "",
                            staff_id   : 6,
                            cusbank_bank_acc_name :  "sdfd",
                            cusbank_bank_num  :  "32232323",
                            cusbank_bank_type  : 1,
                            dagonbank_id  :  1,
                            fromgame_id : "",
                            togame_id   : "",
                            transaction_date  : "2021-09-08 10:21:00",
                            approve_status : 0,
                            provider_id : 3,
                            bank_transaction_id : "2333232"
                        } ],
                        data : {}, step: "list",  league : false, sleague : false, advertise : {advertise_image:""}, isLoading: false, check: 0,package : { id:"1", name:"Daily Bonus",gem:"1",bactive:"0"} ,alertId: ''};
        //this.validatePhone      = this.validatePhone.bind(this);
        this.changeSubMenu      = this.changeSubMenu.bind(this);

        this.showSuccessAlert = this.showSuccessAlert.bind(this);
        this.removeAlert = this.removeAlert.bind(this);
        this.redirectLink       = this.redirectLink.bind(this);
    }

    currencyFormat(num) {
        return num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
       // return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }
    // submitDailyBonusApi() {
    //     this.sendDailyBonusApi();
    // }

    showSuccessAlert(message) {
        const alertId = StatusAlertService.showSuccess(message);
        this.setState({ alertId });
    }
    
    removeAlert() {
        StatusAlertService.removeAlert(this.state.alertId);
    }

    redirectLink(to) {
        this.props.history.push(to);
    }
    
    fetchData() {
        //localStorage.getItem(window.BPdata.time)
        const url = window.BPdata.apiUrl+'/cms/player/show?transfer_type=0&provider_id='+window.BPdata.provider_id;
        //const url = window.BPdata.apiUrl+'/user/token/'+localStorage.getItem(window.BPdata.time);
     
        ///api/customer?transfer_type=2
    
        axios({
          method: 'post',
          url: url,
          data: { time : window.BPdata.rand_compound+"kiuwernxyzxfyfysdsdfdsfydf373sdfdsf" },
          headers: { 
                'Content-Type': 'application/json;charset=UTF-8',
                "X-Trident-Token" : window.BPdata.token,
                "BP-Agent"         : window.BPdata.rand_compound,
                "BP-Time"         : localStorage.getItem(window.BPdata.time)
            },
        })
        .then(resp => {

            if(resp.data) {
                this.setState({
                  id : resp.data.data.id,
                  username : resp.data.data.first_name,
                  phone : resp.data.data.phone,
                  created_at : resp.data.data.created_at,
                  transaction : resp.data.last10trans,
                  wallet : resp.data.customer_wallet,
                });

                localStorage.setItem("wallet", JSON.stringify(resp.data.customer_wallet) )
            }
            
            
          })
          .catch(function(error) {
            // console.log(error);
          });   
    }


    changeSubMenu(step) {
        if(step === 'league') {
            if(this.state.league) {
                this.setState({ league : false});
            } else {
                this.setState({ league : true});
            }
        }
        
    }

    hisUrl(url) {
        if(localStorage.getItem("route")) {
          let menu = JSON.parse(localStorage.getItem("route"));
          //console.log(menu.length+"-------------")
          if(menu.length === "0") {
            let data = ["/"];
            localStorage.setItem("route", JSON.stringify(data) );
          } 
          if(menu.length > 0) {
            //console.log(menu[menu.length - 1] + "-" + url )
              if(menu[menu.length - 2] === url ){
                  menu.pop();
                  //window.BPdata.route = JSON.stringify(menu);
                  localStorage.setItem("route", JSON.stringify(menu) );
                  //localStorage.setItem("route", JSON.stringify(menu) );
              }  
              else if(menu[menu.length-1] !== url ){
                  menu.push(url)
                  //window.BPdata.route = JSON.stringify(menu);
                  localStorage.setItem("route", JSON.stringify(menu) );
                  
              }
              //window.BPdata.preurl =  menu[menu.length-1];
              window.BPdata.preurl =  menu[menu.length-2];
              //console.log(window.BPdata.preurl)
          }
        }
      }


    componentDidMount() {

        if(localStorage.getItem('wallet')) {
        
          if(JSON.parse(localStorage.getItem("wallet")) != null) {
            let wallet = JSON.parse(localStorage.getItem("wallet"));
            this.setState({wallet: wallet})
          }
        } 

        if(localStorage.getItem(window.BPdata.time)) {
          if(localStorage.getItem(window.BPdata.time) !== "0" ) {
            //localStorage.setItem(window.BPdata.time, true);
            this.setState({ step: "list" }); 
          } else {
             this.props.history.push("/more/login");
            }
        } else {
            this.props.history.push("/more/login");
        }

        if(localStorage.getItem('contest_advertise')) {
        
            if(JSON.parse(localStorage.getItem("contest_advertise")) != null) {
              let contest_advertise = JSON.parse(localStorage.getItem("contest_advertise"));
              this.setState({advertise: contest_advertise})
            }
        } 

        this.hisUrl('/contest');

        this.fetchData();
        // this.apifetchurl();
        
        // this.fetchRamAdsData();
        clearTimeout(window.BPdata.liveurl);
        
    }

    UNSAFE_componentWillUpdate() {
        //this.hisUrl('/more');
    }


    viewHandler() {

        
        switch(this.state.step) {
            // case "line-up":   return "";
            //case "register":   return this.registerBlock();
            //case "profile": return this.profileBlock();
            // case "home": return this.homeBlock();
            case "list": return this.listBlock();
            default:      return "";
          }
    }

    listBlock() {
        return  <div className="row text-left">
                   
                    {(this.state.wallet[0]) ?
                    <div className="row pb-10">
                        <div className="adm-card row mt-10">
                              <div className="adm-card-body grid-100">
                                  <div className="row">
                                      <div className="grid-10 left text-center">
                                      </div>
                                      <div className="grid-40 left text-left pl-20 ">
                                          { window.lang.title.main_wallet }
                                      </div>
                                      <div className="grid-40 left text-right">
                                          { (this.state.wallet) ? this.currencyFormat(this.state.wallet[0].credit) : 0 }  Ks 
                                          
                                      </div>
                                      <div className="grid-10 left text-center">
                                        <Link to="/more/wallet-store">
                                          &nbsp; <img src="/img/plus.png" height="18px" />
                                        </Link>
                                      </div>
                                      <br />
                                      <hr />
                                  </div>
                                  <div className="row">
                                      <div className="grid-10 left text-center">
                                      </div>
                                      <div className="grid-40 left text-left pl-20">
                                          { window.lang.title.game_wallet }
                                      </div>
                                      <div className="grid-40 left text-right">
                                          { (this.state.wallet) ? this.currencyFormat(this.state.wallet[1].credit) : 0 }  Ks 
                                          
                                      </div>
                                      <div className="grid-10 left text-center">
                                        <Link to="/more/wallet-store">
                                          &nbsp; <img src="/img/plus.png" height="18px" />
                                        </Link>
                                      </div>
                                      <br />
                                      <hr />
                                  </div>
                                  <div className="row">
                                      <div className="grid-10 left text-center">
                                      </div>
                                      <div className="grid-40 left text-left pl-20">
                                          { window.lang.title.commission_wallet }
                                      </div>
                                      <div className="grid-40 left text-right">
                                          { (this.state.wallet) ? this.currencyFormat(this.state.wallet[2].credit) : 0 }  Ks 
                                          
                                      </div>
                                      <div className="grid-10 left text-center">
                                        <Link to="/more/wallet-store">
                                          &nbsp; <img src="/img/plus.png" height="18px" />
                                        </Link>
                                      </div>

                                  </div>
                              </div>
                      </div>
                    </div>
                    : "" }
                    <br />
                    <div className="grid-100 text-center mt-10">
                        <Link to="/more/wallet-store/transfer">
                            <div className="grid-30   package-block" >
                                <img src="/img/_transfer.png" alt="Transfer" style={{height:"40px",marginBottom:"5px"}} /><br/>
                                <span className="color-text">{window.lang.title.transfer}</span>
                            </div>
                        </Link>
                        <Link to="/more/wallet-store/top-up">
                            <div className="grid-30   package-block" >
                                <img src="/img/_topup.png" alt="Top up" style={{height:"40px",marginBottom:"5px"}} /><br/>
                                <span className="color-text">{window.lang.title.top_up}</span>
                            </div>
                        </Link>
                        
                        <Link to="/more/wallet-store/withdrawal">
                            <div className="grid-30   package-block">
                                <img src="/img/_withdraw.png" alt="Withdrawal" style={{height:"40px",marginBottom:"5px"}} /><br/>
                                <span className="color-text">{window.lang.title.withdrawal}</span>
                            </div>
                        </Link>
                        
                        
                    </div>

                    <div className="grid-100 text-center">
                        <br />
                         <br />
                        <Link to="/more/wallet-store/transaction-status" className="btn btn-success grid-80">
                            {window.lang.title.history}
                        </Link>
                        <br />
                        <br />
                    </div>
                    
                    <div className="grid-100 text-center">
                        
                        <a href="https://www.youtube.com/watch?v=GI1LF_jPyZ0" ><span className="color-red">* ငွေဖြည့်နည်း ကြည့်ရန် နှိပ်ပါ။</span> </a> 
                        <br />
                        <br />
                        <a href="https://www.youtube.com/watch?v=GI1LF_jPyZ0" >&nbsp;<span className="color-red">* ငွေထုတ်နည်း ကြည့်ရန် နှိပ်ပါ။</span> </a> <br />

                    </div>

                </div>
    }
    displayBlock2( ) {
        let data = this.state.transaction
        //console.log(data.length)
        if(data.length  > 0){
            let ui = []

                ui.push(<div className="league-box" key="0">
                        <div className="row">
                            <table className="table table-striped league-table" >
                                <tbody>
                                    {this.childui(0,data)}
                                    </tbody>
                            </table>
                        </div>
                </div>)
            return ui
        }
    }

    childui(id,data) {
        let ui = []
            //console.log("-------------"+data)
        
            if(Array.isArray(data) === false) {
                // console.log(data)
                // console.log(this.state.contests)
            } else {

                let arlength = data.length;
                
                if(arlength  > 0){
                    let ui = []
                    // data.forEach(function(each){
                    for (let i = 0; i < arlength; i++) { 
                        let each = data[i]
                        
                        //check handicap and change setting

                        ui.push(<tr key={id+""+i}>
                                <td> {(each.approve_status > 1) ? ((each.transfer_type == 1) ? <img src="/img/_ttopup.png" height="24px" /> : <img src="/img/_twithdraw.png" height="24px" />) : <img src="/img/_tprogress.png" height="24px" /> } </td>
                                <td colSpan="3" className="text-left pointer"> 
                                    {each.transaction_date}<br />
                                    {(each.transfer_type == 1) ? "Deposit" : "Widthdraw"}
                                </td>
                                <td></td>
                                <td colSpan="2" className="text-center">
                                    {(each.transfer_type == 1) ? "+" : "-"}{ each.amount } MMK

                                </td>
                                
                            </tr>) 
                        
                }

                if(ui.length === 0) {
                        ui.push(<div className="text-center" key="0"><h2>There is no event today</h2></div>)
                }
                    return ui
                }
        }
        
        return ui;
    }

    render() {
        return (
            
            <div className="page">
                <div className="page-content text-center">
                    <div className="main-content text-center">
                        {(this.state.message !== "") ? <div className="grid"><StatusAlert/></div>: ""}
                        
                        { this.viewHandler() }

                        
                    </div>
                
                    <br />
                   
                {/*  <div className="ant-back-footer">
                        <div className="ant-back-top">
                            <div className="ant-back-top-content">
                                <Link to={window.BPdata.preurl} className="color-green" >
                            { (JSON.parse(localStorage.getItem("route")) !== null) 
                            ? ((this.state.url !== "/" || JSON.parse(localStorage.getItem("route"))[JSON.parse(localStorage.getItem("route")).length-1] !== "/") ?  <img src="/img/back-arrow.png" alt="back" />: ""  ) : "" }
                            </Link> 
                            </div>
                        </div>
                    </div>
                */}
                </div>
            </div>
        );
    }
}

export default WalletStore;