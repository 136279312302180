import React from 'react';
import axios from 'axios';
import {
    Link
  } from 'react-router-dom';

import swal from 'sweetalert';

import PopUp from "./PopUp";
import StatusAlert, { StatusAlertService } from 'react-status-alert'
import 'react-status-alert/dist/status-alert.css'
var moment = require('moment');

var isEmpty = function(data) {
    if(typeof(data) === 'object'){
        if(JSON.stringify(data) === '{}' || JSON.stringify(data) === '[]'){
            return true;
        }else if(!data){
            return true;
        }
        return false;
    }else if(typeof(data) === 'string'){
        if(!data.trim()){
            return true;
        }
        return false;
    }else if(typeof(data) === 'undefined'){
        return true;
    }else{
        return false;
    }
}


class TwoGameSetPage extends React.Component {
    constructor(props) {
        super(props)
        window.BPdata.footerMenu = "home";
        
        this.state = { 
            bullet : 0,
            rcondi : 1,
            today_event : 0,
            fast : 0,
            toggle : false,
            seen: false,
            step: "form", 
            message : "", 
            alertId: '',
            wallet : [
                {
                    wallet_id: 1,
                    credit : 0
                },
                {
                    wallet_id: 2,
                    credit : 0
                },
                {
                    wallet_id: 3,
                    credit : 0
                }
            ],
            event : {},
            form : { mycart: [], amount : "", commission : 0, total : 0 , rmycart: [] }, 
            settrade: {
                "thaidate":"29-11-21 13:11",
                "set":"--",
                "value":"--",
                "created_at":"2021-11-29 12:41:10",
                "number":"--"
            },
            eventone  : [],
            eventtwo   : [],
            setting : [],
            twogame : [ {id: 0 ,title : '', max_amount : 0, min_amount: 0, amount :0, gameplay_type: 0, game_type: 1, complete : 0}], 
            editform : false,
            isEvent : false,
         };

        this.togglesPop     = this.togglesPop.bind(this);
        this.fastAction     = this.fastAction.bind(this);
        this.betAction      = this.betAction.bind(this);
        this.submitAction   = this.submitAction.bind(this);
        this.backAction     = this.backAction.bind(this);
        this.endSessionAction = this.endSessionAction.bind(this);

        this.showSuccessAlert   = this.showSuccessAlert.bind(this);
        this.showErrorAlert     = this.showErrorAlert.bind(this);
        this.removeAlert        = this.removeAlert.bind(this);
        this.setReverse         = this.setReverse.bind(this);

        this.btnfastType           = this.btnfastType.bind(this);


        this.previewIndviAmountEdit       = this.previewIndviAmountEdit.bind(this);
        this.previewIndviAmountDelete   = this.previewIndviAmountDelete.bind(this);
        this.previewIndviAmountSave     = this.previewIndviAmountSave.bind(this);

        this.inputEditChangeHandler = this.inputEditChangeHandler.bind(this);

        this.btnfastTimeSelect = this.btnfastTimeSelect.bind(this);

    }

    percentage(partialValue, totalValue) {
       return ((100 * partialValue) / totalValue);
    } 

    currencyFormat(num) {
        return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
       // return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }
    
    btnfastTimeSelect(e, id) {
        // this.setState()
        // id
        this.setState({ step : "form", today_event : id });

        // this.fetchData(window.BPdata.apiUrlCMS+'/2d/formdata?token='+localStorage.getItem(window.BPdata.time)+"&type="+id);

        this.fetchData(window.BPdata.apiUrlCMS+'/2d/formdata?type='+id);
    }

    previewIndviAmountEdit(e,  id) {
        // alert(e)
        // alert(id)
        let mycart = [...this.state.form.mycart];

        Object.keys(mycart).forEach(key => mycart[key] === undefined && delete mycart[key]);

        Object.keys(mycart).forEach(key => mycart[key] == null && delete mycart[key]);

        // console.log(mycart);

        mycart[id]["edit_amount"] = 1;  

        

        this.setState(prevState => ({
            form : {
                ...prevState.form,
                mycart: mycart
            },
        }))

        //this.setState({editform : true});
    }

    previewIndviAmountSave(e, id) {
        let mycart = [...this.state.form.mycart];

        Object.keys(mycart).forEach(key => mycart[key] === undefined && delete mycart[key]);

        Object.keys(mycart).forEach(key => mycart[key] == null && delete mycart[key]);

        // console.log(mycart);

        mycart[id]["edit_amount"] = 0;  

  

        let total = 0;
        let commission = 0;
        let commission_percent = this.state.setting[3].option_value;

        mycart.forEach(function(each,key){
            
            let amount = Number(each.amount); 

            // let commission_amount = Math.round((commission_percent/ 100) * amount);
            let commission_amount = ((commission_percent/ 100) * amount);

            // mycart[each.id] = { "id" : each.id, "title" : each.title, "complete" : each.complete , "amount" : number, "commission" : commission_amount, "product_type" : 1 , "edit_amount" : 0 , "max_amount" : each.max_amount , "total_amount" : each.total_amount };     

            commission += commission_amount ;
            total += amount;

            // console.log(commission)
            // console.log(total)

        })

        Object.keys(mycart).forEach(key => mycart[key] === undefined && delete mycart[key]);

        Object.keys(mycart).forEach(key => mycart[key] == null && delete mycart[key]);
            

        this.setState(prevState => ({
            form : {
                ...prevState.form,
                mycart: mycart,
                commission : commission,
                total : total
            },
        }))

        // this.setState(prevState => ({
        //     form : {
        //         ...prevState.form,
        //         mycart: mycart,
        //         commission : commission,
        //         total : total
        //     }
        // }));


    }

    previewIndviAmountDelete(e, id) {

        let mycart = [...this.state.form.mycart];

        Object.keys(mycart).forEach(key => mycart[key] === undefined && delete mycart[key]);

        Object.keys(mycart).forEach(key => mycart[key] == null && delete mycart[key]);

        delete mycart[id];


        let amount = this.state.form.amount;
        let number  = Number(amount.replace(/^0+/, '')); 

        let total = 0;
        let commission = 0;
        let commission_percent = this.state.setting[3].option_value;

        // let mycart = [...this.state.form.mycart];

        mycart.forEach(function(each,key){
                
                let commission_amount = ((commission_percent/ 100) * number);

                

                mycart[each.id] = { "id" : each.id, "title" : each.title, "complete" : each.complete , "amount" : number, "commission" : commission_amount, "product_type" : 1 , "edit_amount" : 0 , "max_amount" : each.max_amount , "total_amount" : each.total_amount };     

                commission += commission_amount ;
                total += number;

            })

            Object.keys(mycart).forEach(key => mycart[key] === undefined && delete mycart[key]);

            Object.keys(mycart).forEach(key => mycart[key] == null && delete mycart[key]);
            

        this.setState({
            form : {
                mycart: mycart,
                rmycart: this.state.form.rmycart,
                amount : this.state.form.amount,
                commission : commission,
                total : total
            }
        });

        // this.setState(prevState => ({
        //     form : {
        //         ...prevState.form,
        //         mycart: mycart
        //     },
        // }))

        // alert("ok");
    }

    inputEditChangeHandler(e, id, amount, max_amount, total_amount ) {

        // console.log(e)
        // console.log(id)
        // console.log(amount)
        // let form = {...this.state.form};
        // form[e.target.name] = e.target.value;
        // this.setState({
        //     form
        // });

        let value = e.target.value;
        let min_amount = this.state.setting[0].option_value;

        if(isNaN(e.target.value)) {
            this.showErrorAlert("Set bet minimum amount must be "+ min_amount +" Ks ", 'single');
            return ;
        }

        // if(Number(value) < min_amount) {
        //     this.showErrorAlert("Minimum amount should have "+ (Number(min_amount)) +" Ks ", 'single');
        //     return ;
        // }        

        let temp_max_amount = (Number(value) + Number(total_amount));

        // console.log(temp_max_amount)
        // console.log(max_amount < temp_max_amount)
        // console.log(max_amount-total_amount)

        if(Number(max_amount) < temp_max_amount) {
            this.showErrorAlert("This number set amount almost full. Maximum amount should have "+ (Number(max_amount)-Number(total_amount)) +" Ks ", 'single');
            return ;
        }

        // alert(value)

        let mycart = [...this.state.form.mycart];

        Object.keys(mycart).forEach(key => mycart[key] === undefined && delete mycart[key]);

        Object.keys(mycart).forEach(key => mycart[key] == null && delete mycart[key]);

        // console.log(mycart[id]);

        let total = 0;
        let commission = 0;
        let commission_percent = this.state.setting[3].option_value;
        // let commission_amount = Math.round((commission_percent/ 100) * value);
        let commission_amount = ((commission_percent/ 100) * value);

        mycart[id]["amount"] = value;  
        mycart[id]["commission"] = commission_amount;  
        
        this.setState(prevState => ({
            form : {
                ...prevState.form,
                mycart: mycart
            },
        }))

    }

    btnfastType(e,type) {
        // alert(type)
        // $data = [];
        // for (let i = 0; i < cars.length; i++) {
        //   text += cars[i] + "<br>";
        // } 
        let filter;
        let filter2;

       

        let twogame = [...this.state.twogame];
        // twogame[key]["complete"] = e.target.checked;

        let pre_mycart = this.state.form.mycart;
        let mycart = [...this.state.form.mycart];

        let total = 0;
        let commission = 0;
        let rcondi = 1;

        twogame.forEach(function(each,key){
            
            let title = each.title;

            

             if(type == 1) {

                filter = 49;
                filter2 = 100;

                if(key > filter && key < filter2) {
                    twogame[key]["complete"] = 1;  
                    mycart[each.id] = { "id" : each.id, "title" : each.title, "complete" : each.complete , "amount" : 0, "commission" : 0, "product_type" : 1, "edit_amount" : 0, "max_amount" : each.max_amount , "total_amount" : each.total_amount };   
                }

                rcondi = 0;

            }  else if (type == 2) {

                filter = 50;
                if(key < filter) {
                    twogame[key]["complete"] = 1;  
                    mycart[each.id] = { "id" : each.id, "title" : each.title, "complete" : each.complete , "amount" : 0, "commission" : 0, "product_type" : 1, "edit_amount" : 0, "max_amount" : each.max_amount , "total_amount" : each.total_amount };   
                }

                rcondi = 0;

            }  else if (type == 3) {

                
                let lastnum = [ "1", "3", "5", "7", "9"];

                // console.log(title[1])

                // console.log(lastnum.indexOf(title[1]))

                // console.log(lastnum.indexOf(title[1]) )

                if(lastnum.indexOf(title[1]) != "-1") {

                    twogame[key]["complete"] = 1;  
                    mycart[each.id] = { "id" : each.id, "title" : each.title, "complete" : each.complete , "amount" : 0, "commission" : 0, "product_type" : 1 , "edit_amount" : 0, "max_amount" : each.max_amount , "total_amount" : each.total_amount};   
                 }

                 rcondi = 0;

            }  else if (type == 4) {

                
                let lastnum = [ "0", "2", "4", "6", "8"];

                if(lastnum.indexOf(title[1]) != "-1") {

                    twogame[key]["complete"] = 1;  
                    mycart[each.id] = { "id" : each.id, "title" : each.title, "complete" : each.complete , "amount" : 0, "commission" : 0, "product_type" : 1 , "edit_amount" : 0 , "max_amount" : each.max_amount , "total_amount" : each.total_amount };   
                 }

                rcondi = 0;

            } else if (type == 5) {

                if(title[1]+title[0] == title) {

                    twogame[key]["complete"] = 1;  
                    mycart[each.id] = { "id" : each.id, "title" : each.title, "complete" : each.complete , "amount" : 0, "commission" : 0, "product_type" : 1 , "edit_amount" : 0, "max_amount" : each.max_amount , "total_amount" : each.total_amount};   

                }

                rcondi = 0;

            } 
            else if ( type >= 6 && type <= 15 ) {

                //Round

                let lastnum = [ "0" ];

                if(type == 6) {
                    lastnum = [ "0" ];
                } else if (type == 7) {
                    lastnum = [ "1" ];
                } else if (type == 8) {
                    lastnum = [ "2" ];
                } else if (type == 9) {
                    lastnum = [ "3" ];
                } else if (type == 10) {
                    lastnum = [ "4" ];
                } else if (type == 11) {
                    lastnum = [ "5" ];
                } else if (type == 12) {
                    lastnum = [ "6" ];
                } else if (type == 13) {
                    lastnum = [ "7" ];
                } else if (type == 14) {
                    lastnum = [ "8" ];
                } else if (type == 15) {
                    lastnum = [ "9" ];
                } 
                

                if(lastnum.indexOf(title[0]) != "-1" || lastnum.indexOf(title[1]) != "-1") {

                    twogame[key]["complete"] = 1;  
                    mycart[each.id] = { "id" : each.id, "title" : each.title, "complete" : each.complete , "amount" : 0, "commission" : 0, "product_type" : 1 , "edit_amount" : 0, "max_amount" : each.max_amount , "total_amount" : each.total_amount};   
                 }


            } else if ( type >= 16 && type <= 25 ) {

                //Head

                let lastnum = [ "0" ];

                if(type == 16) {
                    lastnum = [ "0" ];
                } else if (type == 17) {
                    lastnum = [ "1" ];
                } else if (type == 18) {
                    lastnum = [ "2" ];
                } else if (type == 19) {
                    lastnum = [ "3" ];
                } else if (type == 20) {
                    lastnum = [ "4" ];
                } else if (type == 21) {
                    lastnum = [ "5" ];
                } else if (type == 22) {
                    lastnum = [ "6" ];
                } else if (type == 23) {
                    lastnum = [ "7" ];
                } else if (type == 24) {
                    lastnum = [ "8" ];
                } else if (type == 25) {
                    lastnum = [ "9" ];
                } 
                

                if(lastnum.indexOf(title[0]) != "-1" ) {

                    twogame[key]["complete"] = 1;  
                    mycart[each.id] = { "id" : each.id, "title" : each.title, "complete" : each.complete , "amount" : 0, "commission" : 0, "product_type" : 1 , "edit_amount" : 0, "max_amount" : each.max_amount , "total_amount" : each.total_amount};   
                 }

            } else if ( type >= 26 && type <= 35 ) {

                //Tail

                let lastnum = [ "0" ];

                if(type == 26) {
                    lastnum = [ "0" ];
                } else if (type == 27) {
                    lastnum = [ "1" ];
                } else if (type == 28) {
                    lastnum = [ "2" ];
                } else if (type == 29) {
                    lastnum = [ "3" ];
                } else if (type == 30) {
                    lastnum = [ "4" ];
                } else if (type == 31) {
                    lastnum = [ "5" ];
                } else if (type == 32) {
                    lastnum = [ "6" ];
                } else if (type == 33) {
                    lastnum = [ "7" ];
                } else if (type == 34) {
                    lastnum = [ "8" ];
                } else if (type == 35) {
                    lastnum = [ "9" ];
                } 
                

                if(lastnum.indexOf(title[1]) != "-1" ) {

                    twogame[key]["complete"] = 1;  
                    mycart[each.id] = { "id" : each.id, "title" : each.title, "complete" : each.complete , "amount" : 0, "commission" : 0, "product_type" : 1 , "edit_amount" : 0, "max_amount" : each.max_amount , "total_amount" : each.total_amount};   
                 }

            } else if ( type >= 36 && type <= 39 ) {

                //Consetellation Power

                let lastnum = [ "0" ];

                if(type == 36) {
                    lastnum = [ "07", "18", "24", "35", "69" ];
                } else if (type == 37) {
                    lastnum = [ "70", "81", "42", "53", "96" ];
                } else if (type == 38) {
                    lastnum = [ "05", "16", "27", "38", "49" ];
                } else if (type == 39) {
                    lastnum = [ "50", "61", "72", "83", "94" ];
                } 
                

                if(lastnum.indexOf(title) != "-1" ) {

                    twogame[key]["complete"] = 1;  
                    mycart[each.id] = { "id" : each.id, "title" : each.title, "complete" : each.complete , "amount" : 0, "commission" : 0, "product_type" : 1 , "edit_amount" : 0, "max_amount" : each.max_amount , "total_amount" : each.total_amount};   
                 }

            }
            else if ( type > 39 ) {

                
                let lastnum = [ "0", "1" ];

                if(type == 40) {
                    lastnum = [ "0", "1" ];
                } else if (type == 41) {
                    lastnum = [ "2", "3" ];
                } else if (type == 42) {
                    lastnum = [ "4", "5" ];
                } else if (type == 43) {
                    lastnum = [ "6", "7" ];
                } else if (type == 44) {
                    lastnum = [ "8", "9" ];
                }
                

                if(lastnum.indexOf(title[0]) != "-1") {

                    twogame[key]["complete"] = 1;  
                    mycart[each.id] = { "id" : each.id, "title" : each.title, "complete" : each.complete , "amount" : 0, "commission" : 0, "product_type" : 1 , "edit_amount" : 0, "max_amount" : each.max_amount , "total_amount" : each.total_amount};   
                 }

            }
            

            commission += commission ;
            total += 0;

            

        })

        Object.keys(mycart).forEach(key => mycart[key] === undefined && delete mycart[key]);

        Object.keys(mycart).forEach(key => mycart[key] == null && delete mycart[key]);


        this.setState(prevState => ({
            fast : type,
            step : 'form',
            rcondi : rcondi,
            form : {
                ...prevState.form,
                mycart: mycart,
                commission : commission,
                total : total
                // rmycart: this.state.form.rmycart,
                // amount : this.state.form.amount,
                // commission : this.state.form.commission,
                // total : this.state.form.total,

            },
            twogame : twogame,
            today_event : this.state.today_event

              // wallet : resp.data.customer_wallet,
              // permit : {
              //   ...prevState.permit,
              //   customer_banks : resp.data.data.customer_banks,
              //   specfic_bank_accounts : resp.data.bank_accounts,
              //   bank_accounts : resp.data.bank_accounts,
              // }
        }))


    }
    
    showSuccessAlert(message, type) {

        let output = "";
        if(type == "multi") {

            for (var i = 0; i < message.length; i++) {
                output += message[i];
            } 

        } else {
            output = message;
        }
        
        // console.log(output)

        let alertId = StatusAlertService.showSuccess(output);
        // console.log(alertId)
        this.setState({ alertId });
    }
    
    removeAlert(message, type) {

        let output = "";
        if(type == "multi") {

            for (var i = 0; i < message.length; i++) {
                output += message[i];
            } 

        } else {
            output = message;
        }
        
        // console.log(output)

        let alertId = StatusAlertService.showError(output);
        // console.log(alertId)
        this.setState({ alertId });

        // StatusAlertService.removeAlert(this.state.alertId);
    }

    showErrorAlert(message ,type ) {
        // let message = "message";
        // let alertId = StatusAlertService.showError(message);
        // // console.log(alertId)
        // this.setState({ alertId });

        let output = "";
        if(type == "multi") {

            for (var i = 0; i < message.length; i++) {
                output += message[i];
            } 

        } else {
            output = message;
        }
        
        // console.log(output)

        let alertId = StatusAlertService.showError(output);
        // console.log(alertId)
        this.setState({ alertId });

    }

    togglesPop() {
        this.setState({
            seen: !this.state.seen
        });

    }

    hisUrl(url) {
        if(localStorage.getItem("route")) {
          let menu = JSON.parse(localStorage.getItem("route"));
          //console.log(menu.length+"-------------")
          if(menu.length === "0") {
            let data = ["/"];
            localStorage.setItem("route", JSON.stringify(data) );
          } else {
            if(menu[menu.length - 1] !== url ){
              menu.push(url)
              localStorage.setItem("route", JSON.stringify(menu) );
            }
          }
        
        }

    }

    componentDidMount() {

        if(localStorage.getItem('wallet')) {

          if(JSON.parse(localStorage.getItem("wallet")) != null) {
            let wallet = JSON.parse(localStorage.getItem("wallet"));
            this.setState({wallet: wallet})
          }
        } 

        if(localStorage.getItem(window.BPdata.time)) {
          if(localStorage.getItem(window.BPdata.time) !== "0" ) {
            //localStorage.setItem(window.BPdata.time, true);

            this.setState({ step: "selecttime" });
            //this.setState({ step: "form" }); 
            
          } else {
             this.props.history.push("/more/login");
            }
        } else {
            this.props.history.push("/more/login");
        }


        this.fetchData(window.BPdata.apiUrlCMS+'/2d/formdata');

        this.fetchTwodData(window.BPdata.apiUrlCMS+'/2d');
        // http://dep.thahtaylay.com/api/v1/cms/2d
    }

    fetchTwodData(url) {
        
        let dataurl = url;

        window.BPdata.liveurl =  setTimeout(() => this.fetchTwodData(url), 10000);

        axios.get(dataurl, {
          method: "GET", // *GET, POST, PUT, DELETE, etc.
          mode: "no-cors", // no-cors, cors, *same-origin
          cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
          //credentials: "same-origin", // include, *same-origin, omit
          crossDomain: true,
          headers: {
              "Content-Type": "application/json; charset=utf-8",
              "X-Trident-Token" : window.BPdata.token,
              "BP-Agent"         : window.BPdata.rand_compound,
                "BP-Time"         : localStorage.getItem(window.BPdata.time)
              // "Content-Type": "application/x-www-form-urlencoded",
          },
          redirect: "follow", // manual, *follow, error
          referrer: "no-referrer", // no-referrer, *client
          //body: JSON.stringify(data), // body data type must match "Content-Type" header
        })
        .then(resp => {

            this.setState(prevState => ({
                settrade : {
                    thaidate: resp.data.data.thaidate,
                    set: resp.data.data.set,
                    value: resp.data.data.value,
                    created_at: resp.data.data.created_at,
                    number: resp.data.data.number
                },
                eventone  : resp.data.eventone,
                eventtwo   : resp.data.eventtwo,
            }))


        })
        .catch(function(error) {
          //console.log(error.tostring());
        });   

        
    }

    fetchData(url) {
        
        let dataurl = url;

        
        axios({
            method: 'get',
            mode: "no-cors", // no-cors, cors, *same-origin
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            //credentials: "same-origin", // include, *same-origin, omit
            crossDomain: true,
            url: dataurl,
            headers: {
              "Content-Type": "application/json; charset=utf-8",
              "X-Trident-Token" : window.BPdata.token,
              "BP-Agent"         : window.BPdata.rand_compound,
              "BP-Time"             : localStorage.getItem(window.BPdata.time)
              // "Content-Type": "application/x-www-form-urlencoded",
          },
        })
        .then(resp => {
            // console.log(resp.data)
            if(resp.data.data.data) {

                this.setState({
                    twogame: resp.data.data.data,
                    event : resp.data.event,
                    setting : resp.data.setting,
                    isLoading: false,
                    isEvent : resp.data.is_event,
                });

            } else {

                this.setState({
                    twogame: resp.data.data.data,
                    event : resp.data.event,
                    setting : resp.data.setting,
                    isLoading: false,
                    isEvent : false,
                });

            }
            

            if(resp.data.wallet[0]) {

                this.setState({
                    wallet : resp.data.wallet,
                });

                localStorage.setItem("wallet", JSON.stringify(resp.data.wallet) )
            }
            

        })
        .catch(function(error) {
          //console.log(error.tostring());
        });   


        
    }

    formChangeHandler(e) {
        let form = {...this.state.form};
        form[e.target.name] = e.target.value;
        this.setState({
            form
        });
    }

    

    inputChangeHandler(e, key, dbid, title, max_amount, total_amount)  {
        // alert(dbid);
        let mycarts = [];

        let pre_twogame = this.state.twogame;

        let twogame = [...this.state.twogame];
        twogame[key]["complete"] = e.target.checked;

        // console.log(twogame)
        
        let mycart      = [...this.state.form.mycart]; 

        let rmycart     = [...this.state.form.rmycart];

        if(e.target.checked == false){
            // console.log(e.target.checked)
            delete mycart[dbid]
        } else {

            //toggle R
            if(this.state.toggle == true) {
                // reverse twogame data complete 
                // insert reverse mycart 
                // insert mycart
                // twogame[key]["complete"] = e.target.checked;
                // mycart[dbid] = { "id" : dbid, "title" : title, "complete" : e.target.checked , "amount" : 0, "commission" : 0, "product_type" : 1 };

                let index = pre_twogame.findIndex(obj => obj.title==(title[1]+title[0]));
                // let fullindex = twogame.find(obj => obj.title==(each.title[1]+each.title[0]));

                // item = twogame.find(item=>item.title==(each.title[1]+each.title[0]));
                let chosenum = pre_twogame[index];
                

                rmycart[chosenum["id"] ] = { "id" : chosenum["id"] , "title" :chosenum["title"] , "complete" : chosenum["complete"] ,   "product_type" : chosenum["product_type"] };    

                // selftmycart not working
                mycart[chosenum["id"]] = { "id" : chosenum["id"] , "title" :chosenum["title"] , "complete" : chosenum["complete"] ,   "product_type" : chosenum["product_type"] , "edit_amount" : 0, "max_amount" : chosenum["max_amount"] , "total_amount" : chosenum["total_amount"]  };   

                if(chosenum){
                    chosenum["complete"] = true;
                }        

            }

            //straight data insert
            mycart[dbid] = { "id" : dbid, "title" : title, "complete" : e.target.checked , "amount" : 0, "commission" : 0, "product_type" : 1 , "edit_amount" : 0, "max_amount" : max_amount, "total_amount" : total_amount};
        }

        // mycart.push({ "id" : dbid, "title" : title, "complete" : e.target.checked});

        Object.keys(mycart).forEach(key => mycart[key] === undefined && delete mycart[key]);

        // Object.keys(mycart).forEach(key => mycart[key] == "null" && delete mycart[key]);

        // this.setState({
        //     twogame
        // });


        // this.setState({
        //     form : {
        //         mycart: mycart,
        //         rmycart: rmycart,
        //         amount : this.state.form.amount,
        //         commission : this.state.form.commission,
        //         total : this.state.form.total

        //     },
        //     twogame: twogame
        // });

        this.setState(prevState => ({
            rcondi : 1,
            form : {
                mycart: mycart,
                rmycart: rmycart,
                amount : this.state.form.amount,
                commission : this.state.form.commission,
                total : this.state.form.total

            },
            twogame: twogame
        }))

        
        // console.log(this.state.form.mycart)
        // console.log(this.state.mycart);
    }

    setReverse(e, toggle){

        // console.log(toggle)

        let item;
        var index;

        let pre_mycart  = this.state.form.mycart;
        let mycart      = [...this.state.form.mycart];

        let pre_rmycart = this.state.form.rmycart;
        let rmycart     = [...this.state.form.rmycart];

        let twogame = this.state.twogame;

        let artwogame = [...this.state.twogame];

        Object.keys(pre_rmycart).forEach(key => pre_rmycart[key] === undefined && delete pre_rmycart[key]);

        Object.keys(pre_rmycart).forEach(key => pre_rmycart[key] == null && delete pre_rmycart[key]);

        Object.keys(pre_mycart).forEach(key => pre_mycart[key] === undefined && delete pre_mycart[key]);

        Object.keys(pre_mycart).forEach(key => pre_mycart[key] == null && delete pre_mycart[key]);

        if(this.state.toggle == false) {

            pre_mycart.forEach(function(each,key){
                
                // let retitle = each.title[0];
                // console.log(retitle)

                //two game index and mycart key

                if(each.title[1]+each.title[0] != each.title) {

                    index = twogame.findIndex(obj => obj.title==(each.title[1]+each.title[0]));
                    // let fullindex = twogame.find(obj => obj.title==(each.title[1]+each.title[0]));

                    // item = twogame.find(item=>item.title==(each.title[1]+each.title[0]));
                    let chosenum = artwogame[index];
                    
                    // console.log(chosenum)

                    rmycart[chosenum["id"] ] = { "id" : chosenum["id"] , "title" :chosenum["title"] , "complete" : chosenum["complete"] ,   "product_type" : chosenum["product_type"] , "edit_amount" : 0 , "max_amount" : chosenum.max_amount , "total_amount" : chosenum.total_amount};    

                    // alert(each.total_amount+each.title[1]+each.title[0])

                    // selftmycart not working
                    mycart[chosenum["id"]] = { "id" : chosenum["id"] , "title" :chosenum["title"] , "complete" : chosenum["complete"] ,   "product_type" : chosenum["product_type"] , "edit_amount" : 0 , "max_amount" : chosenum.max_amount , "total_amount" : chosenum.total_amount};   

                    // alert(each.total_amount+each.title[1]+each.title[0])

                    if(chosenum){
                        chosenum["complete"] = true;
                    }                    
                    


                }

            })

            // Object.keys(rmycart).forEach(key => rmycart[key] === undefined && delete rmycart[key]);

            // Object.keys(rmycart).forEach(key => rmycart[key] == null && delete rmycart[key]);

            // Object.keys(mycart).forEach(key => mycart[key] === undefined && delete mycart[key]);

            // Object.keys(mycart).forEach(key => mycart[key] == null && delete mycart[key]);

            // console.log(artwogame[index])
            this.setState({
                form : {
                    mycart: mycart,
                    rmycart: rmycart,
                    amount : this.state.form.amount,
                    commission : this.state.form.commission ,
                    total : this.state.form.total 
                },
                twogame : artwogame,
                toggle : true
            });

            // console.log(rmycart)

        } else {

            //undefiend clear

            
            // rmycart loop 
            // twogame index
            // twogame uncomplete and mycart remove spec data

            

            // console.log("twogame ------- ")
            // console.log(pre_rmycart)

            pre_rmycart.forEach(function(each,key){
                

                    let fullindex = twogame.find(obj => obj.id==(each.id));
                    index = twogame.findIndex(obj => obj.id==(each.id));
                    //console.log(each)

                    // index = twogame.findIndex(obj => obj.id==(each.id));

                    // console.log(key + "full ---" +fullindex.id)

                    // console.log(key + "---" +index)

                    // console.log("listen ----- ")

                    // console.log(artwogame[index])

                    let chosenum = artwogame[index];

                    if(chosenum){
                        chosenum["complete"] = false;
                    }   

                    // artwogame[index]["complete"] = 0;

                    delete mycart[fullindex.id];
                    delete rmycart[fullindex.id];



              
            })

            // Object.keys(rmycart).forEach(key => rmycart[key] === undefined && delete rmycart[key]);

            // Object.keys(rmycart).forEach(key => rmycart[key] == null && delete rmycart[key]);

            // Object.keys(mycart).forEach(key => (mycart[key] === undefined) && delete mycart[key]);
            // Object.keys(mycart).forEach(key => mycart[key] == null && delete mycart[key]);

   
           
            this.setState({
                form : {
                    mycart: mycart,
                    rmycart: rmycart,
                    amount : this.state.form.amount,
                    commission : this.state.form.commission ,
                    total : this.state.form.total 
                },
                twogame : artwogame,
                toggle : false
            });

        }
        
    }

    betAction() {

        // console.log(this.state.form.mycart)

        // console.log("----------")

        let self = this;

        if(this.state.form.amount >0 || this.state.form.amount != ""){
            
            let min_amount = this.state.setting[0].option_value;

            if(isNaN(this.state.form.amount)) {
                // this.showErrorAlert("Set bet minimum amount must be "+ min_amount +" Ks ", 'single');
                this.showErrorAlert("သတ်မှတ်ထားသော ငွေပမာဏထက် လျော့နည်းနေပါသည်။", 'single');
                return ;
            }


            if(Number(this.state.form.amount) < min_amount) {
                // this.showErrorAlert("Set bet minimum amount must be "+ min_amount +" Ks ", 'single');
                this.showErrorAlert("သတ်မှတ်ထားသော ငွေပမာဏထက် လျော့နည်းနေပါသည်။", 'single');
                return ;
            }

            let twod_maximum_amount = this.state.twogame[0]['max_amount'];
            let maximum_amount = twod_maximum_amount;
            // let maximum_amount = Math.round((twod_maximum_amount/100) * 50);

            if(this.state.form.amount > maximum_amount) {
                this.showErrorAlert("သတ်မှတ်ထားသော ငွေပမာဏထက် ကျော်လွန်နေပါသည်။", 'single');
                // this.showErrorAlert("Set bet maximum amount must be "+ maximum_amount +" Ks ", 'single');
                return ;
            }


            if(this.state.form.mycart.length < 1) {
                this.showErrorAlert("Please select numbers!!! ", 'single');
                return ;
            }
            
            if(this.state.form.amount[this.state.form.amount.length-1] != 0) {
                this.showErrorAlert("အလျော်အစားတွက်ချက်ရာတွင် အခက်အခဲရှိပါသောကြောင့် 50 ဆတိုးကိန်းများသာ ထိုးပေးပါရန်" +  this.state.form.amount[this.state.form.amount.length-1], 'single');
                return ;
            }

            if( this.state.form.amount[this.state.form.amount.length-2] != 0 && this.state.form.amount[this.state.form.amount.length-2] != 5) {
                this.showErrorAlert("အလျော်အစားတွက်ချက်ရာတွင် အခက်အခဲရှိပါသောကြောင့် 50 ဆတိုးကိန်းများသာ ထိုးပေးပါရန်" + this.state.form.amount[this.state.form.amount.length-2], 'single');
                return ;
            }

            let pre_mycart = this.state.form.mycart;

            let mycart = [...this.state.form.mycart];

            let amount = this.state.form.amount;
            let number  = Number(amount.replace(/^0+/, '')); 

            let total = 0;
            let commission = 0;
            let commission_percent = this.state.setting[3].option_value;

            Object.keys(pre_mycart).forEach(key => pre_mycart[key] === undefined && delete pre_mycart[key]);

            Object.keys(pre_mycart).forEach(key => pre_mycart[key] == null && delete pre_mycart[key]);

            //todototalamount
            pre_mycart.forEach(function(each,key){
                
                let commission_amount = ((commission_percent/ 100) * number);

                mycart[each.id] = { "id" : each.id, "title" : each.title, "complete" : each.complete , "amount" : number, "commission" : commission_amount, "product_type" : 1 , "edit_amount" : 0 , "max_amount" : each.max_amount , "total_amount" : each.total_amount };     

                commission += commission_amount ;
                total += number;

            })

            Object.keys(mycart).forEach(key => mycart[key] === undefined && delete mycart[key]);

            Object.keys(mycart).forEach(key => mycart[key] == null && delete mycart[key]);
            

            this.setState({
                form : {
                    mycart: mycart,
                    rmycart: this.state.form.rmycart,
                    amount : this.state.form.amount,
                    commission : commission,
                    total : total
                }
            });


            this.setState({ step : "list" });
              

            // console.log(this.state.form.mycart)
        } else {

            this.showErrorAlert("Set bet amount!!!", 'single');
            return ;
        }
        

    }

    endSessionAction() {
        this.setState({ step : "form" });

        // this.fetchData(window.BPdata.apiUrlCMS+'/2d/formdata?token='+localStorage.getItem(window.BPdata.time)+"&type="+id);

        this.fetchData(window.BPdata.apiUrlCMS+'/2d/formdata?type='+this.state.today_event);

        this.setState({form : { mycart: [], rmycart :[], amount : "", commission : 0, total : 0 } });

    }

    backAction() {
        this.setState({ step : "form" });
    }

    fastAction() {

        if(this.state.fast > 0) {

            this.fetchData(window.BPdata.apiUrlCMS+'/2d/formdata?type='+this.state.today_event);

            this.setState(prevState => ({
                fast : 0,
                step : 'fast',
                form : {
                    ...prevState.form,
                    mycart: [],

                }
            }))


            // form : { mycart: [], amount : 0, commission : 0, total : 0 , rmycart: [] }, 

        } else {

            this.setState({ step : "fast" , fast : 0});

        }

        // if(this.state.fast == true) {
        //     this.setState({ step : "form" });
        // } else {
        //     this.setState({ step : "fast" , fast : 0});
        // }
        
    }

    submitAction() {
        // alert("ok");
        if(!this.state.event.id) {
            this.showErrorAlert("Something was wrong!!!", 'single');
            return ;
        }

        if(this.state.form.mycart.length < 1) {
            this.showErrorAlert("Please select numbers!!!", 'single');
            return ;
        }

        let mycarts = [];
        let formdata = [];

        mycarts = this.state.form.mycart.filter(function (el) {
              return el != null;
            });

        let message = "";
        mycarts.forEach(function(each,key){

            // console.log(each.max_amount-(each.amount+each.total_amount) + "-" + key)
            if( (Number(each.max_amount)-(Number(each.amount)+Number(each.total_amount))) < 0 ) {
                message += each.title+", ";

                
                 //swal("Please check these numbers " + (Number(each.max_amount)-(Number(each.amount)+Number(each.total_amount))))
            }
        })

        if(message != "") {
            swal("Please check these numbers " + message)
            // this.showErrorAlert(message, 'single');
            return ;
        }

        if(this.state.bullet == 1) {

            swal("Please internet connection");
            return;
        }

        if(this.state.bullet == 0) {

            this.setState({  
                    bullet : 1, 
            });
        }

        formdata = { 
            game_type : 1, mycart : mycarts, 
            amount : this.state.form.amount, 
            commission : this.state.form.commission, 
            total : this.state.form.total , 
            contest_id :  this.state.event.id };
        
        var self = this;
        const dataurl = window.BPdata.apiUrlCMS+"/checkout";
        axios({
            method: 'post',
            url: dataurl,
            data: formdata,
            headers: { 'content-type': 'application/json;charset=UTF-8', 
                "X-Trident-Token" : window.BPdata.token,
                "BP-Agent"        : window.BPdata.rand_compound,
                "BP-Time"         : localStorage.getItem(window.BPdata.time)
            },
        })
        .then(function (response) {

            // console.log(response.data.status_code)

            if(response.data.status_code == 404) {
                self.removeAlert(response.data.message, 'multi');
            } 

            if(response.data.status_code == 200) {
                // self.showSuccessAlert(response.data.message, 'multi');
                // self.setState({ step : "message" });
                self.setState({ step : "message", toggle : false });
            }

            self.setState({  
                    bullet : 0, 
            });
             // self.showSuccessAlert(response.data.message, 'multi');

            // console.log(response.data.message)

        })
        .catch(function (error) {
            console.log(error);
        });
    }

    childui(item) {

        let self = this;
        let amount = this.state.form.amount;
        let ui = []
            //console.log("-------------"+data)
        
            if(Array.isArray(item) === false) {
                return ui;
            } else {

                let arlength = item.length;
                
                if(arlength  > 0){
                    
                    this.state.form.mycart.forEach(function(each,key){
                    

                        ui.push(<tr key={"ro-"+each.id}>
                            
                            { ( Number(each.max_amount) < (Number(each.amount) + Number(each.total_amount)) ) ? <td  className="text-center pointer color-blue"> {each.title} </td> : <td  className="text-center pointer"> {each.title}</td>  } 
                            
                            <td colSpan="1" className="text-center">
                                {(each.edit_amount) ?  
                                    <input value={ each.amount } onChange={(e) => self.inputEditChangeHandler.call(self, e, each.id, each.amount, each.max_amount, each.total_amount )}  name="amount"  className="form-control" />  :  each.amount   }

                            </td>
                           {/* <td colSpan="1" className="text-center">

                                { ((each.max_amount - each.total_amount) <= 0 ) ? "Limited" : (each.max_amount - each.total_amount) } 

                            </td>*/}

                            <td colSpan="1" className="text-center">

                                { each.commission }

                            </td>
                            
                            <td>
                                {(each.edit_amount) ?  
                                    <b className="color-blue btn btn-success btn-small" onClick={(e) => self.previewIndviAmountSave.call(e, each, each.id)}>Save</b> :  
                                    


                                    <b className="color-blue" onClick={(e) => self.previewIndviAmountEdit.call(e, each, each.id)} >Edit </b> } | 
                                    <span className="btn btn-danger btn-small"  onClick={(e) => self.previewIndviAmountDelete.call(e, each, each.id)} >Delete</span>
                            </td>
                            
                        </tr>)

                    })

                    return ui
                }
        }
        
        return ui;
    }

    fastBlock() {
        return  <div className="row">
                
                <div className="grid-100 text-left">
                    <i className="pe-7s-back pe-2x pe-va " onClick={this.backAction}></i>
                </div>

                <div className="grid-100 text-left">
                    <br />
                    { window.lang.betgroup.select_number_group }
                    <br />
                    <br />
                    <span className="grid-20">
                        <span onClick={(e) => this.btnfastType.call(this, e, 1)} className="btn btn-secondary mr-10">{ window.lang.betgroup.big }</span>
                    </span>
                    <span className="grid-20">
                        <span onClick={(e) => this.btnfastType.call(this, e, 2)} className="btn btn-secondary mr-10">{ window.lang.betgroup.small }</span>
                        
                    </span>
                    <span className="grid-20">
                        <span onClick={(e) => this.btnfastType.call(this, e, 3)} className="btn btn-secondary mr-10">{ window.lang.betgroup.odd }</span>
                    </span>
                    <span className="grid-20">
                        <span onClick={(e) => this.btnfastType.call(this, e, 4)} className="btn btn-secondary mr-10">{ window.lang.betgroup.even }</span>
                    </span>
                    <span className="grid-20">
                        <span onClick={(e) => this.btnfastType.call(this, e, 5)} className="btn btn-secondary mr-10">{ window.lang.betgroup.same }</span>
                    </span>

                </div>

                <div className="grid-100 text-left">
                    <br />
                    { window.lang.betgroup.round }
                    <br />
                    <br />
                    <span className="grid-9">
                        <label role="checkbox" className="2d-fast-button" aria-checked="true">
                            <span className="bp-fast-checkbox-button__inner" onClick={(e) => this.btnfastType.call(this, e, 6)} >0</span>
                        </label>
                    </span>
                    <span className="grid-9">
                        <label role="checkbox" className="2d-fast-button" aria-checked="true">
                            <span className="bp-fast-checkbox-button__inner" onClick={(e) => this.btnfastType.call(this, e, 7)} >1</span>
                        </label>
                    </span>
                    <span className="grid-9">
                        <label role="checkbox" className="2d-fast-button" aria-checked="true">
                            <span className="bp-fast-checkbox-button__inner" onClick={(e) => this.btnfastType.call(this, e, 8)} >2</span>
                        </label>
                    </span>
                    <span className="grid-9">
                        <label role="checkbox" className="2d-fast-button" aria-checked="true">
                            <span className="bp-fast-checkbox-button__inner" onClick={(e) => this.btnfastType.call(this, e, 9)} >3</span>
                        </label>
                    </span>
                    <span className="grid-9">
                        <label role="checkbox" className="2d-fast-button" aria-checked="true">
                            <span className="bp-fast-checkbox-button__inner" onClick={(e) => this.btnfastType.call(this, e, 10)} >4</span>
                        </label>
                    </span>
                    <span className="grid-9">
                        <label role="checkbox" className="2d-fast-button" aria-checked="true">
                            <span className="bp-fast-checkbox-button__inner" onClick={(e) => this.btnfastType.call(this, e, 11)} >5</span>
                        </label>
                    </span>
                    <span className="grid-9">
                        <label role="checkbox" className="2d-fast-button" aria-checked="true">
                            <span className="bp-fast-checkbox-button__inner" onClick={(e) => this.btnfastType.call(this, e, 12)} >6</span>
                        </label>
                    </span>
                    <span className="grid-9">
                        <label role="checkbox" className="2d-fast-button" aria-checked="true">
                            <span className="bp-fast-checkbox-button__inner" onClick={(e) => this.btnfastType.call(this, e, 13)} >7</span>
                        </label>
                    </span>
                    <span className="grid-9">
                        <label role="checkbox" className="2d-fast-button" aria-checked="true">
                            <span className="bp-fast-checkbox-button__inner" onClick={(e) => this.btnfastType.call(this, e, 14)} >8</span>
                        </label>
                    </span>
                    <span className="grid-9">
                        <label role="checkbox" className="2d-fast-button" aria-checked="true">
                            <span className="bp-fast-checkbox-button__inner" onClick={(e) => this.btnfastType.call(this, e, 15)} >9</span>
                        </label>
                    </span>

                </div>

                <div className="grid-100 text-left">
                    <br />
                    { window.lang.betgroup.head }
                    <br />
                    <br />
                    <span className="grid-9">
                        <label role="checkbox" className="2d-fast-button" aria-checked="true">
                            <span className="bp-fast-checkbox-button__inner" onClick={(e) => this.btnfastType.call(this, e, 16)} >0</span>
                        </label>
                    </span>
                    <span className="grid-9">
                        <label role="checkbox" className="2d-fast-button" aria-checked="true">
                            <span className="bp-fast-checkbox-button__inner" onClick={(e) => this.btnfastType.call(this, e, 17)} >1</span>
                        </label>
                    </span>
                    <span className="grid-9">
                        <label role="checkbox" className="2d-fast-button" aria-checked="true">
                            <span className="bp-fast-checkbox-button__inner" onClick={(e) => this.btnfastType.call(this, e, 18)} >2</span>
                        </label>
                    </span>
                    <span className="grid-9">
                        <label role="checkbox" className="2d-fast-button" aria-checked="true">
                            <span className="bp-fast-checkbox-button__inner" onClick={(e) => this.btnfastType.call(this, e, 19)} >3</span>
                        </label>
                    </span>
                    <span className="grid-9">
                        <label role="checkbox" className="2d-fast-button" aria-checked="true">
                            <span className="bp-fast-checkbox-button__inner" onClick={(e) => this.btnfastType.call(this, e, 20)} >4</span>
                        </label>
                    </span>
                    <span className="grid-9">
                        <label role="checkbox" className="2d-fast-button" aria-checked="true">
                            <span className="bp-fast-checkbox-button__inner" onClick={(e) => this.btnfastType.call(this, e, 21)} >5</span>
                        </label>
                    </span>
                    <span className="grid-9">
                        <label role="checkbox" className="2d-fast-button" aria-checked="true">
                            <span className="bp-fast-checkbox-button__inner" onClick={(e) => this.btnfastType.call(this, e, 22)} >6</span>
                        </label>
                    </span>
                    <span className="grid-9">
                        <label role="checkbox" className="2d-fast-button" aria-checked="true">
                            <span className="bp-fast-checkbox-button__inner" onClick={(e) => this.btnfastType.call(this, e, 23)} >7</span>
                        </label>
                    </span>
                    <span className="grid-9">
                        <label role="checkbox" className="2d-fast-button" aria-checked="true">
                            <span className="bp-fast-checkbox-button__inner" onClick={(e) => this.btnfastType.call(this, e, 24)} >8</span>
                        </label>
                    </span>
                    <span className="grid-9">
                        <label role="checkbox" className="2d-fast-button" aria-checked="true">
                            <span className="bp-fast-checkbox-button__inner" onClick={(e) => this.btnfastType.call(this, e, 25)} >9</span>
                        </label>
                    </span>

                </div>

                <div className="grid-100 text-left">
                    <br />
                    { window.lang.betgroup.tail }
                    <br />
                    <br />
                    <span className="grid-9">
                        <label role="checkbox" className="2d-fast-button" aria-checked="true">
                            <span className="bp-fast-checkbox-button__inner" onClick={(e) => this.btnfastType.call(this, e, 26)} >0</span>
                        </label>
                    </span>
                    <span className="grid-9">
                        <label role="checkbox" className="2d-fast-button" aria-checked="true">
                            <span className="bp-fast-checkbox-button__inner" onClick={(e) => this.btnfastType.call(this, e, 27)} >1</span>
                        </label>
                    </span>
                    <span className="grid-9">
                        <label role="checkbox" className="2d-fast-button" aria-checked="true">
                            <span className="bp-fast-checkbox-button__inner" onClick={(e) => this.btnfastType.call(this, e, 28)} >2</span>
                        </label>
                    </span>
                    <span className="grid-9">
                        <label role="checkbox" className="2d-fast-button" aria-checked="true">
                            <span className="bp-fast-checkbox-button__inner" onClick={(e) => this.btnfastType.call(this, e, 29)} >3</span>
                        </label>
                    </span>
                    <span className="grid-9">
                        <label role="checkbox" className="2d-fast-button" aria-checked="true">
                            <span className="bp-fast-checkbox-button__inner" onClick={(e) => this.btnfastType.call(this, e, 30)} >4</span>
                        </label>
                    </span>
                    <span className="grid-9">
                        <label role="checkbox" className="2d-fast-button" aria-checked="true">
                            <span className="bp-fast-checkbox-button__inner" onClick={(e) => this.btnfastType.call(this, e, 31)} >5</span>
                        </label>
                    </span>
                    <span className="grid-9">
                        <label role="checkbox" className="2d-fast-button" aria-checked="true">
                            <span className="bp-fast-checkbox-button__inner" onClick={(e) => this.btnfastType.call(this, e, 32)} >6</span>
                        </label>
                    </span>
                    <span className="grid-9">
                        <label role="checkbox" className="2d-fast-button" aria-checked="true">
                            <span className="bp-fast-checkbox-button__inner" onClick={(e) => this.btnfastType.call(this, e, 33)} >7</span>
                        </label>
                    </span>
                    <span className="grid-9">
                        <label role="checkbox" className="2d-fast-button" aria-checked="true">
                            <span className="bp-fast-checkbox-button__inner" onClick={(e) => this.btnfastType.call(this, e, 34)} >8</span>
                        </label>
                    </span>
                    <span className="grid-9">
                        <label role="checkbox" className="2d-fast-button" aria-checked="true">
                            <span className="bp-fast-checkbox-button__inner" onClick={(e) => this.btnfastType.call(this, e, 35)} >9</span>
                        </label>
                    </span>

                </div>

                <div className="grid-100 text-center">
                    <br />
                    <br />
                    { window.lang.betgroup.consetellation } { window.lang.betgroup.power }
                    
                    <br />
                </div>

                <div className="grid-100 text-left">
                    <br />
                    <span className="btn btn-secondary grid-90" style={{ textAlign : "left" }} onClick={(e) => this.btnfastType.call(this, e, 36)} >{ window.lang.betgroup.consetellation } 07,18,24,35,69</span>
                    <br />
                </div>

                <div className="grid-100 text-left">
                    <br />
                    <span className="btn btn-secondary grid-90" style={{ textAlign : "left" }} onClick={(e) => this.btnfastType.call(this, e, 37)} >{ window.lang.betgroup.consetellation } R 70,81,42,53,96</span>
                    <br />
                </div>

                <div className="grid-100 text-left">
                    <br />
                    <span className="btn btn-secondary grid-90" style={{ textAlign : "left" }} onClick={(e) => this.btnfastType.call(this, e, 38)} >{ window.lang.betgroup.power } 05, 16, 27, 38,49</span>
                    <br />
                </div>
               
                <div className="grid-100 text-left">
                    <br />
                    <span className="btn btn-secondary grid-90" style={{ textAlign : "left" }} onClick={(e) => this.btnfastType.call(this, e, 39)} >
                    { window.lang.betgroup.power } R 50,61,72,83,94</span>
                    <br />
                </div>

                <div className="grid-100">
                    <br />
                    { window.lang.betgroup.select_number }
                    <br />
                    <br />
                    <span className="grid-20">
                        <span onClick={(e) => this.btnfastType.call(this, e, 40)} className="btn btn-secondary mr-10">00-19</span>
                    </span>
                    <span className="grid-20">
                        <span onClick={(e) => this.btnfastType.call(this, e, 41)} className="btn btn-secondary mr-10">20-39</span>
                        
                    </span>
                    <span className="grid-20">
                        <span onClick={(e) => this.btnfastType.call(this, e, 42)} className="btn btn-secondary mr-10">40-59</span>
                    </span>
                    <span className="grid-20">
                        <span onClick={(e) => this.btnfastType.call(this, e, 43)} className="btn btn-secondary mr-10">60-79</span>
                    </span>
                    <span className="grid-20">
                        <span onClick={(e) => this.btnfastType.call(this, e, 44)} className="btn btn-secondary mr-10">80-99</span>
                    </span>

                </div>

        </div>
    }

    selecttimeBlock() {
        return <div className="row">
            <div className="grid-100">
                <h2>{ window.lang.betgroup.select_time }</h2>
                <br />
                <br />
                <button className="btn btn-primary grid-90" onClick={(e) => this.btnfastTimeSelect.call(this, e, 1)} >12:01 PM</button>
                <br />
                <br />
                <button className="btn btn-primary grid-90" onClick={(e) => this.btnfastTimeSelect.call(this, e, 2)} >4:30 PM</button>
            </div>
        </div>
    }

    formBlock() {
        return   <div className="row">
            
                    { ( this.state.isEvent == true ) ? 
                        <div >

                            {(this.state.wallet[0]) ?
                                    <div className="row pb-10">
                                        <div className="row">
                                            <div className="grid-100 mb-4 text-center">
                                                    {moment(this.state.settrade.created_at, 'YYYY-MM-DD hh:mm:ss').format('DD.MM.YYYY')} &nbsp; &nbsp;|&nbsp;&nbsp;
                                                    {moment(this.state.settrade.created_at, 'YYYY-MM-DD hh:mm:ss').format('hh:mm A')}  
                                                   
                                            </div>

                                            <div className="grid-100 mb-4 text-center">
                                                    <br />
                                                        <span className="blink_me current-number-2" >{ this.state.settrade.number }</span>
                                            </div>
                                        </div>
                                        <div className="adm-card-body grid-100">
                                                <div className="grid-50 left">
                                                    <b>Set</b> &nbsp;: &nbsp;
                                                    { this.state.settrade.set  }
                                                </div>
                                                <div className="grid-50 left">
                                                    <b>Value</b> &nbsp; : &nbsp;
                                                    { this.state.settrade.value }
                                                </div>
                                            
                                        </div>
                                         <div className="adm-card row mt-10">
                                        <div className="adm-card-body grid-100">

                                            <div className="row">
                                                <div className="grid-10 left text-center">
                                                  </div>
                                                  <div className="grid-40 left text-left pl-20 ">
                                                      { window.lang.title.main_wallet }
                                                  </div>
                                                  <div className="grid-40 left text-right">
                                                      { (this.state.wallet) ? this.currencyFormat(this.state.wallet[0].credit) : 0 }  Ks 
                                                      
                                                  </div>
                                                  <div className="grid-10 left text-center">
                                                    <Link to="/more/wallet-store">
                                                      &nbsp; <img src="/img/plus.png" height="18px" />
                                                    </Link>
                                                  </div>
                                            </div>
                                            
                                        </div>
                                </div>
                            </div>
                            : "" }

                            

                            <div className="row p-10">
                                <div className="grid-60 left">
                                    { (this.state.fast > 0) ?
                                    <button className="btn btn-primary grid-100" onClick={this.fastAction}>{ window.lang.betgroup.fast }</button>
                                    : <button className="btn btn-primary grid-100" onClick={this.fastAction}>{ window.lang.betgroup.fast }</button>
                                    }
                                </div>
                                <div className="grid-10 left">
                                    &nbsp;
                                </div>
                                <div className="grid-20 left">
                                    { (this.state.rcondi == 1) ? <button className={`btn grid-100 ${this.state.toggle ? 'btn-success' : 'btn-primary'}`} onClick={(e) => this.setReverse.call(this, e, 'on')}  >R</button> : <button className={`btn grid-100 disabled`}   >R</button>}
                                    
                                </div>
                            </div>

                            <div className="row pb-10 p-10">
                                <div className="grid-70 left text-left">
                                    <input type="text" placeholder="ငွေပမာဏ ထည့်သွင်းရန်" className="form-control text-right grid-80"  name="amount" onChange={(e) => this.formChangeHandler.call(this, e)} value={this.state.form.amount} style={{ width : "95%"}}/>
                                    <br />
                                    { window.lang.betgroup.close_at } {moment(this.state.event.expire_date, 'YYYY-MM-DD hh:mm:ss').format('DD MMM YYYY, hh:mm A')} 
                                    <br />
                                    

                                    <div className="popover__wrapper">
                                      <a href="#">
                                        <b className="popover__title">{ window.lang.betgroup.color_meaning } </b>
                                      </a>
                                      <div className="popover__content">
                                        <p className="popover__message">
                                            ၅၀ ရာခိုင်နူန်းအောက် <span className="color-black">အမဲရောင်</span><br/>
                                            ၅၀ ရာခိုင်နူန်းမှ ၉၀ ရာခိုင်နူန်း အထိ <span className="color-green">အစိမ်းရောင်</span><br/>
                                            ၉၀ ရာခိုင်နူန်းအထက် <span className="color-yellow">အဝါရောင်</span><br />
                                            <span className="color-gray">အနီရောင်</span> ထိုး၍မရတော့ပါ
                                        </p>
                                      </div>
                                    </div>
                                    <br />
                                </div>
                                <div className="grid-20 left text-left">
                                    <button className="btn btn-success grid-100" onClick={this.betAction} style={{ "margin": "5px 0"}}>{ window.lang.betgroup.bet }</button>
                                    <br />
                                </div>
                            </div>
                            <br />
                            <br />
                            
                             <div className="row pb-10">
                                    {this.state.twogame.map((item, i) => (
                                        (this.percentage(item.total_amount,item.max_amount) < 100) ? 
                                        <span className="grid-15"><label role="checkbox" className={`bp-checkbox-button ${item.complete ? 'is-checked' : ''}`}  aria-checked="true" key={i}><input type="checkbox" className="bp-checkbox-button__original" value="000" onClick={(e) => this.inputChangeHandler.call(this, e, i, item.id, item.title, item.max_amount, item.total_amount) }  defaultChecked={!! item.complete}/>
                                        <span className="bp-checkbox-button__inner">{item.title} 

                                            { this.progressBarUi(this.percentage(item.total_amount,item.max_amount),i) }
                                            
                                        </span>
                                        </label>
                                        </span>
                                        :
                                        <span className="grid-15 "><label role="checkbox" className={`bp-checkbox-button ${item.complete ? 'is-checked' : ''}`}  aria-checked="true" key={i}>
                                        <span className="bg-gray bp-checkbox-button__inner disabled">{item.title} 

                                            { this.progressBarUi(this.percentage(item.total_amount,item.max_amount),i) }
                                            
                                        </span>
                                        </label>
                                        </span>
                                        
                                    ))}
                            </div>
                        </div>
                    : window.lang.messages.not_available_yet  }
        </div>
    }

    progressBarUi(percent,i) {

        let ui = []

        if(percent <= 50) {
            ui.push(
                <div role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100" className="bp-progress numberProgessBar bp-progress--line is-success" key={"pro-"+i}>
                    <div className="bp-progress-bar">
                    <div className="bp-progress-bar__outer" style={{ height: "6px" }}><div className="bp-progress-bar__inner bg-black" style={{ width: "20%" }}></div></div></div>
                    <div className="bp-progress__text" style={{ fontSize: "14.4px" }}><i className="bp-icon-circle-check"></i></div>
                </div>
            )
        } else if( percent> 50 && percent < 90) {
            ui.push(
                <div role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100" className="bp-progress numberProgessBar bp-progress--line is-success" key={"pro-"+i}>
                    <div className="bp-progress-bar">
                    <div className="bp-progress-bar__outer" style={{ height: "6px" }}><div className="bp-progress-bar__inner bg-green" style={{ width: "60%" }}></div></div></div>
                    <div className="bp-progress__text" style={{ fontSize: "14.4px" }}><i className="bp-icon-circle-check"></i></div>
                </div>
            )   
        } else if( percent > 90 && percent < 100) {
            ui.push(
                <div role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100" className="bp-progress numberProgessBar bp-progress--line is-success">
                    <div className="bp-progress-bar">
                    <div className="bp-progress-bar__outer" style={{ height: "6px" }}><div className="bp-progress-bar__inner bg-yellow" style={{ width: "80%" }}></div></div></div>
                    <div className="bp-progress__text" style={{ fontSize: "14.4px" }}><i className="bp-icon-circle-check"></i></div>
                </div>
            )
        } else {
            ui.push(
                <div role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100" className="bp-progress numberProgessBar bp-progress--line is-success">
                    <div className="bp-progress-bar">
                    <div className="bp-progress-bar__outer" style={{ height: "6px" }}><div className="bp-progress-bar__inner bg-red" style={{ width: "100%" }}></div></div></div>
                    <div className="bp-progress__text" style={{ fontSize: "14.4px" }}><i className="bp-icon-circle-check"></i></div>
                </div>
            )
        }
        return ui;
    }

    listBlock() {
        return  <div className="row">
                
                <div className="grid-100 text-left">
                    <i className="pe-7s-back pe-2x pe-va pl-5" onClick={this.backAction}></i>
                </div>

                <div className="grid-100 text-center">
                    အဆ {this.state.setting[1].option_value}
                </div>

                <table className="table  table-border league-table" >
                    <thead>
                        <tr>
                            <th>2D</th>
                            <th>{ window.lang.betgroup.bet_amount }</th>
                            <th>{ window.lang.betgroup.commission }</th>
                            <th>{ window.lang.betgroup.action }</th>
                        </tr>
                    </thead>
                    <tbody>
                        
                        {this.childui(this.state.form.mycart)}

                        <tr>
                            <td colSpan="2">{ window.lang.betgroup.commission_total } (?)</td>
                            <td>{this.currencyFormat(this.state.form.commission)} Ks</td>
                            <td></td>
                        </tr>

                        <tr>
                            <td colSpan="2">{ window.lang.betgroup.total }</td>
                            <td>{this.currencyFormat(this.state.form.total)} Ks</td>
                            <td></td>
                        </tr>

                    </tbody>
                </table>

                <button className="btn btn-success grid-100" onClick={this.submitAction}>Submit</button>
                <br />
                <br />
        </div>
    }

    messageBlock() {
        return  <div className="row">
                
                <div className="grid-100 text-center">
                    <i className="pe-7s-info pe-2x pe-va "></i> Success 
                </div>
                <br />
                <p>အောင်မြင်သွားပါပြီ </p>
                <br />

                <button className="btn btn-success grid-100" onClick={this.endSessionAction}>Ok</button>
                <br />
                <br />
        </div>
    }

    viewHandler() {

        
        switch(this.state.step) {
            case "selecttime": return this.selecttimeBlock();
            case "form": return this.formBlock();
            case "list": return this.listBlock();
            case "message": return this.messageBlock();
            case "fast": return this.fastBlock();
            default:      return "";
          }
    }

    render() {
        return (
            <div className="page">

                <div className="page-content text-center">
                    <div className="main-content two_home">
                    <br />
                    {this.state.seen ? <PopUp toggle={this.togglesPop} /> : null}
                    {/*<button onClick={this.showErrorAlert}>Show success alert</button> */}
                    <div className="grid"><StatusAlert/></div>
                        
                    { this.viewHandler() }

                    
                    </div>
                </div>
            </div>
        );
    }
}

export default TwoGameSetPage;