import React from 'react';
import axios from 'axios';
import {
    Link
  } from 'react-router-dom';
import mmph from 'myanmar-phonenumber';


class LoginPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = { show_hide: false, step: "login", message : "", form : {username: '', phone: '',verify_code: '', password: '' }};
        this.validatePhone      = this.validatePhone.bind(this);
        this.userLogout         = this.userLogout.bind(this);
        this.showHide           = this.showHide.bind(this);
    }

    hisUrl(url) {
        if(localStorage.getItem("route")) {
          let menu = JSON.parse(localStorage.getItem("route"));
          //console.log(menu.length+"-------------")
          if(menu.length === "0") {
            let data = ["/"];
            localStorage.setItem("route", JSON.stringify(data) );
          } else {
            if(menu[menu.length - 1] !== url ){
              menu.push(url)
              localStorage.setItem("route", JSON.stringify(menu) );
            }
          }

          // localStorage.setItem("route", JSON.stringify(menu) );
          // console.log(localStorage.getItem('route'));
        }
       // console.log(localStorage.getItem('route'));
    }

    componentDidMount() {
        if(localStorage.getItem(window.BPdata.time)) {
            if(localStorage.getItem(window.BPdata.time) !== "0" ) {
              this.setState({ step: "profile" });
            }

          }

        if(localStorage.getItem('username')) {
                this.setState({
                    form: {
                        username : localStorage.getItem('username')
                    }
                });
        }

        // localStorage.setItem(window.BPdata.time, 0);
        // localStorage.setItem('username', "");
        // else {
        //   localStorage.setItem(window.BPdata.time, 'profile');
        //   this.setState({ step: "home" });
        // }
        this.hisUrl('/more/login');
        //localStorage.setItem('route', '/more/login');
        //console.log(localStorage.getItem('route'))
    }

    // componentWillUpdate() {
    //     localStorage.setItem('route', '/more/login');
    //     console.log(localStorage.getItem('route'))
    // }

    inputChangeHandler(e) {
        let form = {...this.state.form};
        form[e.target.name] = e.target.value;
        this.setState({
            form
        });
    }

    userLogout() {
        //console.log(this.state.step);
        let data = window.confirm("Are you sure want to logout?");
        if(data){
            localStorage.setItem(window.BPdata.time, 0);
            localStorage.setItem('username', "");
            this.setState({ step: "login" , message : "Successfully Log Out" });
            //this.profileBlock()
            this.viewHandler();
        }
    }

    showHide() {

        if(this.state.show_hide == false) {
            this.setState({ show_hide: true });
        } else {
            this.setState({ show_hide: false });
        }

    }

    validatePhone() {
        let form = this.state.form;
        let data
        //console.log(this.state.form.phone.length)
        if(form.phone.length >= 6) {
            if(mmph.isValidMMPhoneNumber(form.phone)){
                // data = {
                //     phone : mmph.normalizeInput(this.state.form.phone),
                //     password : this.state.form.password
                // }
                data = {
                    phone : this.state.form.phone,
                    password : this.state.form.password
                }
                //console.log(data)
                this.setState({  message : window.lang.messages.sending });
                this.sendLoginApi(data);
            } else {
                this.setState({  message : window.lang.messages.invalid_phone });
            }

        } else {
            //console.log(this.state.message)
            this.setState({  message : window.lang.messages.invalid_phone });
        }

    }
    sendLoginApi(formdata) {
        var self = this;
        // let dataurl = window.BPdata.apiUrl+'/user/login';
        const dataurl = window.BPdata.apiUrlCMS+"/player/login";

        // Route::post('/cms/player', 'PlayerApiController@store');
        // Route::post('/cms/player/login', 'PlayerApiController@login');
        axios({
            method: 'post',
            url: dataurl,
            data: formdata,
            headers: { 'content-type': 'application/json;charset=UTF-8',
                "X-Trident-Token" : window.BPdata.token,
                "BP-Agent"         : window.BPdata.rand_compound
            },
        })
        .then(function (response) {

            console.log(response.data.data.message)

            if(response.data.data.status == 403) {
                self.setState({  message : response.data.data.message });
            } else if(response.data.data.status == 200) {
                self.setState({
                    message : "Successfully Login",
                    step : "profile",
                    form : {
                        username : response.data.username
                    }
                });

                // console.log(response.data)
                localStorage.setItem(window.BPdata.time, response.data.data.token );
                localStorage.setItem('username', response.data.data.username );
                localStorage.setItem('reset', 1);
                self.props.history.push("/");
            }  else {
                self.setState({  message : "တစ်ခုခုတော့မှားနေပြီ" });
            }


            //{"data":{"status":"403","message":"\u101c\u1030\u1000\u103c\u102e\u1038\u1019\u1004\u103a\u1038\u101b\u1032\u1037 \u1016\u102f\u1014\u103a\u1038\u1014\u1032\u1037\u1005\u1000\u102c\u1038\u101d\u103e\u1000\u103a\u1000 \u1019\u1000\u102d\u102f\u1000\u103a\u100a\u102e\u101e\u1031\u1038\u1015\u102b .. !! "}}

            // if(response.data.data.status != 403) {
            //     self.setState({
            //         message : "Successfully Login",
            //         step : "profile",
            //         form : {
            //             username : response.data.username
            //         }
            //     });
            //     localStorage.setItem(window.BPdata.time, response.data.data.token );
            //     localStorage.setItem('username', response.data.data.username );
            //     localStorage.setItem('reset', 1);
            //     self.props.history.push("/");
            // } else {
            //     self.setState({  message : response.data.data.message });
            // }

            //console.log(localStorage.getItem(window.BPdata.time))
            //console.log(response.data.success);
        })
        .catch(function (error) {
            // console.log(error);
        });
    }

    viewHandler() {
        if(this.state.step){
            switch(this.state.step) {
                case "login":   return this.loginBlock();
                case "home":    return this.homeBlock();
                case "profile": return this.profileBlock();
                default:        return "";
              }
        }

    }

    loginBlock() {
        return <div className="row ">
                <div className="row" >
                    <div className="grid-100 grid-s-90">
                        <img src="/img/logo.png" height="250px" />
                    </div>

                     { (this.state.message !==  "") ?
                        <span>{this.state.message}
                        <br />
                        <br /></span>
                        : "" }
                </div>
                <div className="row page-contest">
                    <div className="grid-90 grid-s-90 text-left">
                    <label>{window.lang.form.phone}</label>
                    </div>
                    <div className="grid-90 grid-s-90">

                        <input type="text" className="form-control text-center" placeholder="09**********" name="phone" onChange={(e) => this.inputChangeHandler.call(this, e)} value={this.state.form.phone} /><br />

                    </div>
                </div>
                <div className="row page-contest">
                    <div className="grid-90 grid-s-90 text-left">
                        <label>{window.lang.form.password} <span onClick={this.showHide}>(Show/Hide)</span></label>
                    </div>
                    <div className="grid-90 grid-s-90 left text-right">
                        <div className="form-group text-center">
                        { (this.state.show_hide == false) ?
                        <input type="password" className="form-control text-center"  name="password" onChange={(e) => this.inputChangeHandler.call(this, e)} value={this.state.form.password} /> :
                        <input type="text" className="form-control text-center"  name="password" onChange={(e) => this.inputChangeHandler.call(this, e)} value={this.state.form.password} /> }
                        <br />
                        </div>
                        <Link to="/more/password-reset" className="color-dark-red">{window.lang.form.forgot_password}</Link> | &nbsp;&nbsp;
                        {/* <a class="btn btn-success grid-50" href="" onClick={this.validatePhone}>Log in</a> */}
                        <input type="submit" className="btn btn-success grid-40" value={window.lang.form.submit} onClick={this.validatePhone} /><br />
                        <br /> <Link to="/more/sign-up" className="color-dark-red">{window.lang.form.register}</Link>
                    </div>
                </div>
        </div>
    }

    profileBlock() {
      return <div>
          <div className="row">
                { (this.state.message !==  "") ?
                        <span>{this.state.message}
                        <br />
                        <br /></span>
                        : "" }
              <h2>Hi {((this.state.form.username )? this.state.form.username : "Customer")}</h2>
              <p>Welcome to Web App</p>
              <br/>
              <input type="submit" className="btn btn-blue grid-50" value="Log Out" onClick={this.userLogout} />
              {/* <a className="btn btn-blue grid-50" onClick={this.userLogout}>Logout</a> */}
          </div>
      </div>
   }

   homeBlock() {
        return <div>
            <br />
            <br />

            {/*<img src="/img/shwesport.png" alt="profile" className="medium-logo"/>*/}
            <br />
            <span>{this.state.message}</span>
            <br />
            <br />
            <div className="row">
                <Link to="/more/login" className="btn btn-success grid-50">{window.lang.form.login}</Link><br /><br />
                <Link to="/more/sign-up" className="btn btn-yellow grid-50">{window.lang.form.register}</Link>
            </div>
        </div>
    }

    render() {
        return (
            <div className="page">
                <div className="page-content text-center">
                    <div className="main-content">
                    <br />
                    <br />
                    <h1 className="color-dark-red">{window.lang.form.login}</h1>


                    { this.viewHandler() }
                    </div>
                </div>
            </div>
        );
    }
}

export default LoginPage;
