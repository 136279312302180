/* eslint no-dupe-keys: 0 */
import React from 'react';
import { ListView } from 'antd-mobile';

// import { FacebookProvider, CommentsCount } from 'react-facebook';

import {
  Link
} from 'react-router-dom';

import axios from 'axios';
var moment = require('moment');

let NUM_ROWS = 20;
let LIMIT_ROWS = 20;
let TOTAL_INDEX = 0;
//let MAX_LENGTH = 90;
let MAX_LENGTH = 90;

function genData(pIndex = 0) {
  const dataBlob = {};
  for (let i = 0; i < NUM_ROWS; i++) {
    const ii = (pIndex * NUM_ROWS) + i;
    dataBlob[`${ii}`] = `row - ${ii}`;
  }
  return dataBlob;
}

class RecordHistoryPage extends React.Component {
  constructor(props) {
    super(props);

    

    var dataSource = new ListView.DataSource({
      rowHasChanged: (r1, r2) => r1 !== r2
    });

    this.data = [];
    this.state = {
      step: "2d",  
      dataSource: dataSource.cloneWithRows(this.data),
      isLoading: true,
      advertise: []
      
    };

    this.pageIndex = 1;
    TOTAL_INDEX = 0;
    NUM_ROWS = 20;
    if(this.props.perPage) {
      NUM_ROWS = this.props.perPage;
      TOTAL_INDEX = this.props.pageIndexTotal;
      //console.log(TOTAL_INDEX)
      this.state.isLoading = true;
      
    }

    this.changeSubMenu      = this.changeSubMenu.bind(this);

  }

  componentDidMount() {

    this.setState({ step: "2d" }); 

    setTimeout(() => this.lv.scrollTo(0, 120), 800);

    // simulate initial Ajax
    setTimeout(() => {
      this.fetchData();
    }, 600);
  }

  componentWillUnmount() {
    clearInterval(this.state)
  }

  fetchData() {
        
        let dataurl = window.BPdata.apiUrl+'/service/record-history?limit='+NUM_ROWS;
        // let data = this.state.data;
        axios({
            method: 'get',
            url: dataurl,
            // data: data,
            headers: {
              "Content-Type": "application/json; charset=utf-8",
              "X-Trident-Token" : window.BPdata.token,
              "BP-Agent"         : window.BPdata.rand_compound
              // "Content-Type": "application/x-www-form-urlencoded",
          },
        })
        .then(resp => {
          // console.log(this.data.concat(resp.data));
          this.data = resp.data.data;
          // console.log("-----------------");
            
            this.setState({
              dataSource: this.state.dataSource.cloneWithRows(this.data.reverse()),
              isLoading: false
            });

            NUM_ROWS = Number(NUM_ROWS) + Number(LIMIT_ROWS);

            //console.log(this.state.advertise)
            
        })
        .catch(function(error) {
          //console.log(error);
        });   
  }


  onEndReached = (event) => {
    // load new data
    // hasMore: from backend data, indicates whether it is the last page, here is false
    if (this.state.isLoading && !this.state.hasMore) {
      
      return;
    }
    // console.log('reach end', event);
    //this.setState({ isLoading: true });
    if(TOTAL_INDEX !== 0) { 
      if(this.pageIndex+1 >= TOTAL_INDEX) {
        this.setState({
          isLoading: false,
        });
      } else {
        this.setState({ isLoading: true });
      }
    } else {
      this.setState({ isLoading: false });
    }

    setTimeout(() => {
      //console.log(this.pageIndex)
      if(TOTAL_INDEX !== 0) {
  
        if(this.pageIndex+1 >= TOTAL_INDEX) {
          return ;
        }

        //this.data = { ...this.data, ...genData(++this.pageIndex) };
        
        this.fetchData();
        // this.setState({
        //   // dataSource: this.state.dataSource.cloneWithRows(this.rData),
        //   isLoading: false,
        // });
      } else {
        this.data = { ...this.data, ...genData(++this.pageIndex) };
        this.fetchData();
        // this.setState({
        //   dataSource: this.state.dataSource.cloneWithRows(this.rData),
        //   isLoading: false,
        // });
      }
      
    }, 1000);
  }

  changeSubMenu(step) {

      this.setState({ step : step});
  }

  render() {
    const separator = (sectionID, rowID) => (
      <div
        key={`${sectionID}-${rowID}`}
        style={{
          backgroundColor: '#F5F5F9',
          height: 8,
          borderTop: '1px solid #ECECED',
          borderBottom: '1px solid #ECECED',
        }}
      />
    );
    let index = this.data.length - 1;
    let ui;
    let adcount = 0;
    const row = (rowData, sectionID, rowID) => {
      if (index < 0) {
        index = this.data.length - 1;
      }
      const obj = this.data[index--];

      ui =  <div style={{ display: '-webkit-box', display: 'flex', padding: '5px 0' }}>
               
                 
                  <div className="row p-4" style={{ lineHeight: 1 }}>
                              <div className="text-left grid-40 left">
                                  <b className="color-green">{obj.lottery_number}</b>
                              </div>
                                <div className="text-center grid-40 left">
                                   { (obj.gcontest_type == 0) ? "2D" : "" }
                                </div>
                                <div className="text-right grid-20 right">
                                    {moment(obj.expire_date, 'YYYY-MM-DD hh:mm:ss').format('DD MMMM YYYY')}
                                </div>
                  </div>
                  
            </div>;
      
      return (
          ui
      );
    };
    return (

      <div className="page">
        <div className="page-content">
            <div className="main-content text-center">
            <div className="row">
                <div className="grid-100 text-center">
                     <br /><br />
                        <div className="grid-45  bg-gray package-block" onClick={this.changeSubMenu.bind(this,"2d")} >
                            <img src="/img/forumicon.png" alt={window.lang.title.forum} style={{height:"40px",marginBottom:"5px"}} /><br/>
                            <span className="color-green">{window.lang.title.withdrawal}2d</span>
                        </div>
                    
                        <div className="grid-45  bg-gray package-block" onClick={this.changeSubMenu.bind(this,"3d")}>
                            <img src="/img/bill_contest_40.png" alt="Phone bill" style={{height:"40px",marginBottom:"5px"}} /><br/>
                            <span className="color-green">{window.lang.title.top_up}3d</span>
                        </div>
                </div>
            </div>
            <div className="row text-left">
                  
                  <ListView
                    ref={el => this.lv = el}
                    dataSource={this.state.dataSource}
                    renderHeader={() => <div className="list-header">
                                        <img src="/img/badge.png" class="list-img-circle" alt={window.lang.more.announcement} /><span>Record History</span>
                                    </div>}
                    renderFooter={() => (<div style={{ padding: this.state.isLoading ? 30 : 0 , textAlign: 'center', display: this.state.isLoading ? 'block' : 'none' }}>
                      {this.state.isLoading ? <img src="/img/loading.gif" alt="loading"  /> : ''}
                    </div>)}
                    renderRow={row}
                    renderSeparator={separator}
                    className="am-list"
                    pageSize={10}
                    useBodyScroll
                    // onScroll={() => { console.log('scroll'); }}
                    scrollRenderAheadDistance={500}
                    onEndReached={this.onEndReached}
                    onEndReachedThreshold={10}
                  />
              </div>
          </div>
        </div>
      </div>
    );
  }
}

export default RecordHistoryPage;

