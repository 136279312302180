import React, { Component } from 'react';
import Messages from '../Messages/Messages';

class Chat extends Component {
  constructor() {
    super();
    this.state = {
      messages: [],
      message: '',
      name: ''
    };

    // Create a WebSocket instance and connect to the server
    this.socket = new WebSocket('ws://localhost:1992');
  }

  componentDidMount() {
    // Set up event listeners
    var n = Math.floor(Math.random() * 10000) + 1;
    let username = localStorage.getItem('username');

    if(username) {
      this.state.name = username +n
      
    } else {
      this.state.name = "User"+n;
    }

    this.socket.onopen = () => {
      console.log('WebSocket connection opened');
    };

    this.socket.onmessage = (event) => {
      const messages = this.state.messages.concat(event.data);
      this.setState({ messages });
    };

    this.socket.onclose = () => {
      console.log('WebSocket connection closed');
    };

    this.socket.onerror = (error) => {
      console.error(`WebSocket error: ${error}`);
    };
  }

  componentWillUnmount() {
    // Close the WebSocket connection when the component is unmounted
    this.socket.close();
  }

  sendMessage = () => {
    this.socket.send(this.state.message);
    this.setState({ message: '' });
  };

  handleInputChange = (e) => {
    this.setState({ message: e.target.value });
  };

  render() {
    return (

      <div className="outerContainer chlivechat">
        <div className="container-chat">
            <Messages messages={this.state.messages} name={this.state.name} />
            {/* <Input message={message} setMessage={setMessage} sendMessage={sendMessage} total="1"/> */}
        </div>
        {/* <TextContainer users={users}/> */}
      </div>
      // ... rest of your component
     
    );
  }
}

export default Chat;
