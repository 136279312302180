import React from 'react';
import axios from 'axios';
import {
    Link
  } from 'react-router-dom';

class ContactUsPage extends React.Component {
    constructor(props) {
        super(props)
        window.BPdata.footerMenu = "ABOUT";
        this.state = { step: "login", message : "", form : {username: '', phone: '',verify_code: '', password: '' }};

    }

    hisUrl(url) {
        if(localStorage.getItem("route")) {
          let menu = JSON.parse(localStorage.getItem("route"));
          //console.log(menu.length+"-------------")
          if(menu.length === "0") {
            let data = ["/"];
            localStorage.setItem("route", JSON.stringify(data) );
          } else {
            if(menu[menu.length - 1] !== url ){
              menu.push(url)
              localStorage.setItem("route", JSON.stringify(menu) );
            }
          }

          // localStorage.setItem("route", JSON.stringify(menu) );
          // console.log(localStorage.getItem('route'));
        }
       // console.log(localStorage.getItem('route'));
    }

    componentDidMount() {
    }

    // componentWillUpdate() {
    //     localStorage.setItem('route', '/more/login');
    //     console.log(localStorage.getItem('route'))
    // }

    inputChangeHandler(e) {
        let form = {...this.state.form};
        form[e.target.name] = e.target.value;
        this.setState({
            form
        });
    }


    viewHandler() {
        if(this.state.step){
            switch(this.state.step) {
                case "login":   return this.loginBlock();
                case "home":    return this.homeBlock();
                case "profile": return this.profileBlock();
                default:        return "";
              }
        }

    }


    render() {
        return (
            <div className="page">

                <div className="contact-header row text-center">

                            <br />
                            <img src="/img/_contact_us.png" height="56pt"/>
                            <br /><br />
                        <span>
                            Following the phone number of <br /> 127 gateway Company Our Service is 24/7
                        </span>
                    </div>

                <div className="page-content text-center">
                    <div className="main-content">

                        <div className="card row">
                            <div className="el-card__body">
                                <br />
                                <br />
                                <br />
                                <h6>Customer Service Phone</h6>
                                <br />
                                <br />
                                {/*+959785041924*/}
                                <img src="/img/list/_phone.png" alt="" height="30px"/>
                                <br />
                                <a href="tel:+959785041924" style={{ color: "#000", fontSize: "15px"}} >
                                    +959785041924
                                </a>
                                <br />
                                <br />
                                <img src="/img/list/_email.png" alt="" height="30px"/>
                                <br />
                                <a href="mailto:info.127gateway@gmail.com" style={{ color: "#000", fontSize: "15px"}}>
                                    preordermyanmar@gmail.com
                                </a>
                                <br />
                                <br />
                                <img src="/img/list/_viber.png" alt="" height="30px"/>
                                <br />
                                <a href="viber://contact?number=%2B959785041924" style={{ color: "#000", fontSize: "15px"}}>
                                    +959785041924
                                </a>
                                <br />
                                <br />
                                <a href="viber://contact?number=%2B959785041924" className="btn btn-success grid-50" style={{ color: "fff", fontSize: "15px"}}>
                                    <img src="/img/list/_live_chat.png" alt="" height="20px" style={{ verticalAlign: "bottom"}} /> Live Chat
                                </a>

{/*
                                <ul className="contact_phone">
                                    <div className="el-row">
                                        <div className="el-col el-col-9" style={{ textAlign: "left" }}>
                                        <li className="phone_item">+959785041924</li>
                                        </div> <div className="el-col el-col-15" style={{ textAlign: "right" }}><li>
                                        <button type="button" className="el-button el-button--default el-button--medium"><span>
                                            <a href="viber://contact?number=%2B959785041924" style={{ color: "rgb(123, 81, 156)", fontSize: "15px" }}>
                                            <img src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0iIzdCNTE5QyIgd2lkdGg9IjE4cHgiIGhlaWdodD0iMThweCI+PHBhdGggZD0iTTAgMGgyNHYyNEgwVjB6IiBmaWxsPSJub25lIi8+PHBhdGggZD0iTTEyLjg4IDUuMDVjMy4xOC40IDUuNjcgMi44OSA2LjA3IDYuMDcuMDYuNTEuNDkuODguOTkuODguMDQgMCAuMDggMCAuMTItLjAxLjU1LS4wNy45NC0uNTcuODctMS4xMi0uNTEtNC4wOS0zLjcyLTcuMy03LjgxLTcuODEtLjU1LS4wNi0xLjA1LjMzLTEuMTEuODgtLjA3LjU1LjMyIDEuMDUuODcgMS4xMXptLjM4IDIuMTFjLS41My0uMTQtMS4wOC4xOC0xLjIyLjcycy4xOCAxLjA4LjcyIDEuMjJjMS4wNS4yNyAxLjg3IDEuMDkgMi4xNSAyLjE1LjEyLjQ1LjUyLjc1Ljk3Ljc1LjA4IDAgLjE3LS4wMS4yNS0uMDMuNTMtLjE0Ljg1LS42OS43Mi0xLjIyLS40Ny0xLjc3LTEuODQtMy4xNC0zLjU5LTMuNTl6bTUuOTcgOC4xbC0yLjU0LS4yOWMtLjYxLS4wNy0xLjIxLjE0LTEuNjQuNTdsLTEuODQgMS44NGMtMi44My0xLjQ0LTUuMTUtMy43NS02LjU5LTYuNTlsMS44NS0xLjg1Yy40My0uNDMuNjQtMS4wMy41Ny0xLjY0bC0uMjktMi41MmMtLjEyLTEuMDEtLjk3LTEuNzctMS45OS0xLjc3SDUuMDNjLTEuMTMgMC0yLjA3Ljk0LTIgMi4wNy41MyA4LjU0IDcuMzYgMTUuMzYgMTUuODkgMTUuODkgMS4xMy4wNyAyLjA3LS44NyAyLjA3LTJ2LTEuNzNjLjAxLTEuMDEtLjc1LTEuODYtMS43Ni0xLjk4eiIvPjwvc3ZnPg==" alt="" />Viber
                                        </a></span></button>
                                    </li>
                                    </div>
                                        </div>
                                        <div className="el-row"><div className="el-col el-col-9" style={{ textAlign: "left" }}><li className="phone_item">+959785041924</li></div> <div className="el-col el-col-15" style={{ textAlign: "right" }}>
                                        <li><button type="button" className="el-button el-button--default el-button--medium"><span></span></button></li></div></div>
                                </ul>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ContactUsPage;
