import "./webpack.config";
//import Loadable from 'react-loadable';
import React from 'react';
import ReactDOM from 'react-dom';

import { createStore } from 'redux'
import { Provider } from 'react-redux';
import rootReducer from './reducers/rootReducer'
import App from './App';
import registerServiceWorker from './registerServiceWorker';
// import { initializeFirebase } from './components/push-notification';
import { BrowserRouter } from 'react-router-dom';

import './index.css'



const store = createStore(rootReducer)

ReactDOM.render(
     
        <BrowserRouter >
          <Provider store={store} >
              <App />
            </Provider> 
        </BrowserRouter>

    
    
    , document.getElementById('root')
);

// <Provider store={store} >
//             <App />
//           </Provider> 

// Loadable.preloadReady().then(() => {
//     ReactDOM.hydrate(
//         <MemoryRouter>
//             <BrowserRouter forceRefresh="false">
//                 <App />
//             </BrowserRouter>
//         </MemoryRouter>
//         , document.getElementById('root')
//     );    
// });


registerServiceWorker();
// initializeFirebase();
