import React, { Component } from 'react';
import {
  Route,
  Switch,
  Redirect,
  Link
} from 'react-router-dom';
// import { FacebookProvider, Like } from 'react-facebook';

// import logo from './logo.svg';

import 'swiper/dist/css/swiper.min.css';
import 'swiper/dist/js/swiper.min.js';
import 'react-day-picker/lib/style.css';

import './kitchen-sink.css';
import './App.css';
import './Reset.css';
import './Ant.css';
import './Color.css';
import './Component.css';
import './Gameui.css';

import { Grid } from 'antd-mobile';

import Chat from './components/component/Chat/Chat';
import HomePage from './components/pages/news/HomePage';
import ListPage from './components/pages/ListPage';
import FeaturePage from './components/pages/FeaturePage';
import ListDetailPage from './components/pages/news/ListDetailPage';
import LeaguePage from './components/pages/news/LeaguePage';
import HighlightsLeaguePage from './components/pages/Highlights/HighlightsLeaguePage';

import TwoGameHomePage from './components/pages/TwoGameHomePage';
import TwoGameSetPage from './components/pages/TwoGameSetPage';
import ThreeGameHomePage from './components/pages/ThreeGameHomePage';
import ThreeGameSetPage from './components/pages/ThreeGameSetPage';

import ContactUsPage from './components/pages/ContactUsPage';
import AboutPage from './components/pages/gaming/bg/AboutPage';
import LiveCasinoPage from './components/pages/gaming/bg/LiveCasinoPage';
import FishingPage from './components/pages/gaming/bg/FishingPage';

import WSFootballPage from './components/pages/WSFootballPage';
// import LiveScorePage from './components/pages/live/LiveScorePage';
// import LiveScoreMatchDetailPage from './components/pages/live/LiveScoreMatchDetailPage';
// import Channel1AppPage from './components/pages/live/Channel1AppPage';
// import Channel1AppDetailPage from './components/pages/live/Channel1AppDetailPage';
// import Channel2AppPage from './components/pages/live/Channel2AppPage';
// import Channel2AppDetailPage from './components/pages/live/Channel2AppDetailPage';
// import Channel3AppPage from './components/pages/live/Channel3AppPage';
// import Channel3AppDetailPage from './components/pages/live/Channel3AppDetailPage';
// import ChannelsSchedulePage from './components/pages/ChannelsSchedulePage';

import HelpCenterAppPage from './components/pages/live/HelpCenterAppPage';


// import HighlightsPage from './components/pages/Highlights/HighlightsPage';
// import HighlightDetailPage from './components/pages/Highlights/HighlightsDetailPage';
// import HighlightsListPage from './components/pages/Highlights/HighlightsListPage';
// import BillContestPage from './components/pages/BillContestPage';
// import LeagueContestPage from './components/pages/league/LeagueContestPage';
// import LeagueTablePage from './components/pages/league/LeagueTablePage';
// import SLeagueContestPage from './components/pages/sleague/SLeagueContestPage';
// import SLeagueTablePage from './components/pages/sleague/SLeagueTablePage';

import MorePage from './components/pages/MorePage';
import ContestPage from './components/pages/ContestPage';
import WalletTransfer from './components/pages/walletstore/WalletTransfer';
import WalletWithdrawalStore from './components/pages/walletstore/WalletWithdrawalStore';
import WalletTopUpStore from './components/pages/walletstore/WalletTopUpStore';
import TransactionStatus from './components/pages/walletstore/TransactionStatus';

import AddBank from './components/pages/walletstore/AddBank';
import WalletStore from './components/pages/walletstore/WalletStore';



import MyAccPage from './components/pages/user/MyAccPage';
import MyEditAccPage from './components/pages/user/MyEditAccPage';
import InvoiceListPage from './components/pages/user/InvoiceListPage';
import BetList3DPage from './components/pages/user/BetList3DPage';

import ResultList2DPage from './components/pages/user/ResultList2DPage';
import ResultList3DPage from './components/pages/user/ResultList3DPage';
import InvoiceListDetailPage from './components/pages/user/InvoiceListDetailPage';
import InvoiceListDetailWithTokenPage from './components/pages/user/InvoiceListDetailWithTokenPage';
import BetList3DDetailPage from './components/pages/user/BetList3DDetailPage';

import ThaiOffDayPage from './components/pages/user/ThaiOffDayPage';
import RecordHistoryPage from './components/pages/user/RecordHistoryPage';
import DreamPhotoPage from './components/pages/user/DreamPhotoPage';
import WinnerTopTentwoDListPage from './components/pages/user/WinnerTopTentwoDListPage';
import WinnerTopTenthreeDListPage from './components/pages/user/WinnerTopTenthreeDListPage';

import AccPage from './components/pages/AccPage';

import FAQPage from './components/pages/user/FAQPage';
import SharePage from './components/pages/user/SharePage';

import InboxPage from './components/pages/user/InboxPage';
import TransactionPage from './components/pages/user/TransactionPage';
import OrderPage from './components/pages/user/OrderPage';
import LoginPage from './components/pages/LoginPage';
import SignUpPage from './components/pages/SignUpPage';
import FeedbackPage from './components/pages/FeedbackPage';
import PrizePoolPage from './components/pages/PrizePoolPage';

import PasswordRestPage from './components/pages/PasswordRestPage';
import AnnouncementsPage from './components/pages/user/AnnouncementsPage';


import HighlightsVideoSlider from './components/pages/HighlightsVideoSlider';

import BillHistoryPage from './components/pages/user/BillHistoryPage';
import LeagueHistoryPage from './components/pages/league/LeagueHistoryPage';
// import SLeagueHistoryPage from './components/pages/sleague/SLeagueHistoryPage';
import TermAndCondition from './components/pages/TermAndConditionPage';
import AccBillHistoryPage from './components/pages/AccBillHistoryPage';
import AccLeagueHistoryPage from './components/pages/AccLeagueHistoryPage';

import GemStorePage from './components/pages/GemStorePage';
import PackageStorePage from './components/pages/PackageStorePage';

import UserPredictPackage from './components/pages/UserPredictPackage';


import SkillvideoPage from './components/pages/SkillvideoPage';
import SkillvideoDetailPage from './components/pages/SkillvideoDetailPage';

import ParticipantPage from './components/pages/ParticipantPage';

import ForumPage from './components/pages/forum/ForumPage';
import ForumDetailPage from './components/pages/forum/ForumDetailPage';
import ForumCreatePage from './components/pages/forum/ForumCreatePage';
import ImagePreviewPage from './components/pages/ImagePreviewPage';

import IOSDownloadPage from './components/pages/IOSDownloadPage';
import DownloadPage from './components/pages/DownloadPage';

// import CommentPage from './components/pages/CommentPage';
import Toolbar from "./components/Toolbar";
import SideDrawer from "./components/sidemenu/SideDrawer";
import BackDrop from "./components/backdrop/BackDrop";


require('./BPdata.js')
// const data1 = Array.from(new Array(4)).map(() => ({
//   icon: 'https://gw.alipayobjects.com/zos/rmsportal/WXoqXTHrSnRcUwEaQgXJ.png',
// }));
//https://codepen.io/chriscoyier/pen/Wqyyqx



// data1 = data1.reverse();
let url = '/';



class App extends Component {
  constructor(props) {
    super(props);
    //console.log(props);
    this.state = {
      url: '/',
      sideDrawerOpen: false
    }

    //this.goBack = this.goBack.bind(this);
  }

  checklogin() {
    if(localStorage.getItem(window.BPdata.time)) {
        if(localStorage.getItem(window.BPdata.time) !== "0" ) {
            return true;
        }
    }
    return false;
  }

  getCount() {
    const clicked = this.state.clicked
    if(clicked){
      this.setState({count: this.state.count - 1, clicked: false});
    } else {
      this.setState({count: this.state.count + 1, clicked: true})
    }

  }
  UNSAFE_componentWillMount() {
    if(!localStorage.getItem('lang')){
      localStorage.setItem('lang','mm');
    } else {
      if(localStorage.getItem('lang') === 'mm') {
        window.lang = window.mmlang;
      } else {
        window.lang = window.enlang;
      }
    }
  }
  componentDidMount() {

    // this.window.BPdata.route =JSON.stringify( "/" );
      //console.log(localStorage.getItem('route'))

  }

  UNSAFE_componentWillUpdate() {
    //this.goBack();


    // } else {
    //   this.setState({ url: "/" });
    // }
  }

  drawerToggleClickHandler = () => {
    this.setState(prevState => {
      return { sideDrawerOpen: !prevState.sideDrawerOpen };
    });
  };

  backDropClickHandler = () => {
    this.setState({ sideDrawerOpen: false });
  };

  render() {

    if (JSON.parse(localStorage.getItem("route")) !== null)  {
      let routes = JSON.parse(localStorage.getItem("route"));
      //console.log(routes.length)
        if(routes.length>1) {
          // if(window.BPdata.preurl ===  routes[routes.length-1]) {
          //   url = routes[routes.length-1] ;
          // } else {
            url = routes[routes.length-1] ;
          //}

        } else {
          url = "/";
        }

     }

    let backdrop;

    if (this.state.sideDrawerOpen) {
      // sideDrawer = <SideDrawer />;
      backdrop = <BackDrop click={this.backDropClickHandler} />;
    }

    return (
      <div className="App" >
        <Toolbar drawerToggleClickHandler={this.drawerToggleClickHandler} />
        <SideDrawer show={this.state.sideDrawerOpen} />
        {backdrop}

        <div className="App-intro">
          <div id="fb-root"></div>
          <Switch>

             <Route path="/faq" component={FAQPage} />

            <Route path="/help-center" component={HelpCenterAppPage} />
            <Route path="/share-app" component={SharePage} />

            <Route path="/chat" component={Chat} />

            <Route path="/feature" component={FeaturePage} />
            <Route path="/latest" component={ListPage} />


            <Route path="/two-game" component={TwoGameHomePage} />
            <Route path="/two-game-set" component={TwoGameSetPage} />

            <Route path="/three-game" component={ThreeGameHomePage} />
            <Route path="/three-game-set" component={ThreeGameSetPage} />
            <Route path="/about" component={AboutPage} />


            <Route path="/ws-football" component={WSFootballPage} />

            <Route path="/bg-live-casino" component={LiveCasinoPage} />

            <Route path="/bg-fishing-master" component={FishingPage} />
            {/*   <Route path="/bg-xiyoufishing" component={AboutPage} />
            <Route path="/bg-daisen" component={AboutPage} />
            <Route path="/bg-poker" component={AboutPage} /> */}


            <Route path="/contact-us" component={ContactUsPage} />
  {/*          <Route path="/live-score" component={LiveScorePage} />
            <Route exact path="/live/match-detail/:id" component={LiveScoreMatchDetailPage} />

            <Route path="/channel1/detail" component={Channel1AppDetailPage} />
            <Route path="/channel1" component={Channel1AppPage} />

            <Route path="/channel2/detail" component={Channel2AppDetailPage} />
            <Route path="/channel2" component={Channel2AppPage} />

            <Route path="/channel3/detail" component={Channel3AppDetailPage} />
            <Route path="/channel3" component={Channel3AppPage} />


            <Route path="/channelsschedule" component={ChannelsSchedulePage} /> */}

            <Route path="/news/league/:id/:name" component={LeaguePage} />
            <Route exact path="/news/:id" component={ListDetailPage} />

            {/* <Route path="/comment" component={CommentPage} />

            <Route path="/highlightsvideo" component={HighlightsVideoSlider} />
            <Route path="/highlight/latest" component={HighlightsListPage} />
            <Route path="/highlight/league/:id/:name" component={HighlightsLeaguePage} />
            <Route path="/highlight/:id/:name" component={HighlightDetailPage} />
            <Route path="/highlights" component={HighlightsPage} /> */}

            <Route path="/image/:id" component={ImagePreviewPage} />

            <Route path="/forum/create" component={ForumCreatePage} />
            <Route path="/forum/:id" component={ForumDetailPage} />
            <Route path="/forum" component={ForumPage} />

            <Route path="/skillvideo/:id/:name" component={SkillvideoDetailPage} />
            <Route path="/skillvideo" component={SkillvideoPage} />

            <Route path="/participant/predict/order/:id" component={UserPredictPackage} />

            <Route path="/more/invoice-list/:id/:hash/:token" component={InvoiceListDetailWithTokenPage} />

            <Route path="/more/wallet-store/transfer" component={WalletTransfer} />
            <Route path="/more/wallet-store/withdrawal" component={WalletWithdrawalStore} />
            <Route path="/more/wallet-store/top-up" component={WalletTopUpStore} />
            <Route path="/more/wallet-store/transaction-status" component={TransactionStatus} />

            <Route path="/more/wallet-store/add-bank" component={AddBank} />
            <Route path="/more/wallet-store" component={WalletStore} />

            <Route path="/more/announcements" component={AnnouncementsPage} />
            <Route path="/more/myaccount/edit-user" component={MyEditAccPage} />
            <Route path="/more/myaccount" component={MyAccPage} />


            {/* http://invoice.127platform.com/more/invoice-list/6433765cb1013/e8f61549e86554c3b7d91c14bf64c7bc71dce56a/16810942366433765cb6587 */}

            

            <Route path="/more/invoice-list/:id" component={InvoiceListDetailPage} />
            {/* <Route path="/more/bet-list-3d/:id" component={BetList3DDetailPage} /> */}
            <Route path="/more/invoice-list" component={InvoiceListPage} />
            {/* <Route path="/more/bet-list-3d" component={BetList3DPage} /> */}
            <Route path="/more/result-list-2d" component={ResultList2DPage} />
            {/* <Route path="/more/result-list-3d" component={ResultList3DPage} /> */}


            {/* <Route path="/more/thai-off-day" component={ThaiOffDayPage} /> */}
            <Route path="/more/record-history" component={RecordHistoryPage} />
            {/* <Route path="/more/dream-photo" component={DreamPhotoPage} /> */}
            {/* <Route path="/more/2d-winner-top-ten-list" component={WinnerTopTentwoDListPage} />
            <Route path="/more/3d-winner-top-ten-list" component={WinnerTopTenthreeDListPage} /> */}



            <Route path="/more/account/:id/bill-history" component={AccBillHistoryPage} />
            <Route path="/more/account/:id/league-history" component={AccLeagueHistoryPage} />
            <Route path="/more/account/:id" component={AccPage} />
            <Route path="/more/transaction" component={TransactionPage} />
            <Route path="/more/order" component={OrderPage} />
            <Route path="/more/inbox" component={InboxPage} />

            {/*
            <Route path="/more/bill-contest" component={BillContestPage} />
            <Route path="/more/league-contest" component={LeagueContestPage} />
             <Route path="/more/sleague-contest" component={SLeagueContestPage} /> */}

         {/*
            <Route path="/more/league-table" component={LeagueTablePage} />
            {/* <Route path="/more/sleague-table" component={SLeagueTablePage} />
            <Route path="/more/prize-pool" component={PrizePoolPage} />

            <Route path="/more/gems/store" component={GemStorePage} />
            <Route path="/more/package/store" component={PackageStorePage} />


            <Route path="/more/bill-history" component={BillHistoryPage} />
            <Route path="/more/league-history" component={LeagueHistoryPage} />
            <Route path="/more/sleague-history" component={SLeagueHistoryPage} /> */}

            <Route path="/more/login" component={LoginPage} />
            <Route path="/more/feedback" component={FeedbackPage} />
            <Route path="/more/sign-up" component={SignUpPage} />
            <Route path="/more/password-reset" component={PasswordRestPage} />
            <Route path="/more/term-condition" component={TermAndCondition} />
            <Route path="/more" component={MorePage} />
            <Route path="/contest" component={ContestPage} />
            <Route path="/participant" component={ParticipantPage} />

            <Route path="/download/ios" component={IOSDownloadPage} />
            <Route path="/download" component={DownloadPage} />



            {/* <Route path="/euro/euro-standing" component={EuroStandingPage} />
            <Route path="/euro/euro-team" component={EuroTeamPage} />

            <Route  path="/news"  component={EuroChampionPage} /> */}

            <Route exact path="/" component={HomePage} />




            {/* <Route path="/euro/team/:id" component={EuroTeamDetailPage} /> */}


            <Redirect to="/" />
          </Switch>

        </div>
        <footer  className="App-footer">

          <div className="App-footer-inner">
            {/* {window.BPdata.footerMenu} */}

            <Grid data={window.lang.footer}
                columnNum={3}
                renderItem={dataItem => (

                  <Link to={dataItem.url} className="cont-wrap-link hvr-pulse-shrink" style={{width: '100%'}}>
                        { (window.BPdata.footerMenu === dataItem.check )  ? (dataItem.text == "My Profile") ?  <span className="color-white"><img src={dataItem.icon_off}  height="30px"/><br />{(this.checklogin()) ? dataItem.text : "Login / Register" }</span> : <span className="text-white"><img src={dataItem.icon_off}  height="30px"/><br /><span style={{ fontSize :"12px" }}>{dataItem.text}</span> </span> : <span className="text-white"><img src={dataItem.icon_off}  height="30px"/><br />{dataItem.text} </span> }

                  </Link>
                )}
              />

          </div>
        </footer>

      </div>
    );


  }

}

export default App;
