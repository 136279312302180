/* eslint no-dupe-keys: 0 */
import React from 'react';
import { ListView } from 'antd-mobile';

// import { FacebookProvider, CommentsCount } from 'react-facebook';

import {
  Link
} from 'react-router-dom';

import { Grid } from 'antd-mobile';
// import AppHeader from './../AppHeader';

import axios from 'axios';
var moment = require('moment');

let NUM_ROWS = 20;
let LIMIT_ROWS = 20;
let TOTAL_INDEX = 0;
//let MAX_LENGTH = 90;
let MAX_LENGTH = 90;

function genData(pIndex = 0) {
  const dataBlob = {};
  for (let i = 0; i < NUM_ROWS; i++) {
    const ii = (pIndex * NUM_ROWS) + i;
    dataBlob[`${ii}`] = `row - ${ii}`;
  }
  return dataBlob;
}

class InvoiceListPage extends React.Component {
  constructor(props) {
    super(props);

    window.BPdata.footerMenu = "home";

    var dataSource = new ListView.DataSource({
      rowHasChanged: (r1, r2) => r1 !== r2
    });

    this.data = [];
    this.state = {
      invoicelist : 'invoicelist',
      invoice_list    : [],
      result_list : [],
      dataSource: dataSource.cloneWithRows(this.data),
      isLoading: true,
      advertise: []

    };

    this.pageIndex = 1;
    TOTAL_INDEX = 0;
    NUM_ROWS = 20;
    if(this.props.perPage) {
      NUM_ROWS = this.props.perPage;
      TOTAL_INDEX = this.props.pageIndexTotal;
      //console.log(TOTAL_INDEX)
      this.state.isLoading = true;

    }

    this.changeSubMenu      = this.changeSubMenu.bind(this);

  }

  componentDidMount() {



    // if(localStorage.getItem('home_advertise')) {

    //   if(JSON.parse(localStorage.getItem("home_advertise")) != null) {
    //     let home_advertise = JSON.parse(localStorage.getItem("home_advertise"));
    //     this.setState({advertise: home_advertise})
    //   }
    // }

    // if(JSON.parse(localStorage.getItem("videoSlides")) != null) {
    //   let video_slides = JSON.parse(localStorage.getItem("videoSlides"));
    //   this.setState({
    //     dataSource: this.state.dataSource.cloneWithRows(video_slides.reverse()),
    //     isLoading: true,
    //   });
    // }

    // if(localStorage.getItem('homeData')) {
    //   if(JSON.parse(localStorage.getItem("homeData")) != null) {
    //     let home_data = JSON.parse(localStorage.getItem("homeData"));
    //     this.setState({homeData: home_data})
    //     this.setState({
    //       dataSource: this.state.dataSource.cloneWithRows(home_data.dataSource),
    //       isLoading: false,
    //       advertise: home_data.advertise
    //     });
    //   }
    // }
    //TOTAL_INDEX = 20;
    // you can scroll to the specified position
    // setTimeout(() => this.lv.scrollTo(0, 120), 800);
    setTimeout(() => this.lv.scrollTo(0, 0), 800);

    // simulate initial Ajax
    setTimeout(() => {
      this.fetchData();
    }, 600);

    // this.lv.scrollTo(0, 0);

  }

  componentWillUnmount() {
    clearInterval(this.state)
  }

  orderStatus(id) {
    if(id == 1) {
      return "Awaiting payment";
    } else if (id == 2) {
      return "Processing";
    } else if (id == 3) {
      return "Cancel";
    } else if (id == 4) {
      return "Completed";
    } else {
      return "Pending";
    }
}

  changeSubMenu(step) {
      // if(step === 'invoicelist') {
        this.setState({ invoicelist : step});

        if(step === 'invoicelist') {
          this.data = this.invoice_list;
        } else {
          this.data =   this.result_list;
        }

        this.fetchData();
          // if(this.state.invoicelist) {
          //     this.setState({ invoicelist : step});
          // } else {
          //     this.setState({ invoicelist : step});
          // }
      // }

  }

  fetchData() {

        let dataurl = window.BPdata.apiUrl+'/service/invoice-list?limit='+NUM_ROWS+'&game_type=1';
        // let data = this.state.data;
        axios({
            method: 'get',
            url: dataurl,
            // data: data,
            headers: {
              "Content-Type": "application/json; charset=utf-8",
              "X-Trident-Token"  : window.BPdata.token,
              "BP-Agent"         : window.BPdata.rand_compound,
              "BP-Time"          : localStorage.getItem(window.BPdata.time)
              // "Content-Type": "application/x-www-form-urlencoded",
          },
        })
        .then(resp => {
          // console.log(this.data.concat(resp.data));
          this.invoice_list = resp.data.invoice_list.data;
          this.result_list = resp.data.result_list;

          if(this.state.invoicelist == "invoicelist") {

            this.data = resp.data.invoice_list.data;

          } else {

            this.data = resp.data.result_list;

          }

          // console.log('fetch data')
          // console.log(this.data )



          // console.log("-----------------");

            this.setState({
              dataSource: this.state.dataSource.cloneWithRows(this.data.reverse()),
              isLoading: false
            });

            // localStorage.setItem("home_advertise", JSON.stringify(resp.data.advertise) )
            // localStorage.setItem("homeData", JSON.stringify({
            //   dataSource: this.data.reverse(),
            //   isLoading: false,
            //   advertise: resp.data.advertise
            // }) )

            // this.data = { ...this.data, ...genData(++this.pageIndex) };
            // console.log(this.data)
            NUM_ROWS = Number(NUM_ROWS) + Number(LIMIT_ROWS);

            //console.log(this.state.advertise)

        })
        .catch(function(error) {
          //console.log(error);
        });
    // fetch(REQUEST_URL)
    //   .then((response) => response.json())
    //   .then((responseData) => {
    //     this.setState({
    //       dataSource: this.state.dataSource.cloneWithRows(responseData[0].data),
    //     });
    //   })
    //   .done();
  }


  onEndReached = (event) => {
    // load new data
    // hasMore: from backend data, indicates whether it is the last page, here is false
    if (this.state.isLoading && !this.state.hasMore) {

      return;
    }
    // console.log('reach end', event);
    //this.setState({ isLoading: true });
    if(TOTAL_INDEX !== 0) {
      if(this.pageIndex+1 >= TOTAL_INDEX) {
        this.setState({
          isLoading: false,
        });
      } else {
        this.setState({ isLoading: true });
      }
    } else {
      this.setState({ isLoading: false });
    }

    setTimeout(() => {
      //console.log(this.pageIndex)
      if(TOTAL_INDEX !== 0) {

        if(this.pageIndex+1 >= TOTAL_INDEX) {
          return ;
        }

        //this.data = { ...this.data, ...genData(++this.pageIndex) };

        this.fetchData();
        // this.setState({
        //   // dataSource: this.state.dataSource.cloneWithRows(this.rData),
        //   isLoading: false,
        // });
      } else {
        this.data = { ...this.data, ...genData(++this.pageIndex) };
        this.fetchData();
        // this.setState({
        //   dataSource: this.state.dataSource.cloneWithRows(this.rData),
        //   isLoading: false,
        // });
      }

    }, 1000);
  }

  render() {
    const separator = (sectionID, rowID) => (
      <div
        key={`${sectionID}-${rowID}`}
        style={{
          backgroundColor: '#F5F5F9',
          height: 8,
          borderTop: '1px solid #ECECED',
          borderBottom: '1px solid #ECECED',
        }}
      />
    );
    let index = this.data.length - 1;
    let ui = "";
    let adcount = 0;
    const row = (rowData, sectionID, rowID) => {
      if (index < 0) {
        index = this.data.length - 1;
      }
      const obj = this.data[index--];

      if (this.state.invoicelist == "invoicelist") {
          ui =  <div style={{ display: '-webkit-box', display: 'flex', padding: '10px' }}>

                      <div className="row p-4" style={{ lineHeight: 1 }}>

                                  <div className="text-left grid-100 left">
                                    <h2 className="color-red">Invoice No : {obj.invoice_no}</h2><br />
                                    <small>Invoice Date : {moment.utc(obj.created_at).local().format('DD MMM YYYY, hh:mm A') } </small><br/><br/>
                                    
                                    
                                  </div>


                                  <div className="text-left grid-30 right">
                                      <Link to={"/more/invoice-list/"+obj.invoice_no}>
                                      <span>{obj.grand_total} MMK { (obj.win_amount != 0 ) ? "*" : "" }</span><br /><br />
                                      Click to Detail
                                      </Link>
                                  </div>
                                  <div className="text-left grid-30 left">
                                      <Link to={"/more/invoice-list/"+obj.invoice_no}>
                                          <b>{obj.shop.name}</b>
                                      </Link>
                                      <br /><br />
                                      <b class="color-blue">{(obj.payment_status) ? this.orderStatus(obj.payment_status) : "Pending"}</b>
                                  </div>

                                  <div className="text-left grid-100 left">
                                      <hr />
                                  </div>


                      </div>

                </div>;
      }

      if (this.state.invoicelist == "resultlist") {
          // console.log('------------')
          // console.log(obj[0]);

          // for (let i = 0; i < obj.length; i++) {
            // ui +="ss";
          //   //   ui += "11";

              ui =  <div className="row grid-100">
                      <div className="grid-90">
                        <div className="grid-100 left pl-20">
                            <br />
                            { obj[1].date }
                            <br /><br />
                        </div>
                      </div>
                      <div className="adm-card row  bg-noise">
                          <div className="adm-card-body grid-90">
                                 

                          </div>
                          <div className="adm-card-body row">

                              <div className="grid-90  pl-20 pt-4 pb-4">
                                <hr style={{ "borderBottom": "1px solid #FFFFFF" , "opacity": "0.25" }} />
                              </div>
                          </div>

                          <div className="adm-card-body grid-90">
                                  

                          </div>
                      </div>
              </div>;


      }

      return (
          ui
      );
    };
    return (
      <div className="page">
        <div className="page-content">
            <div className="main-content">

                <div className="row">
                  <div className={ (this.state.invoicelist == "invoicelist") ? 'grid-50 left text-center tab-holder active color-red' : 'grid-50 left text-center tab-holder color-red'}  onClick={this.changeSubMenu.bind(this,"invoicelist")}  >
                    { window.lang.title.invoice_list }
                  </div>



                </div>
                <div className="row text-left">


                    <ListView
                      ref={el => this.lv = el}
                      dataSource={this.state.dataSource}
                      renderHeader={() => ""}
                      renderFooter={() => (<div style={{ padding: this.state.isLoading ? 30 : 0 , textAlign: 'center', display: this.state.isLoading ? 'block' : 'none' }}>
                        {this.state.isLoading ? <img src="/img/loading.gif" alt="loading" height="50px"  /> : ''}
                      </div>)}
                      renderRow={row}
                      // renderSeparator={separator}
                      className="am-list"
                      pageSize={10}
                      useBodyScroll
                      // onScroll={() => { console.log('scroll'); }}
                      scrollRenderAheadDistance={500}
                      onEndReached={this.onEndReached}
                      onEndReachedThreshold={10}
                    />
                </div>
          </div>
        </div>

        <footer  className="App-footer">

          <div className="App-footer-inner">
            {/* {window.BPdata.footerMenu} */}
            <Grid data={window.lang.footer}
                columnNum={5}
                renderItem={dataItem => (

                  <Link to={dataItem.url} className="cont-wrap-link hvr-pulse-shrink" style={{width: '100%'}}>
                        { (window.BPdata.footerMenu === dataItem.check )  ? (dataItem.text == "My Profile") ?  <span className="color-white"><img src={dataItem.icon}  height="25px"/><br />{(this.checklogin()) ? dataItem.text : "Login / Register" }</span> : <span className="text-white"><img src={dataItem.icon_off}  height="25px"/><br /><span >{dataItem.text}</span> </span> : <span className="text-white"><img src={dataItem.icon}  height="25px"/><br />{dataItem.text} </span> }

                  </Link>
                )}
              />

          </div>
        </footer>

      </div>
    );
  }
}

export default InvoiceListPage;
